import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Button, IconButton, Stack, Table, TableBody, TableCell, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { OnlyFansMassDMMessageScheduleDocumentWithColor } from '../../../../hooks/useMessageUsersMassDmList';
import { OnlyFansSubscriber } from '../../../../hooks/useSubscriber';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import MassageUsersAutoMassDmCalendarItemNameAndBadges from './MassageUsersAutoMassDmCalendarItemNameAndBadges';
import MessageUsersAutoMassDmEditMessageDialog from './MessageUsersAutoMassDmEditMessageDialog';

const MessageUsersAutoMassDmCalendarItemCard = (props: {
    subscriber: OnlyFansSubscriber;
    scheduledAutoMassDmMessage: OnlyFansMassDMMessageScheduleDocumentWithColor;
    isFutureScheduledMessage: boolean;
    futureScheduledDate?: moment.Moment;
    setSelectedDate: (day: moment.Moment) => void;
    refetchMassDmsSchedule: () => void;
    refetchMassDms: () => void;
}) => {
    const {
        subscriber,
        scheduledAutoMassDmMessage,
        isFutureScheduledMessage,
        futureScheduledDate,
        setSelectedDate,
        refetchMassDms,
        refetchMassDmsSchedule,
    } = props;

    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();
    const axios = useAxios();
    const timezone = moment.tz.guess();

    const [open, setOpen] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [isDeletingScheduledMessage, setIsDeletingScheduledMessage] = useState(false);

    const massDmScheduleDelete = async (massDmScheduleId: string): Promise<{ success: boolean }> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return { success: false };
        }

        setIsDeletingScheduledMessage(true);

        const data = await axios
            .delete(`${settingsContext.routes.massDm.base}${params.userId}/schedule/${massDmScheduleId}`)
            .then(async response => {
                setIsDeletingScheduledMessage(false);

                return response.data;
            })
            .catch(error => {
                setIsDeletingScheduledMessage(false);
                handleHttpError(error, dialog);

                return { success: false };
            });

        return data;
    };

    const handleDeleteScheduledMessage = (id: string) => {
        dialog
            .confirm({
                message: 'Are you sure you want to delete this scheduled Mass DM?',
                ok: { text: 'Delete', variant: 'contained', color: 'error' },
                cancel: { text: 'Cancel' },
                title: 'Delete Scheduled Mass DM',
            })
            .then(async () => {
                const result = await massDmScheduleDelete(id);

                if (result && result.success) {
                    enqueueSnackbar('Scheduled Mass DM deleted.', { variant: 'success' });

                    refetchMassDmsSchedule();
                } else {
                    enqueueSnackbar('Failed to delete scheduled Mass DM.', { variant: 'error' });
                }
            })
            .catch(() => {});
    };

    return (
        <Box
            pl={1}
            pr={1}
            pb={0}
            sx={{
                borderBottom: '1px solid',
                borderBottomColor: 'divider',
                backgroundColor: isFutureScheduledMessage ? 'grey.50' : 'transparent',
                // opacity: isFutureScheduledMessage ? 0.5 : 1,
            }}
        >
            <Table
                width={'100%'}
                size="small"
                sx={{
                    '& td': {
                        '&:first-of-type': {
                            paddingLeft: '0px',
                            paddingRight: '0px',
                        },
                        '&:last-child': {
                            paddingRight: '0px',
                        },
                        paddingLeft: 1,
                        paddingRight: 1,
                        borderBottom: 'none',
                    },
                    '& th': {
                        '&:first-of-type': {
                            paddingLeft: '0px',
                            paddingRight: '0px',
                        },
                        '&:last-child': {
                            paddingRight: '0px',
                        },
                        // Make header cells bold
                        fontWeight: 'bold',
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                    bgcolor: open ? 'grey.50' : 'transparent',
                    tableLayout: 'fixed',
                }}
            >
                <TableBody>
                    <TableRow>
                        <TableCell
                            width={'85px'}
                            valign="top"
                            sx={{
                                borderRight: '6px solid',
                                borderRightColor: scheduledAutoMassDmMessage.color ? scheduledAutoMassDmMessage.color.color : 'divider',
                                paddingLeft: 0,
                                minWidth: 75,
                            }}
                        >
                            <Stack direction="column" spacing={1} alignItems="center">
                                {isFutureScheduledMessage && futureScheduledDate && (
                                    <Button
                                        variant="text"
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            setSelectedDate(moment(scheduledAutoMassDmMessage.scheduleDate));
                                        }}
                                        startIcon={<EventRepeatIcon />}
                                    >
                                        {moment(scheduledAutoMassDmMessage.scheduleDate).format('DD MMM')}
                                    </Button>
                                )}
                                <Box>
                                    {moment(
                                        isFutureScheduledMessage && futureScheduledDate
                                            ? futureScheduledDate
                                            : scheduledAutoMassDmMessage.scheduleDate,
                                    ).format('hh:mm a')}
                                </Box>
                                {/* <Tooltip title={open ? 'Hide Message Details' : 'Show Message Details'}>
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </Tooltip> */}
                            </Stack>
                        </TableCell>
                        <TableCell sx={{ textWrap: 'wrap', overflow: 'scroll' }}>
                            <Stack direction="column" spacing={0.5} sx={{ pt: 1 }}>
                                <MassageUsersAutoMassDmCalendarItemNameAndBadges
                                    scheduledAutoMassDmMessage={scheduledAutoMassDmMessage}
                                    isFutureScheduledMessage={isFutureScheduledMessage}
                                    timezone={timezone}
                                />

                                <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-start">
                                    <Tooltip
                                        title={scheduledAutoMassDmMessage.active ? 'Edit Repeat Settings' : 'Restart Scheduled Message'}
                                    >
                                        <div>
                                            <IconButton
                                                color={
                                                    scheduledAutoMassDmMessage.repeatEveryAmount &&
                                                    scheduledAutoMassDmMessage.repeatEveryUnit
                                                        ? 'success'
                                                        : 'default'
                                                }
                                                size="small"
                                                disabled={isDeletingScheduledMessage || isFutureScheduledMessage}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setOpenEditDialog(true);
                                                }}
                                            >
                                                {scheduledAutoMassDmMessage.active ? <EditIcon /> : <RestartAltIcon />}
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    {scheduledAutoMassDmMessage.active && (
                                        <Tooltip title="Delete Scheduled Message">
                                            <div>
                                                <IconButton
                                                    color="error"
                                                    size="small"
                                                    disabled={isDeletingScheduledMessage || isFutureScheduledMessage}
                                                    onClick={e => {
                                                        e.stopPropagation();

                                                        handleDeleteScheduledMessage(scheduledAutoMassDmMessage._id!);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    )}
                                </Stack>
                            </Stack>
                        </TableCell>
                    </TableRow>
                    {/* <TableRow>
                        <TableCell
                            style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                            }}
                            colSpan={2}
                        >
                            <Collapse
                                in={open}
                                timeout={{
                                    enter: theme.transitions.duration.enteringScreen,
                                    exit: theme.transitions.duration.leavingScreen,
                                }}
                                unmountOnExit
                                sx={{
                                    overflow: 'hidden',
                                }}
                            >
                                <Box
                                    sx={{
                                        overflowX: 'auto',
                                        border: '6px solid',
                                        borderColor: scheduledAutoMassDmMessage.color
                                            ? scheduledAutoMassDmMessage.color.color
                                            : theme.palette.divider,
                                    }}
                                >
                                    <MessageUsersScheduledListRowDetails
                                        row={scheduledAutoMassDmMessage}
                                        timezone={timezone}
                                        smallScreen={smallScreen}
                                    />
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow> */}
                </TableBody>
            </Table>
            {openEditDialog && (
                <MessageUsersAutoMassDmEditMessageDialog
                    subscriber={subscriber}
                    scheduledAutoMassDmMessage={scheduledAutoMassDmMessage}
                    refetchMassDms={refetchMassDms}
                    open={openEditDialog}
                    onClose={() => setOpenEditDialog(false)}
                />
            )}
        </Box>
    );
};

export default MessageUsersAutoMassDmCalendarItemCard;
