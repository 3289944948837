import { Box, Skeleton, Theme, useTheme } from '@mui/material';
import * as d3 from 'd3';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { SextforceMetricsCampaignsClaimedTodayOverview } from '../../../../hooks/useSextforceMetricsCampaignsClaimedToday';

interface MetricHistory {
    date: Date;
    count: number;
}

interface CompactChartProps {
    data: MetricHistory[];
    width?: number;
    height?: number;
}

const CompactChart: React.FC<CompactChartProps> = ({ data, height = 48 }) => {
    const theme: Theme = useTheme();
    const containerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.getBoundingClientRect().width);
            }
        };

        updateWidth();
        const resizeObserver = new ResizeObserver(updateWidth);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        if (!data.length || !svgRef.current || width === 0) return;

        // Clear previous content
        d3.select(svgRef.current).selectAll('*').remove();

        // Setup margins
        const margin = { top: 5, right: 5, bottom: 14, left: 5 };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // Create scales
        const xScale = d3
            .scaleTime()
            .domain(d3.extent(data, d => d.date) as [Date, Date])
            .range([0, innerWidth]);

        const yScale = d3
            .scaleLinear()
            .domain([0, Math.max(1, d3.max(data, d => d.count) || 0)])
            .range([innerHeight, 0]);

        // Create SVG
        const svg = d3.select(svgRef.current).attr('width', width).attr('height', height);

        // Add group for margins
        const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

        // Create line generator
        const line = d3
            .line<MetricHistory>()
            .x(d => xScale(d.date))
            .y(d => yScale(d.count));

        // Setup tooltip
        const tooltip = d3.select(tooltipRef.current);

        // Draw line segments between points
        for (let i = 0; i < data.length - 1; i++) {
            const point1 = data[i];
            const point2 = data[i + 1];
            const isZeroSegment = point1.count === 0 && point2.count === 0;
            const color = isZeroSegment ? '#ef4444' : '#2563eb';

            // Draw line segment
            g.append('path').datum([point1, point2]).attr('fill', 'none').attr('stroke', color).attr('stroke-width', 1.5).attr('d', line);

            // Add dot for the first point (and last point if we're on the last segment)
            g.append('circle')
                .attr('cx', xScale(point1.date))
                .attr('cy', yScale(point1.count))
                .attr('r', 2)
                .attr('fill', point2.count === 0 ? theme.palette.error.main : theme.palette.primary.main)
                .attr('class', 'data-point')
                .on('mouseover', event => {
                    tooltip
                        .style('opacity', 1)
                        .style('left', `0px`)
                        .style('top', `22px`)
                        .html(`<b>${moment(point1.date).format('MMM D')}</b> | Count: ${point1.count}`);
                })
                .on('mouseout', () => {
                    tooltip.style('opacity', 0);
                });

            if (i === data.length - 2) {
                g.append('circle')
                    .attr('cx', xScale(point2.date))
                    .attr('cy', yScale(point2.count))
                    .attr('r', 2)
                    .attr('fill', point2.count === 0 ? theme.palette.error.main : theme.palette.primary.main)
                    .attr('class', 'data-point')
                    .on('mouseover', event => {
                        tooltip
                            .style('opacity', 1)
                            .style('left', `0px`)
                            .style('top', `22px`)
                            .html(`<b>${moment(point2.date).format('MMM D')}</b> | Count: ${point2.count}`);
                    })
                    .on('mouseout', () => {
                        tooltip.style('opacity', 0);
                    });
            }
        }

        // Add start and end date labels
        const dateExtent = d3.extent(data, d => d.date) as [Date, Date];

        // Remove existing axis
        g.append('g')
            .attr('transform', `translate(0,${innerHeight})`)
            .call(g => g.select('.domain').remove());

        // Add start date label (left-aligned)
        g.append('text')
            .attr('x', 0)
            .attr('y', innerHeight + 12)
            .style('font-size', '10px')
            .style('font-family', 'system-ui')
            .style('text-anchor', 'start')
            .text(d3.timeFormat('%b %d')(dateExtent[0]));

        // Add end date label (right-aligned)
        g.append('text')
            .attr('x', innerWidth)
            .attr('y', innerHeight + 12)
            .style('font-size', '10px')
            .style('font-family', 'system-ui')
            .style('text-anchor', 'end')
            .text(d3.timeFormat('%b %d')(dateExtent[1]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, width, height]);

    return (
        <Box ref={containerRef} sx={{ width: '100%', position: 'relative' }}>
            <svg ref={svgRef} />
            <div
                ref={tooltipRef}
                style={{
                    position: 'absolute',
                    opacity: 0,
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '2px 4px',
                    fontSize: '10px',
                    pointerEvents: 'none',
                    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                    zIndex: 1000,
                }}
            />
            <Box
                component="style"
                dangerouslySetInnerHTML={{
                    __html: `
            .data-point {
              cursor: pointer;
            }
            .data-point:hover {
              r: 3;
            }
          `,
                }}
            />
        </Box>
    );
};

type Props = {
    claimsTodayOverview: SextforceMetricsCampaignsClaimedTodayOverview[] | undefined;
    claimsTodayOverviewLoading: boolean;
    metricId: string;
};

const SextforceMetricsClaimed7DaysGraph = (props: Props) => {
    const { claimsTodayOverview, claimsTodayOverviewLoading, metricId } = props;

    const [history, setHistory] = useState<MetricHistory[]>([]);

    useEffect(() => {
        if (!metricId || claimsTodayOverviewLoading || !claimsTodayOverview) {
            return;
        }

        const metricHistory = claimsTodayOverview.map(day => {
            const metric = day.data.find(m => m.metricId === metricId);
            return {
                date: new Date(day.date),
                count: metric?.count || 0,
            };
        });

        // Limit the history to the last 7 days
        const now = moment().startOf('day');
        const sevenDaysAgo = moment().subtract(7, 'days').startOf('day');
        const last7Days = metricHistory.filter(
            historyItem => moment(historyItem.date).isAfter(sevenDaysAgo) && moment(historyItem.date).isBefore(now),
        );

        setHistory(last7Days);
    }, [metricId, claimsTodayOverviewLoading, claimsTodayOverview]);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
            {claimsTodayOverviewLoading && <Skeleton variant="rectangular" width="100%" height="32px" />}
            {!claimsTodayOverviewLoading && <CompactChart data={history} height={32} />}
        </Box>
    );
};

export default SextforceMetricsClaimed7DaysGraph;
