import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { Box, Chip, CircularProgress, Grid, IconButton, Stack, Tab, Tabs, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { useDialog } from 'muibox';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../../store/SettingsContext';
import { UserContext } from '../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse } from '../../utils/common';
import MessageUsersTagCreatorsList from '../services/messageUsers/sendMessage/MessageUsersTagCreatorsList';
import MessageUsersTagReleaseFormsList from '../services/messageUsers/sendMessage/MessageUsersTagReleaseFormsList';

// Memoized TabPanel component to prevent unnecessary re-renders
const CustomTabPanel = React.memo(({ children, value, index, ...other }: { children?: React.ReactNode; index: number; value: number }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0, pt: 1, bgcolor: 'transparent' }}>{children}</Box>}
        </div>
    );
});

// Memoized ActionButtons component
const ActionButtons = React.memo(
    ({
        onSortToggle,
        sortEnabled,
        onClearSelection,
        onRefetch,
        disabled,
    }: {
        onSortToggle: () => void;
        sortEnabled: boolean;
        onClearSelection: () => void;
        onRefetch: () => void;
        disabled: boolean;
    }) => {
        return (
            <>
                <Grid item xs={'auto'}>
                    <Tooltip title="Sort alphabetically">
                        <IconButton onClick={onSortToggle} color={sortEnabled ? 'primary' : 'secondary'} disabled={disabled}>
                            <SortByAlphaIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={'auto'}>
                    <Tooltip title="Clear Selection">
                        <IconButton color="error" onClick={onClearSelection} disabled={disabled}>
                            <ClearIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={'auto'}>
                    <Tooltip title="Reload from OnlyFans">
                        <IconButton color="secondary" onClick={onRefetch} disabled={disabled}>
                            <RefreshIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    },
);

// Memoized SelectedChips component
const SelectedChips = React.memo(({ items, onDelete }: { items: { id: number; name: string }[]; onDelete: (id: number) => void }) => {
    if (items.length === 0) {
        return <Typography variant="caption">No items selected.</Typography>;
    }

    return (
        <Stack direction="row" flexWrap="wrap" alignItems="center" spacing={2} rowGap={1} justifyContent="flex-start">
            {items.map(item => (
                <Chip key={item.id} label={item.name} onDelete={() => onDelete(item.id)} />
            ))}
        </Stack>
    );
});

// Loading indicator component
const LoadingIndicator = React.memo(({ type }: { type: string }) => (
    <Box textAlign={'center'}>
        <Typography variant="caption">
            Retrieving your {type}...
            <br />
            <br />
            This might take a while depending on how many {type.toLowerCase()} you have and OnlyFans servers speed 🤷‍♂️
            <br />
            <br />
            <CircularProgress size={24} />
        </Typography>
    </Box>
));

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type Props = {
    subscriber: any;
    tagCreators: boolean;
    setTagCreators: (tagCreators: boolean) => void;
    taggedCreators: {
        id: number;
        name: string;
    }[];
    setTaggedCreators: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                name: string;
            }[]
        >
    >;
    taggedReleaseForms: {
        id: number;
        name: string;
    }[];
    setTaggedReleaseForms: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
                name: string;
            }[]
        >
    >;
    session?: any;
    disabled?: boolean;
};

const OnlyFansTagCreatorsSelector = (props: Props) => {
    const { subscriber, taggedCreators, setTaggedCreators, taggedReleaseForms, setTaggedReleaseForms, disabled = false } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const dialog = useDialog();

    const [sortTaggedFriends, setSortTaggedFriends] = useState<boolean>(false);
    // const [sortedTaggedFriends, setSortedTaggedFriends] = useState<any[]>([]);

    const [sortReleaseForms, setSortReleaseForms] = useState<boolean>(false);
    // const [sortedReleaseForms, setSortedReleaseForms] = useState<any[]>([]);

    const [tabValue, setTabValue] = useState<number>(0);

    const taggedCreatorsCount = taggedCreators ? taggedCreators.length : 0;
    const taggedReleaseFormsCount = taggedReleaseForms ? taggedReleaseForms.length : 0;
    const totalTagged = taggedCreatorsCount + taggedReleaseFormsCount;

    // Memoize API query functions to prevent recreation on render
    const fetchTaggedFriends = useCallback(async () => {
        if (!subscriber || !userContext.jwtToken || !settingsContext.apiKey) return null;

        const query = `${settingsContext.routes.onlyFans.base}${subscriber._id}/taggedFriends`;

        try {
            const response = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            });

            if (response.ok) {
                return response.json();
            } else {
                handleHttpErrorResponse(response, dialog);
                return null;
            }
        } catch (error) {
            console.error(error);
            handleHttpError(error, dialog);
            return null;
        }
    }, [subscriber, userContext.jwtToken, settingsContext.apiKey, settingsContext.routes.onlyFans.base, dialog]);

    const {
        data: taggedFriends,
        isLoading: taggedFriendsLoading,
        isFetching: taggedFriendsFetching,
        refetch: refetchTaggedFriends,
    } = useQuery(
        ['taggedFriends', subscriber],
        () => {
            if (subscriber) {
                return fetchTaggedFriends();
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: subscriber ? true : false,
        },
    );

    const fetchReleaseForms = useCallback(async () => {
        if (!subscriber || !userContext.jwtToken || !settingsContext.apiKey) return null;

        const query = `${settingsContext.routes.onlyFans.base}${subscriber._id}/releaseForms`;

        try {
            const response = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            });

            if (response.ok) {
                return response.json();
            } else {
                handleHttpErrorResponse(response, dialog);
                return null;
            }
        } catch (error) {
            console.error(error);
            handleHttpError(error, dialog);
            return null;
        }
    }, [subscriber, userContext.jwtToken, settingsContext.apiKey, settingsContext.routes.onlyFans.base, dialog]);

    const {
        data: releaseForms,
        isLoading: releaseFormsLoading,
        isFetching: releaseFromsFetching,
        refetch: refetchReleaseForms,
    } = useQuery(
        ['releaseForms', subscriber],
        () => {
            if (subscriber) {
                return fetchReleaseForms();
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: subscriber ? true : false,
        },
    );

    // Memoize sorted data
    const sortedTaggedFriends = useMemo(() => {
        if (!taggedFriends) return [];

        if (sortTaggedFriends) {
            return [...taggedFriends].sort((a, b) => {
                const x = a.name.toLowerCase();
                const y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
        }

        return taggedFriends;
    }, [taggedFriends, sortTaggedFriends]);

    const sortedReleaseForms = useMemo(() => {
        if (!releaseForms) return [];

        const documentForms = releaseForms.filter((releaseForm: any) => releaseForm.type === 'document');

        if (sortReleaseForms) {
            return [...documentForms].sort((a, b) => {
                const x = a.name.toLowerCase();
                const y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
        }

        return documentForms;
    }, [releaseForms, sortReleaseForms]);

    // useEffect(() => {
    //     if (taggedFriends) {
    //         if (sortTaggedFriends) {
    //             const copy = [...taggedFriends];
    //             const sortedData = copy.sort((a: any, b: any) => {
    //                 let x = a.name.toLowerCase();
    //                 let y = b.name.toLowerCase();

    //                 if (x < y) {
    //                     return -1;
    //                 }

    //                 if (x > y) {
    //                     return 1;
    //                 }

    //                 return 0;
    //             });

    //             setSortedTaggedFriends(sortedData);
    //         } else {
    //             setSortedTaggedFriends(taggedFriends);
    //         }
    //     }
    // }, [taggedFriends, sortTaggedFriends]);

    // useEffect(() => {
    //     if (releaseForms) {
    //         if (sortReleaseForms) {
    //             const copy = [...releaseForms];
    //             const sortedData = copy
    //                 .filter(item => item.type === 'document')
    //                 .sort((a: any, b: any) => {
    //                     let x = a.name.toLowerCase();
    //                     let y = b.name.toLowerCase();

    //                     if (x < y) {
    //                         return -1;
    //                     }

    //                     if (x > y) {
    //                         return 1;
    //                     }

    //                     return 0;
    //                 });

    //             setSortedReleaseForms(sortedData);
    //         } else {
    //             setSortedReleaseForms(releaseForms.filter((item: any) => item.type === 'document'));
    //         }
    //     }
    // }, [releaseForms, sortReleaseForms]);

    // Memoize handlers
    const handleTabChange = useCallback((event, newValue) => {
        setTabValue(newValue);
    }, []);

    const handleCreatorListItemClicked = (id: number) => {
        const alreadyChecked = taggedCreators.find(item => item.id === id);

        if (alreadyChecked) {
            const newCheckedLists = taggedCreators.filter(item => item.id !== id);

            setTaggedCreators(newCheckedLists);
        } else if (taggedFriends) {
            const listName: string = taggedFriends.find((item: any) => item.id === id).name;
            setTaggedCreators([...taggedCreators, { id, name: listName }]);
        }
    };

    const handleReleaseFormListItemClicked = (id: number) => {
        const alreadyChecked = taggedReleaseForms.find(item => item.id === id);

        if (alreadyChecked) {
            const newCheckedLists = taggedReleaseForms.filter(item => item.id !== id);

            setTaggedReleaseForms(newCheckedLists);
        } else if (releaseForms) {
            const listName: string = releaseForms.find((item: any) => item.id === id).name;
            setTaggedReleaseForms([...taggedReleaseForms, { id, name: listName }]);
        }
    };

    const handleDeleteTaggedCreator = (id: number) => {
        const newCheckedLists = taggedCreators.filter(item => item.id !== id);

        setTaggedCreators(newCheckedLists);
    };

    const handleDeleteReleaseForm = (id: number) => {
        const newCheckedLists = taggedReleaseForms.filter(item => item.id !== id);

        setTaggedReleaseForms(newCheckedLists);
    };

    const toggleSortTaggedFriends = useCallback(() => {
        setSortTaggedFriends(prev => !prev);
    }, []);

    const toggleSortReleaseForms = useCallback(() => {
        setSortReleaseForms(prev => !prev);
    }, []);

    const clearTaggedCreators = useCallback(() => {
        setTaggedCreators([]);
    }, [setTaggedCreators]);

    const clearTaggedReleaseForms = useCallback(() => {
        setTaggedReleaseForms([]);
    }, [setTaggedReleaseForms]);

    return (
        <>
            <Grid item xs="auto">
                <Chip
                    color={totalTagged === 0 ? 'error' : 'primary'}
                    icon={<CheckCircleIcon />}
                    label={`${totalTagged} selected`}
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12}>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange}>
                    <Tab label="Tag Creators" {...a11yProps(0)} />
                    <Tab label="Tag Release Forms" {...a11yProps(1)} />
                </Tabs>

                <CustomTabPanel value={tabValue} index={0}>
                    <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                        {!taggedFriendsLoading && !taggedFriendsFetching && (
                            <>
                                <Grid item xs>
                                    <div style={{ color: theme.palette.grey[500] }}>Select friends to tag</div>
                                </Grid>
                                <ActionButtons
                                    onSortToggle={toggleSortTaggedFriends}
                                    sortEnabled={sortTaggedFriends}
                                    onClearSelection={clearTaggedCreators}
                                    onRefetch={refetchTaggedFriends}
                                    disabled={disabled}
                                />
                            </>
                        )}
                        {taggedFriendsLoading || taggedFriendsFetching ? (
                            <Grid item xs>
                                <LoadingIndicator type="Friends" />
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={2}
                                    flexGrow={1}
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ marginBottom: theme.spacing(1) }}
                                >
                                    <Grid item xs={12}>
                                        <MessageUsersTagCreatorsList
                                            sortedTaggedFriends={sortedTaggedFriends}
                                            taggedCreators={taggedCreators}
                                            handleListItemClicked={handleCreatorListItemClicked}
                                            theme={theme}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <SelectedChips items={taggedCreators} onDelete={handleDeleteTaggedCreator} />
                        </Grid>
                    </Grid>
                </CustomTabPanel>

                <CustomTabPanel value={tabValue} index={1}>
                    <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                {!releaseFormsLoading && !releaseFromsFetching && (
                                    <>
                                        <Grid item xs>
                                            <div style={{ color: theme.palette.grey[500] }}>Select release forms to tag</div>
                                        </Grid>
                                        <ActionButtons
                                            onSortToggle={toggleSortReleaseForms}
                                            sortEnabled={sortReleaseForms}
                                            onClearSelection={clearTaggedReleaseForms}
                                            onRefetch={refetchReleaseForms}
                                            disabled={disabled}
                                        />
                                    </>
                                )}
                                {releaseFormsLoading || releaseFromsFetching ? (
                                    <Grid item xs>
                                        <LoadingIndicator type="Release Forms" />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            spacing={2}
                                            flexGrow={1}
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{ marginBottom: theme.spacing(1) }}
                                        >
                                            <Grid item xs={12}>
                                                <MessageUsersTagReleaseFormsList
                                                    sortedReleaseForms={sortedReleaseForms}
                                                    taggedReleaseForms={taggedReleaseForms}
                                                    handleListItemClicked={handleReleaseFormListItemClicked}
                                                    theme={theme}
                                                    disabled={disabled}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <SelectedChips items={taggedReleaseForms} onDelete={handleDeleteReleaseForm} />
                        </Grid>
                    </Grid>
                </CustomTabPanel>
            </Grid>
        </>
    );
};

export default OnlyFansTagCreatorsSelector;
