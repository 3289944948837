import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

/**
 * Describes OnlyFans /count/all API reply object
 */
export interface OnlyFansAllCountersSubscribers {
    active: number;
    muted: number;
    restricted: number;
    expired: number;
    blocked: number;
    all: number;
    activeOnline: number;
}

export interface OnlyFansAllCountersSubscriptions {
    active: number;
    muted: number;
    restricted: number;
    expired: number;
    blocked: number;
    all: number;
    attention?: number;
}

export interface OnlyFansAllCounters {
    subscriptions: OnlyFansAllCountersSubscriptions;
    subscribers: OnlyFansAllCountersSubscribers;
    bookmarks: number;
}

export namespace OnlyFansPayout {
    export interface PayoutsStats {
        list: List;
    }

    export interface List {
        months: { [key: string]: Month };
        total: Total;
    }

    export interface Month {
        chat_messages: ChatMessage[];
        total_net: number;
        total_gross: number;
        post?: ChatMessage[];
        tips?: ChatMessage[];
    }

    export interface ChatMessage {
        time: number;
        net: number;
        gross: number;
    }

    export interface Total {
        chat_messages: All;
        all: All;
        tips: All;
        post: All;
    }

    export interface All {
        total_net: number;
        total_gross: number;
    }
}

export namespace OnlyFansStatisticsProfileVisitors {
    export interface Response {
        visitors: ResponseVisitors;
    }

    export interface ResponseVisitors {
        hasStatistic: boolean;
        visitors: NewClass;
        chartData: ChartDatum[];
        earnings: Earnings;
        subscriptions: Subscriptions;
    }

    export interface ChartDatum {
        date: Date;
        count: number;
    }

    export interface Earnings {
        total: number;
        gross: number;
        delta: number;
    }

    export interface Subscriptions {
        new: NewClass;
        renew: NewClass;
    }

    export interface NewClass {
        total: number;
        delta: number;
    }
}

export interface OnlyFansDailyStats {
    _id: Date;
    counters?: OnlyFansAllCounters;
    earnings?: OnlyFansPayout.PayoutsStats;
    performerTop?: number;
    fansSubscriptions?: OnlyFansStatisticsProfileVisitors.ResponseVisitors;
}

const useMetricsDailyStats = (startDate: Date, endDate: Date, timezone: string, userId?: string) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const axios = useAxios();

    // Fetch online users count vs. total sales report
    const fetchData = async (startDate: Date, endDate: Date, timezone: string, userId?: string): Promise<OnlyFansDailyStats[]> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return [];
        }

        if (!startDate || !endDate) {
            return [];
        }

        if (!userId && !params.userId) {
            return [];
        }

        if (userContext.jwtToken && settingsContext.apiKey) {
            const query: string = `${settingsContext.routes.metrics.base}${userId || params.userId}/dailystats?${new URLSearchParams({
                startDate: moment(startDate).tz(timezone, true).toISOString(),
                endDate: moment(endDate).tz(timezone, true).toISOString(),
            })}`;

            const data = await axios
                .get(query)
                .then(async response => response.data)
                .catch(error => {
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    return useQuery(
        ['metricsDailyStats', userId || params.userId, userContext.jwtToken, startDate, endDate, timezone],
        () => {
            return fetchData(startDate, endDate, timezone, userId);
        },
        {
            refetchOnWindowFocus: false,
            // Stale time 1 hour
            staleTime: 60 * 60 * 1000,
            enabled: userContext.jwtToken && (params.userId || userId) ? true : false,
        },
    );
};

export default useMetricsDailyStats;
