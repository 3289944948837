import { Card, CardContent, Grid, Skeleton, Theme, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { OnlyFansDailyStats } from '../../hooks/useMetricsDailyStats';
import StatsGraphD3 from './StatsGraphD3';
import ValueAndTrend from './ValueAndTrend';

const StatsCardEarningsAll = (props: {
    dailyStats: OnlyFansDailyStats[] | undefined;
    dailyStatsLoading: boolean;
    amountType: 'gross' | 'net';
}) => {
    const { dailyStats, dailyStatsLoading, amountType } = props;
    const theme: Theme = useTheme();

    const getValue = (d: OnlyFansDailyStats): number => {
        if (amountType === 'gross') {
            return d.earnings &&
                d.earnings.list &&
                d.earnings.list.total &&
                d.earnings.list.total.all &&
                d.earnings.list.total.all.total_gross
                ? d.earnings.list.total.all.total_gross
                : 0;
        }

        return d.earnings && d.earnings.list && d.earnings.list.total && d.earnings.list.total.all && d.earnings.list.total.all.total_net
            ? d.earnings.list.total.all.total_net
            : 0;
    };

    return (
        <Card variant="elevation">
            <CardContent
                sx={{
                    height: 150,
                    padding: 0,
                }}
            >
                {dailyStatsLoading ? (
                    <Skeleton />
                ) : (
                    <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ height: '100%' }}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                paddingRight: {
                                    xs: theme.spacing(1),
                                    sm: theme.spacing(1.5),
                                },
                                marginTop: {
                                    xs: theme.spacing(1),
                                    sm: theme.spacing(1.5),
                                },
                                marginLeft: {
                                    xs: theme.spacing(1),
                                    sm: theme.spacing(1.5),
                                },
                            }}
                        >
                            <Grid container spacing={1} flexGrow={0} alignItems="center">
                                <Grid item xs>
                                    <Typography variant="h6" textAlign={'left'}>
                                        Earnings
                                        <small
                                            style={{
                                                fontSize: '0.8rem',
                                                color: theme.palette.grey[600],
                                                // Position below the bottom left corner of the main text
                                                position: 'relative',
                                                left: -79,
                                                top: 14,
                                            }}
                                        >
                                            {amountType}
                                        </small>
                                        {/* Earnings <small style={{ fontSize: '0.8rem', color: theme.palette.grey[600] }}>Gross</small> */}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <ValueAndTrend
                                        value={dailyStats && dailyStats[0] ? getValue(dailyStats[0]) : undefined}
                                        previousValue={dailyStats && dailyStats.length > 1 ? getValue(dailyStats[1]) : undefined}
                                        type={'money'}
                                        fractionDigits={2}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ height: 100 }}>
                            <StatsGraphD3
                                data={
                                    dailyStats
                                        ? dailyStats.map(d => {
                                              return {
                                                  time: moment(d._id).toDate(),
                                                  count: d.earnings ? getValue(d) : 0,
                                              };
                                          })
                                        : []
                                }
                                type="currency"
                                color={theme.palette.primary.main}
                            />
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

export default StatsCardEarningsAll;
