import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { OnlyFansMassDMMessageScheduleDocumentWithColor } from '../../../../hooks/useMessageUsersMassDmList';
import { OnlyFansSubscriber } from '../../../../hooks/useSubscriber';
import MessageUsersAutoMassDmCalendarItemCard from './MessageUsersAutoMassDmCalendarItemCard';

const DateTitle = ({ date }: { date: Date }) => {
    return (
        <TableRow>
            <TableCell colSpan={2}>
                <Typography variant="h6" pl={1} pt={1} pb={1}>
                    {moment(date).format('MMMM D, YYYY')}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

type Props = {
    subscriber: OnlyFansSubscriber;
    scheduledAutoMassDmMessage: OnlyFansMassDMMessageScheduleDocumentWithColor[];
    setSelectedDate: (date: moment.Moment) => void;
    isScheduledMessagesLoading: boolean;
    refetchMassDmsSchedule: () => void;
    refetchMassDms: () => void;
};

const MessageUsersAutoMassDmCalendarListView = (props: Props) => {
    const { subscriber, scheduledAutoMassDmMessage, setSelectedDate, isScheduledMessagesLoading, refetchMassDms, refetchMassDmsSchedule } =
        props;

    return (
        <Box
            sx={{
                display: 'block',
                overflowX: 'auto',
            }}
        >
            {!isScheduledMessagesLoading && scheduledAutoMassDmMessage && scheduledAutoMassDmMessage.length === 0 && (
                <Typography variant="body1" color="text.secondary" align="center" gutterBottom>
                    No Priority messages scheduled for this day.
                </Typography>
            )}
            {!isScheduledMessagesLoading && scheduledAutoMassDmMessage && scheduledAutoMassDmMessage.length > 0 && (
                <>
                    <Table
                        size="small"
                        sx={{
                            // No padding on the left and right of the table but only on the first and last cell
                            '& td': {
                                '&:first-of-type': {
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                },
                                '&:last-child': {
                                    paddingRight: 1,
                                },
                                paddingLeft: 0.5,
                                paddingRight: 0.5,
                                paddingTop: 0,
                                paddingBottom: 0,
                            },
                            '& th': {
                                '&:first-of-type': {
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                },
                                '&:last-child': {
                                    paddingRight: 1,
                                },
                                // Make header cells bold
                                fontWeight: 'bold',
                                paddingLeft: 0.5,
                                paddingRight: 0.5,
                            },
                        }}
                    >
                        <TableBody>
                            {scheduledAutoMassDmMessage.map((scheduledMessage, index) => (
                                <React.Fragment key={scheduledMessage._id}>
                                    {scheduledMessage.scheduleDate && index === 0 && (
                                        <DateTitle key={`date-${scheduledMessage._id}`} date={scheduledMessage.scheduleDate} />
                                    )}
                                    {scheduledMessage.scheduleDate &&
                                        index > 0 &&
                                        moment(scheduledAutoMassDmMessage[index - 1].scheduleDate).isSame(
                                            scheduledMessage.scheduleDate,
                                            'day',
                                        ) === false && (
                                            <DateTitle key={`date-${scheduledMessage._id}`} date={scheduledMessage.scheduleDate} />
                                        )}
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <MessageUsersAutoMassDmCalendarItemCard
                                                subscriber={subscriber}
                                                scheduledAutoMassDmMessage={scheduledMessage}
                                                setSelectedDate={setSelectedDate}
                                                isFutureScheduledMessage={false}
                                                refetchMassDms={refetchMassDms}
                                                refetchMassDmsSchedule={refetchMassDmsSchedule}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </Box>
    );
};

export default MessageUsersAutoMassDmCalendarListView;
