import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { OnlyFansSubscriber } from '../../../../hooks/useSubscriber';
import { TaskOccurrence } from './MessageUsersAutoMassDmCalendar';
import MessageUsersPriorityAutoMassDmCalendarItemCard from './MessageUsersAutoMassDmCalendarItemCard';

type Props = {
    subscriber: OnlyFansSubscriber;
    taskOccurrences: TaskOccurrence[];
    selectedDate: moment.Moment;
    setSelectedDate: (date: moment.Moment) => void;
    handlePrevDay: () => void;
    handleNextDay: () => void;
    refetchMassDmsSchedule: () => void;
    refetchMassDms: () => void;
};

const MessageUsersAutoMassDmCalendarDayView = (props: Props) => {
    const {
        subscriber,
        taskOccurrences,
        selectedDate,
        setSelectedDate,
        handlePrevDay,
        handleNextDay,
        refetchMassDmsSchedule,
        refetchMassDms,
    } = props;

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pb: 2,
                    mb: 2,
                    borderBottom: '1px solid',
                    borderBottomColor: 'divider',
                    marginBottom: 0,
                }}
            >
                <IconButton onClick={handlePrevDay} disabled={moment(selectedDate).isSame(moment(), 'day')}>
                    <ChevronLeft />
                </IconButton>
                <Typography variant="h6" sx={{ flex: 1, textAlign: 'center' }}>
                    {selectedDate.format('LL')} ({taskOccurrences.length.toLocaleString()})
                </Typography>
                <IconButton onClick={handleNextDay}>
                    <ChevronRight />
                </IconButton>
            </Box>

            <Stack spacing={0}>
                {taskOccurrences.length === 0 && (
                    <Typography variant="body1" color="text.secondary" align="center" gutterBottom sx={{ mt: 2 }}>
                        No Priority messages scheduled for this day.
                    </Typography>
                )}
                {moment(selectedDate).isBefore(moment(), 'day') && (
                    <Typography variant="body1" color="text.secondary" align="center" gutterBottom sx={{ mt: 2 }}>
                        This day has already passed!
                    </Typography>
                )}
                {taskOccurrences.map((taskOccurrence, index) => (
                    <MessageUsersPriorityAutoMassDmCalendarItemCard
                        key={index}
                        subscriber={subscriber}
                        scheduledAutoMassDmMessage={taskOccurrence.task}
                        setSelectedDate={setSelectedDate}
                        isFutureScheduledMessage={taskOccurrence.isRepeat}
                        futureScheduledDate={taskOccurrence.isRepeat ? taskOccurrence.dateTime : undefined}
                        refetchMassDmsSchedule={refetchMassDmsSchedule}
                        refetchMassDms={refetchMassDms}
                    />
                ))}
            </Stack>
        </Box>
    );
};

export default MessageUsersAutoMassDmCalendarDayView;
