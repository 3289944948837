import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Divider, Grid, Theme, useTheme } from '@mui/material';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { emptyOnlyFansMassDMMessageRequest, OnlyFansMassDMMessageSchedule } from '../../../../hooks/useMessageUsersMassDmList';
import { OnlyFansSubscriber } from '../../../../hooks/useSubscriber';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import StyledCard from '../../../common/StyledCard';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';
import MessageUsersMassDmAutoMassDmMessageForm from './MessageUsersAutoMassDmMessageForm';

/**
 * Props for the MessageUsersMassDmAutoMassDmMessageForm component.
 * @property {OnlyFansSubscriber} subscriber - The subscriber data.
 * @property {() => void} refetchMassDms - Function to refetch the Mass DMs.
 * @property {() => void} handleCancel - Function to handle the cancel action.
 * @property {(show: boolean) => void} setShowAddNewForm - Function to set the visibility of the add new form.
 */
type Props = {
    subscriber: OnlyFansSubscriber;
    refetchMassDms: () => void;
    handleCancel: () => void;
    setShowForm: (show: boolean) => void;
};

const MessageUsersAutoMassDmAddMassDm = ({ subscriber, refetchMassDms, handleCancel, setShowForm }: Props) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const params = useParams();
    const axios = useAxios();
    const { enqueueSnackbar } = useSnackbar();
    const dialog = useDialog();
    const queryClient = useQueryClient();

    const [formData, setFormData] = useState<OnlyFansMassDMMessageSchedule>(emptyOnlyFansMassDMMessageRequest);
    const [isFormInvalid, setIsFormInvalid] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const massDmAdd = async (newMassDm: OnlyFansMassDMMessageSchedule) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsSubmitting(true);

        const data = await axios
            .post(`${settingsContext.routes.massDm.base}${params.userId}/schedule`, newMassDm)
            .then(async response => {
                setIsSubmitting(false);
                return response.data as OnlyFansMassDMMessageSchedule;
            })
            .catch(error => {
                setIsSubmitting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleSubmit = () => {
        massDmAdd(formData).then(data => {
            if (!data) {
                return;
            }

            enqueueSnackbar('Mass DM created successfully', { variant: 'success' });

            if (formData.scheduleDate) {
                // Invalidate the scheduled messages query
                queryClient.invalidateQueries(['subscriberMassDmsSchedule']);
            } else {
                refetchMassDms();
            }

            // Reset the form
            setFormData(emptyOnlyFansMassDMMessageRequest);

            setShowForm(false);
        });
    };

    return (
        <>
            <StyledCard noCard noBottomMargin>
                <StyledCardTitleBar title="New Mass DM" theme={theme} />
            </StyledCard>
            <StyledCard>
                <MessageUsersMassDmAutoMassDmMessageForm
                    subscriber={subscriber}
                    setIsFormInvalid={setIsFormInvalid}
                    formData={formData}
                    setFormData={setFormData}
                />

                <Grid container alignItems={'center'} justifyContent={'center'} spacing={2}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={8}>
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            fullWidth
                            size="large"
                            loading={isSubmitting}
                            disabled={isFormInvalid}
                            onClick={handleSubmit}
                        >
                            {formData.scheduleDate ? 'Schedule Mass DM' : 'Send Mass DM'}
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="outlined" color="error" fullWidth size="large" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </StyledCard>
        </>
    );
};

export default MessageUsersAutoMassDmAddMassDm;
