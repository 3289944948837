import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import { SextforceAutoCampaignTipReply } from './useSextforceAutoCampaignTipReply';
import { SextforceAutoCampaignTipReplyLog } from './useSextforceAutoCampaignTipReplyLogs';

export interface SextforceAutoCampaignTipReplyWithLog extends SextforceAutoCampaignTipReply {
    log: SextforceAutoCampaignTipReplyLog;
}

const useSextforceAutoCampaignTipReplyLogsBySubscriber = (offset: number, limit: number) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const axios = useAxios();
    const dialog = useDialog();
    const params = useParams();

    // Fetch online users count vs. total sales report
    const fetchData = async (
        offset: number,
        limit: number,
    ): Promise<{
        metadata: {
            total: number;
            offset: number;
            limit: number;
        };
        data: SextforceAutoCampaignTipReplyWithLog[];
    }> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return {
                metadata: {
                    total: 0,
                    offset: 0,
                    limit: 0,
                },
                data: [],
            };
        }

        const url: string = `${settingsContext.routes.autoCampaignTipReply.base}${params.userId}/logs?offset=${offset}&limit=${limit}`;

        const data = await axios
            .get(url)
            .then(async response => response.data)
            .catch(error => {
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    const autoCampaignTipReplyLogsBySubscriber = useQuery(
        ['autoCampaignTipReplyLogsBySubscriber', params.userId, userContext.jwtToken, offset, limit],
        () => {
            return fetchData(offset, limit);
        },
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: userContext.jwtToken && params.userId && settingsContext.apiKey ? true : false,
        },
    );

    return {
        ...autoCampaignTipReplyLogsBySubscriber,
    };
};

export default useSextforceAutoCampaignTipReplyLogsBySubscriber;
