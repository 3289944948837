import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';
import { OnlyFansMassDMMessageScheduleDocumentWithColor } from './useMessageUsersMassDmList';
import useSubscriber from './useSubscriber';

export type MassDmScheduleListSortBy = '_id' | 'scheduleDate' | 'name' | 'colorId';

const useMessageUsersMassDmScheduleList = (query: string, active: boolean | null, sortBy: MassDmScheduleListSortBy) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const axios = useAxios();
    const { enqueueSnackbar } = useSnackbar();

    const { data: subscriber } = useSubscriber();

    // Fetch Scheduled Messages
    const fetchScheduledMessages = async (): Promise<OnlyFansMassDMMessageScheduleDocumentWithColor[]> => {
        const url: string = `${settingsContext.routes.massDm.base}${params.userId}/schedule?${new URLSearchParams({
            query: query.trim(),
            active: typeof active === 'boolean' ? (active ? 'true' : 'false') : 'null',
            sortBy: sortBy,
        })}`;

        return axios
            .get(url)
            .then(async response => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    return Promise.reject(response.data);
                }
            })
            .catch(error => {
                console.error(error);

                enqueueSnackbar('Failed to fetch scheduled messages', { variant: 'error' });

                return [];
            });
    };

    const messageUsersScheduledSessions = useQuery(
        ['subscriberMassDmsSchedule', subscriber?._id, query, active, sortBy],
        () => fetchScheduledMessages(),
        {
            refetchOnWindowFocus: false,
            staleTime: 5000,
            enabled:
                userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber && subscriber._id
                    ? true
                    : false,
        },
    );

    return {
        ...messageUsersScheduledSessions,
    };
};

export default useMessageUsersMassDmScheduleList;
