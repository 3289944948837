import { Grid, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { useMemo } from 'react';
import PageContainer from '../../components/common/PageContainter';
import SharedCreditCard from '../../components/common/SharedCreditCard';
import Last7DaysDailyStats from '../../components/subscriber/Last7DaysDailyStats';
import Last7DaysOnlineUsersCountGraph from '../../components/subscriber/Last7DaysOnlineUsersCountGraph';
import SubscriberCard from '../../components/subscriber/SubscriberCard';
import useSubscriber from '../../hooks/useSubscriber';
import useSubscriberCategories from '../../hooks/useSubscriberCategories';
import useSubscribers from '../../hooks/useSubscribers';
import SubscriberOverviewServices from './SubscriberOverviewServices';

type Props = {
    // refetchSubscribers: () => void;
};

function SubscriberOverview(props: Props) {
    // const { refetchSubscribers } = props;
    const theme: Theme = useTheme();
    const { data: subscriber } = useSubscriber();
    const { data: categories, isFetching: categoriesLoading } = useSubscriberCategories();
    const { refetch: refetchSubscribers } = useSubscribers();

    const category = useMemo(() => {
        if (categoriesLoading || !subscriber || !subscriber.cognito || !subscriber.cognito.category) {
            return undefined;
        }

        return categories && categories.find(c => c._id === subscriber.cognito!.category);
    }, [categories, categoriesLoading, subscriber]);

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        Account Overview
                    </Typography>
                    <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(1) }}>
                        <Grid item xs={12} md={8}>
                            <SubscriberCard subscriber={subscriber} category={category} refetchSubscribers={refetchSubscribers} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SharedCreditCard showTopUpButton={true} />
                        </Grid>
                    </Grid>

                    <Last7DaysDailyStats />

                    <Last7DaysOnlineUsersCountGraph />

                    <SubscriberOverviewServices subscriber={subscriber} />
                </>
            )}
        </PageContainer>
    );
}

export default SubscriberOverview;
