import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { Alert, Divider, Grid, IconButton, MenuItem, Select, SxProps, Theme, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import useSubscriber, { OnlyFansSubscriber } from '../../hooks/useSubscriber';
import useSubscriberLists from '../../hooks/useSubscriberLists';
import { OnlyFansLists } from '../../types/onlyFansTypes';
import { stripHtmlAndConvertParagraphs } from '../../utils/common';
import SextforceLiveStreamAddTippersToListCreateListDialog from '../services/hostess/liveStreams/addTippersToList/SextforceLiveStreamAddTippersToListCreateListDialog';
import StyledLocalMenu from './StyledLocalMenu';
import TextInputDialog from './TextInputDialog';

const LocalMenu = ({
    sortLists,
    setSortLists,
    isLoading,
    isCreatingList,
    refetchLists,
    showCreateListButton,
    setIsCreateListDialogOpen,
    disabled,
    subscriber,
    isSubscriberLoading,
    searchText,
    setSearchText,
    searchTextDialogOpen,
    setSearchTextDialogOpen,
}: {
    sortLists: boolean;
    setSortLists: (sortLists: boolean) => void;
    isLoading: boolean;
    isCreatingList: boolean;
    refetchLists: () => void;
    showCreateListButton?: boolean;
    setIsCreateListDialogOpen: (isOpen: boolean) => void;
    disabled?: boolean;
    subscriber: OnlyFansSubscriber | null | undefined;
    isSubscriberLoading: boolean;
    searchText: string;
    setSearchText: (searchText: string) => void;
    searchTextDialogOpen: boolean;
    setSearchTextDialogOpen: (isOpen: boolean) => void;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const sortMenuOpen = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton disabled={disabled} onClick={e => handleMenuClick(e)}>
                <MoreHorizIcon />
            </IconButton>
            <StyledLocalMenu
                id="trial-menu"
                MenuListProps={{
                    'aria-labelledby': 'trial-button',
                }}
                anchorEl={anchorEl}
                open={sortMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    disabled={isLoading || isCreatingList}
                    onClick={() => {
                        setSearchTextDialogOpen(true);
                        handleMenuClose();
                    }}
                    disableRipple
                >
                    <SearchIcon color="primary" />
                    Search lists...
                </MenuItem>
                <Divider />
                <MenuItem
                    disabled={isLoading || isCreatingList}
                    onClick={() => {
                        setSortLists(!sortLists);
                        handleMenuClose();
                    }}
                    disableRipple
                >
                    <SortByAlphaIcon color={sortLists ? 'primary' : 'secondary'} />
                    {sortLists ? 'Custom sort like on OnlyFans' : 'Sort lists alphabetically'}
                </MenuItem>
                <Divider />
                <MenuItem
                    disabled={isLoading || isCreatingList}
                    onClick={() => {
                        refetchLists();
                        handleMenuClose();
                    }}
                    disableRipple
                >
                    <RefreshIcon color="secondary" />
                    Reload Lists from OnlyFans
                </MenuItem>
                {showCreateListButton && [
                    <Divider key={'divider'} />,
                    <MenuItem
                        key={'create-list'}
                        disabled={isSubscriberLoading || !subscriber || isCreatingList || disabled}
                        onClick={() => {
                            setIsCreateListDialogOpen(true);
                            handleMenuClose();
                        }}
                        disableRipple
                    >
                        <AddIcon color="success" />
                        Create new OnlyFans list...
                    </MenuItem>,
                ]}
            </StyledLocalMenu>
        </>
    );
};

type Props = {
    targetList: { id: number | OnlyFansLists.Type; name: string } | undefined | null;
    setTargetList: (targetLists: { id: number | OnlyFansLists.Type; name: string } | undefined | null) => void;
    includeBuiltInLists: boolean;
    showCreateListButton?: boolean;
    size?: 'small' | 'medium';
    disabled?: boolean;
    sx?: SxProps<Theme>;
};

const OnlyFansListsSelector = (props: Props) => {
    const { targetList, setTargetList, includeBuiltInLists, showCreateListButton, size, disabled, sx } = props;
    const theme: Theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const { data: subscriber, isLoading: isSubscriberLoading } = useSubscriber();
    const {
        lists,
        hasMore,
        loadedListsCount,
        isLoading: listsLoading,
        isFetching: listsFetching,
        refetch: refetchLists,
        createList,
        isCreatingList,
    } = useSubscriberLists(true);

    const [searchText, setSearchText] = useState<string>('');
    const [searchTextDialogOpen, setSearchTextDialogOpen] = useState<boolean>(false);

    const [isCreateListDialogOpen, setIsCreateListDialogOpen] = useState(false);

    const handleCreateList = (listName: string) => {
        createList(listName).then(newList => {
            if (!newList) {
                enqueueSnackbar('Failed to create list!', { variant: 'error' });
                return;
            }

            enqueueSnackbar('List created!', { variant: 'success' });

            setTargetList(newList);

            setIsCreateListDialogOpen(false);
        });
    };

    const [sortLists, setSortLists] = useState<boolean>(true);
    const [sortedLists, setSortedLists] = useState<OnlyFansLists.List[] | void | undefined>([]);

    // Memoize the sorting function
    const sortListsData = useCallback(
        (data: OnlyFansLists.List[]) => {
            if (!sortLists) return data;

            return [...data].sort((a, b) => {
                const x = a.name.toLowerCase();
                const y = b.name.toLowerCase();

                return x < y ? -1 : x > y ? 1 : 0;
            });
        },
        [sortLists],
    );

    // Update sorted lists whenever lists or sort preference changes
    useEffect(() => {
        if (!lists) {
            setSortedLists([]);
            return;
        }

        if (!includeBuiltInLists) {
            // Filter out lists who's id is not a number
            let filteredLists = lists.filter(list => typeof list.id === 'number');

            if (searchText) {
                filteredLists = filteredLists.filter(list => list.name.toLowerCase().includes(searchText.toLowerCase()));
            }

            const newSortedLists = sortListsData(filteredLists);

            setSortedLists(newSortedLists);

            return;
        }

        const filteredLists = searchText ? lists.filter(list => list.name.toLowerCase().includes(searchText.toLowerCase())) : lists;

        const newSortedLists = sortListsData(filteredLists);

        setSortedLists(newSortedLists);
    }, [lists, sortListsData, searchText, includeBuiltInLists]);

    const isLoading = listsLoading || listsFetching || hasMore;

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
            <Grid item xs>
                {sortedLists && typeof sortedLists !== 'undefined' ? (
                    <Select
                        value={!isLoading && !isCreatingList && targetList ? targetList.id : -1}
                        size={size}
                        disabled={disabled}
                        fullWidth
                        onChange={event => {
                            setTargetList(
                                sortedLists.find(list => list.id === event.target.value)
                                    ? {
                                          id: event.target.value as number | OnlyFansLists.Type,
                                          name: sortedLists.find(list => list.id === event.target.value)?.name || '',
                                      }
                                    : undefined,
                            );
                        }}
                        sx={{ width: '100%', ...sx }}
                    >
                        {(isLoading || isCreatingList) && (
                            <MenuItem value={-1} disabled sx={{ opacity: 0.5 }}>
                                <em>Loading ({loadedListsCount.toLocaleString()})...</em>
                            </MenuItem>
                        )}
                        {!isLoading && !isCreatingList && lists && lists.length === 0 && (
                            <MenuItem value={-1} disabled sx={{ opacity: 0.5 }}>
                                <em>No Lists Found</em>
                            </MenuItem>
                        )}
                        {!isLoading && !isCreatingList && lists && lists.length > 0 && (
                            <MenuItem value={-1} disabled sx={{ opacity: 0.5 }}>
                                <em>Select a List</em>
                            </MenuItem>
                        )}
                        {!isLoading &&
                            !isCreatingList &&
                            sortedLists &&
                            sortedLists.map((list: any) => (
                                <MenuItem value={list.id} key={list.id}>
                                    <Grid container spacing={0} sx={{ margin: 0, padding: 0 }}>
                                        <Grid item xs>
                                            {stripHtmlAndConvertParagraphs(list.name)}
                                        </Grid>
                                        <Grid item xs="auto" sx={{ paddingRight: 2 }}>
                                            <span style={{ color: theme.palette.grey[700] }}>
                                                {(list.usersCount as number).toLocaleString()} Users
                                            </span>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                    </Select>
                ) : (
                    <Alert severity="error">Could not load lists!</Alert>
                )}
            </Grid>
            <Grid item xs={'auto'}>
                <LocalMenu
                    sortLists={sortLists}
                    setSortLists={setSortLists}
                    isLoading={isLoading}
                    isCreatingList={isCreatingList}
                    refetchLists={refetchLists}
                    showCreateListButton={showCreateListButton}
                    setIsCreateListDialogOpen={setIsCreateListDialogOpen}
                    disabled={disabled}
                    subscriber={subscriber}
                    isSubscriberLoading={isSubscriberLoading}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    searchTextDialogOpen={searchTextDialogOpen}
                    setSearchTextDialogOpen={setSearchTextDialogOpen}
                />
                {searchTextDialogOpen && (
                    <TextInputDialog
                        open={searchTextDialogOpen}
                        onClose={() => setSearchTextDialogOpen(false)}
                        onSubmit={value => {
                            setSearchText(value.trim());
                            setSearchTextDialogOpen(false);
                        }}
                        title="Search"
                        contentText="Search for lists by name"
                        label="Search"
                        placeholder="Search"
                        initialValue={searchText}
                        submitButtonText="Search"
                        clearButton={true}
                    />
                )}
                {showCreateListButton && (
                    <SextforceLiveStreamAddTippersToListCreateListDialog
                        open={isCreateListDialogOpen}
                        onClose={() => setIsCreateListDialogOpen(false)}
                        handleSubmit={handleCreateList}
                        theme={theme}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default OnlyFansListsSelector;
