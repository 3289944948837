import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    PaletteColor,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { useDialog } from 'muibox';
import { useContext, useEffect, useMemo, useState } from 'react';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import LargeMaterialUISwitch from '../../../components/common/LargeMaterialUISwitch';
import PageContainer from '../../../components/common/PageContainter';
import StyledCard from '../../../components/common/StyledCard';
import StyledCardTitleBar from '../../../components/common/StyledCardTitleBar';
import useSextforceBigBrotherRestrictedWords from '../../../hooks/useSextforceBigBrotherRestrictedWords';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';

const SextforceAutoRepostDescription = () => {
    return (
        <>
            <Typography variant="body1" gutterBottom>
                Restricted Words Monitor keeps an eye on your outgoing messsages in chats to look out for any restricted words or mentions
                of <strong>Crypto Wallets</strong> that could get you in trouble with the OnlyFans police. If a restricted word or a crypto
                wallet is detected, the system will automatically delete the message instantly and notify you on Telegram so you know what's
                going on.
            </Typography>
            <Typography variant="body1">
                The system looks for restricted words and crypto wallets all the time while Sextforce is running, but it won't delete any
                messages if the service isn't turned on below. Some of the words you might want to allow, so you can add them to the white
                list below.
            </Typography>
        </>
    );
};

const RenderRow = ({
    word,
    whiteList,
    handleAddWordToWhiteList,
    handleRemoveWordFromWhiteList,
}: {
    word: string;
    whiteList: string[];
    handleAddWordToWhiteList: (word: string) => void;
    handleRemoveWordFromWhiteList: (word: string) => void;
}) => {
    return (
        <ListItem component="div" disablePadding>
            <ListItemButton
                onClick={() => {
                    if (whiteList.includes(word)) {
                        handleRemoveWordFromWhiteList(word);
                    } else {
                        handleAddWordToWhiteList(word);
                    }
                }}
            >
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={whiteList.includes(word)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': word }}
                    />
                </ListItemIcon>
                <ListItemText primary={word} />
            </ListItemButton>
        </ListItem>
    );
};

/**
 * List component with checkboxes for the restricted words white list. Receives a list of all the restricted words, and a list of the words that are in the white list.
 */
const RestrictedWordsWhiteListWithCheckboxes = ({
    groupKeys,
    groups,
    whiteList,
    handleAddWordToWhiteList,
    handleRemoveWordFromWhiteList,
}: {
    groupKeys: string[];
    groups: { [key: string]: string[] };
    whiteList: string[];
    handleAddWordToWhiteList: (word: string) => void;
    handleRemoveWordFromWhiteList: (word: string) => void;
}) => {
    const [tabValue, setTabValue] = useState<number>(0);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
            >
                {groupKeys.map((key: string) => (
                    <Tab key={`tab-${key}`} label={key} id={`tab-${key}`} />
                ))}
            </Tabs>

            <Box
                sx={{
                    display: 'block',
                    overflowX: 'auto',
                    maxHeight: '500px',
                }}
            >
                {groupKeys.map((key: string, index: number) => (
                    <div
                        key={`list-${key}`}
                        role="tabpanel"
                        hidden={tabValue !== index}
                        id={`tabpanel-${key}`}
                        aria-labelledby={`tab-${key}`}
                    >
                        {tabValue === index && (
                            <List
                                sx={{
                                    bgcolor: 'grey.100',
                                }}
                            >
                                {groups[key].map((word: string) => (
                                    <RenderRow
                                        key={word}
                                        word={word}
                                        whiteList={whiteList}
                                        handleAddWordToWhiteList={handleAddWordToWhiteList}
                                        handleRemoveWordFromWhiteList={handleRemoveWordFromWhiteList}
                                    />
                                ))}
                            </List>
                        )}
                    </div>
                ))}
            </Box>
        </>
    );
};

const CounterCard = ({ title, count, color }: { title: string; count: number; color: PaletteColor }) => {
    const theme: Theme = useTheme();

    return (
        <Grid
            container
            spacing={1}
            flexGrow={0}
            alignItems="center"
            sx={{
                backgroundColor: color.main,
                padding: '0.5rem',
                borderRadius: theme.shape.borderRadius,
                color: 'white',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid item>{title}</Grid>
            <Grid item>
                <Typography variant="h5">{count}</Typography>
            </Grid>
        </Grid>
    );
};

const SextforceBigBrotherRestrictedWords = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const [active, setActive] = useState<boolean>(true);
    const [whiteList, setWhiteList] = useState<string[]>([]);
    const [restrictedWordsFoundCount, setRestrictedWordsFoundCount] = useState<number>(0);
    const [restrictedWordsDeletedCount, setRestrictedWordsDeletedCount] = useState<number>(0);

    const { data: subscriber, isLoading: isSubscriberLoading, setServiceRunning, setServiceRunningLoading } = useSubscriber();
    const {
        data: restrictedWords,
        isLoading: isRestrictedWordsLoading,
        sextforceBigBrotherRestrictedWordsWhiteListLoading,
        setSextforceBigBrotherRestrictedWordsWhiteList,
        setSextforceBigBrotherRestrictedWordsResetCounters,
        SextforceBigBrotherRestrictedWordsResetCountersLoading,
    } = useSextforceBigBrotherRestrictedWords();

    // Groups all the words in the restrictedWords array by the first letter of the word
    const groups: { [key: string]: string[] } = useMemo(() => {
        if (!restrictedWords || (restrictedWords && restrictedWords.size === 0)) return {};

        const groups: { [key: string]: string[] } = {};

        // Iterate over a Set
        for (const word of restrictedWords) {
            // const word = restrictedWords[i];
            const firstLetter = word.charAt(0).toUpperCase();
            if (!groups[firstLetter]) groups[firstLetter] = [];
            groups[firstLetter].push(word);
        }

        return groups;
    }, [restrictedWords]);

    // Creates a list of the first letter of each group
    const groupKeys = Object.keys(groups);

    useEffect(() => {
        if (subscriber) {
            setActive(subscriber.sextforce?.bigBrother?.active || false);
            setWhiteList(subscriber.sextforce?.bigBrother?.restrictedWordsWhiteList || []);
            setRestrictedWordsFoundCount(subscriber.sextforce?.bigBrother?.restrictedWordsFoundCount || 0);
            setRestrictedWordsDeletedCount(subscriber.sextforce?.bigBrother?.restrictedWordsDeletedCount || 0);
        }
    }, [subscriber]);

    const handleAddWordToWhiteList = (word: string) => {
        setWhiteList([...whiteList, word]);
    };

    const handleRemoveWordFromWhiteList = (word: string) => {
        setWhiteList(whiteList.filter((w: string) => w !== word));
    };

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}`} />{' '}
                        Restricted Words Monitor for {subscriber.username}
                    </Typography>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Restricted words Monitor" theme={theme} />
                    </StyledCard>
                    <StyledCard>
                        <SextforceAutoRepostDescription />
                    </StyledCard>

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                Restricted Words Monitor is currently{' '}
                                {active ? (
                                    <span style={{ color: theme.palette.success.dark }}>running</span>
                                ) : (
                                    <span style={{ color: theme.palette.error.main }}>not running</span>
                                )}
                                .
                            </Grid>
                            <Grid item xs="auto">
                                {isSubscriberLoading || setServiceRunningLoading ? (
                                    <Skeleton width={'100%'} height={51} />
                                ) : (
                                    <LargeMaterialUISwitch
                                        checked={active}
                                        onChange={e => {
                                            setServiceRunning('sextforce.bigBrother', e.target.checked);
                                            setActive(e.target.checked);
                                        }}
                                        color="primary"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </StyledCard>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title={`Detector`} isLoading={isSubscriberLoading} theme={theme} />
                    </StyledCard>

                    <StyledCard>
                        <Typography variant="body1">
                            The system counts how many words from the restricted list it has detected and deleted in real-time as the
                            messages are sent out from your account.
                        </Typography>
                    </StyledCard>

                    <StyledCard noCard>
                        <Stack direction="row" spacing={1} flexGrow={0} alignItems="center">
                            <CounterCard title="Restricted Words Detected" count={restrictedWordsFoundCount} color={theme.palette.error} />
                            <CounterCard
                                title="Restricted Words Deleted"
                                count={restrictedWordsDeletedCount}
                                color={theme.palette.success}
                            />
                        </Stack>
                    </StyledCard>

                    <StyledCard noCard>
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            size="large"
                            fullWidth
                            loading={SextforceBigBrotherRestrictedWordsResetCountersLoading}
                            onClick={() => {
                                dialog
                                    .confirm({ title: 'Confirm', message: 'Are you sure you want to reset the counters?' })
                                    .then(() => {
                                        setSextforceBigBrotherRestrictedWordsResetCounters().then(data => {
                                            setRestrictedWordsFoundCount(data.restrictedWordsFoundCount || 0);
                                            setRestrictedWordsDeletedCount(data.restrictedWordsDeletedCount || 0);
                                        });
                                    })
                                    .catch(() => {});
                            }}
                        >
                            Reset Counters
                        </LoadingButton>
                    </StyledCard>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title={`White List`} isLoading={isSubscriberLoading} theme={theme} />
                    </StyledCard>

                    <StyledCard>
                        <Typography variant="body1" gutterBottom>
                            The list of restricted words is comprehensive and includes words that are not necessarily bad. For example, the
                            word "call" is restricted to prevent conversation about talking on the phone. But at the same time, it can be
                            used in legitimate conversation like "How do I call you?".
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            It's up to you to decide which words are allowed. By default, all words are restricted. Select the words you
                            want to allow in the white list below and hit Save Settings below.
                        </Typography>

                        {isRestrictedWordsLoading && <Skeleton width={'100%'} height={200} />}
                        {restrictedWords && restrictedWords.size > 0 && (
                            <>
                                <Stack direction="column" spacing={1} sx={{ mb: 2 }}>
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                        <div>Words in White List: {whiteList.length.toLocaleString()}</div>
                                        <div>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                disabled={whiteList.length === 0}
                                                onClick={() => {
                                                    dialog
                                                        .confirm({
                                                            title: 'Confirm',
                                                            message: 'Are you sure you want to clear the white list?',
                                                        })
                                                        .then(() => {
                                                            setWhiteList([]);
                                                        })
                                                        .catch(() => {});
                                                }}
                                            >
                                                Clear White List
                                            </Button>
                                        </div>
                                    </Stack>

                                    <RestrictedWordsWhiteListWithCheckboxes
                                        groupKeys={groupKeys}
                                        groups={groups}
                                        whiteList={whiteList}
                                        handleAddWordToWhiteList={handleAddWordToWhiteList}
                                        handleRemoveWordFromWhiteList={handleRemoveWordFromWhiteList}
                                    />

                                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                                        Total restricted words: {restrictedWords.size.toLocaleString()}
                                    </Typography>
                                </Stack>
                            </>
                        )}
                        {restrictedWords && restrictedWords.size === 0 && 'No restricted words found.'}
                    </StyledCard>

                    <StyledCard noCard>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            loading={sextforceBigBrotherRestrictedWordsWhiteListLoading}
                            onClick={() => {
                                // Save the form data
                                setSextforceBigBrotherRestrictedWordsWhiteList(whiteList).then(() => {
                                    dialog
                                        .alert({
                                            title: 'Success',
                                            message: 'Settings saved successfully',
                                        })
                                        .then(() => {});
                                });
                            }}
                        >
                            Save Changes
                        </LoadingButton>
                    </StyledCard>
                </>
            )}
        </PageContainer>
    );
};

export default SextforceBigBrotherRestrictedWords;
