import { Grid, Stack, Theme, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import useMetricsDailyStats from '../../hooks/useMetricsDailyStats';
import StatsCardEarningsAll from './StatsCardEarningsAll';
import StatsCardFansActive from './StatsCardFansActive';
import StatsCardFollowingActive from './StatsCardFollowingActive';
import StatsCardTopPerformer from './StatsCardTopPerformer';

const Last7DaysDailyStats = () => {
    const theme: Theme = useTheme();
    const timezone: string = useMemo(() => moment.tz.guess(), []);
    const startDate: Date = useMemo(() => moment().subtract(7, 'days').startOf('day').toDate(), []);
    const endDate: Date = useMemo(() => moment().endOf('day').toDate(), []);

    const { data: dailyStats, isFetching: dailyStatsLoading } = useMetricsDailyStats(startDate, endDate, timezone);

    const [amountType, setAmountType] = useState<'gross' | 'net'>(
        localStorage.getItem('showEarningsAsGross') === 'false' ? 'net' : 'gross',
    );

    return (
        <div style={{ marginTop: theme.spacing(4) }}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginBottom: 2 }}>
                <Typography variant="h5">Account Gains</Typography>
                <ToggleButtonGroup
                    value={amountType}
                    exclusive
                    color="secondary"
                    size={'small'}
                    onChange={(_event, newValue) => {
                        if (!newValue) {
                            return;
                        }

                        setAmountType(newValue);
                        localStorage.setItem('showEarningsAsGross', newValue === 'gross' ? 'true' : 'false');
                    }}
                    sx={{ marginTop: '4px', height: '39px' }}
                >
                    <ToggleButton value="gross" fullWidth sx={{ minWidth: '80px' }}>
                        GROSS
                    </ToggleButton>
                    <ToggleButton value="net" fullWidth sx={{ minWidth: '80px' }}>
                        NET
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                <Grid item xs={12} sm={6} md={3}>
                    <StatsCardFansActive dailyStats={dailyStats} dailyStatsLoading={dailyStatsLoading} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatsCardFollowingActive dailyStats={dailyStats} dailyStatsLoading={dailyStatsLoading} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatsCardTopPerformer dailyStats={dailyStats} dailyStatsLoading={dailyStatsLoading} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <StatsCardEarningsAll dailyStats={dailyStats} dailyStatsLoading={dailyStatsLoading} amountType={amountType} />
                </Grid>
            </Grid>
        </div>
    );
};

export default Last7DaysDailyStats;
