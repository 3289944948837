import { alpha, Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { OnlyFansWSChatQueueUpdateMessage } from '../../../../hooks/useSubscriberMassDmQueue';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import MessageUsersAutoMassDmQueueListRow from './MessageUsersAutoMassDmQueueListRow';

interface Props {
    massDmQueue: OnlyFansWSChatQueueUpdateMessage.ChatQueueUpdate[];
    refetchMassDmQueue: () => void;
    refetchMassDms: () => void;
}

const MessageUsersAutoMassDmQueueList = (props: Props) => {
    const { massDmQueue, refetchMassDmQueue, refetchMassDms } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();
    const theme: Theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [list, setList] = useState<OnlyFansWSChatQueueUpdateMessage.ChatQueueUpdate[]>(massDmQueue);
    const [lastUpdatedDate, setLastUpdatedDate] = useState<Date | null>(null);

    const [isAnimating, setIsAnimating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        setIsAnimating(true);
        const timer = setTimeout(() => {
            setIsAnimating(false);
        }, 500); // Animation duration

        return () => clearTimeout(timer);
    }, [lastUpdatedDate]); // Empty dependency array since we want to trigger on mount/key change

    useEffect(() => {
        if (massDmQueue) {
            setList(massDmQueue);
            setLastUpdatedDate(new Date());
        }

        return () => {
            setList([]);
            setLastUpdatedDate(null);
        };
    }, [massDmQueue]);

    const unsendMassDm = async (massDmId: number) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsDeleting(true);

        const data = await axios
            .delete(`${settingsContext.routes.massDm.base}${params.userId}/list/${massDmId}`)
            .then(async response => {
                return response.data as { success: boolean };
            })
            .catch(error => {
                setIsDeleting(false);
                handleHttpError(error, dialog);

                return { success: false };
            });

        return data;
    };

    const handleUnsendMassDm = (massDmId: number) => {
        dialog
            .confirm({ message: 'Are you sure you want to unsend this Mass DM?', title: 'Cancel Mass DM' })
            .then(() => {
                unsendMassDm(massDmId).then(data => {
                    setIsDeleting(false);

                    if (data && data.success) {
                        enqueueSnackbar('Mass DM unsent successfully', { variant: 'success' });

                        refetchMassDms();
                        refetchMassDmQueue();

                        return;
                    }

                    enqueueSnackbar('Failed to unsend Mass DM', { variant: 'error' });
                });
            })
            .catch(() => {});
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'block',
                    overflowX: 'auto',
                }}
            >
                <Table
                    size="small"
                    sx={{
                        '& td': {
                            borderBottom: 'none',
                            paddingRight: 0,
                        },
                        // Bold header
                        '& th': {
                            fontWeight: 'bold',
                            paddingRight: 0,
                        },
                        '& th:last-child, & td:last-child': {
                            paddingRight: 2,
                        },
                        // No wrapping
                        whiteSpace: 'nowrap',
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell width={30} align="center">
                                %
                            </TableCell>
                            <TableCell width={140}>Created At</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell width={90} align="right">
                                Sent
                            </TableCell>
                            <TableCell width={90} align="right">
                                Total
                            </TableCell>
                            <TableCell width={48} align="center">
                                Cancel
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((queueItem, index) => (
                            <MessageUsersAutoMassDmQueueListRow
                                key={queueItem.id}
                                queueItem={queueItem}
                                handleUnsendMassDm={handleUnsendMassDm}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {lastUpdatedDate && (
                <Typography
                    variant="caption"
                    color="textSecondary"
                    key={lastUpdatedDate.valueOf()}
                    sx={{
                        pl: 2,
                    }}
                >
                    <span
                        style={{
                            // animate flash background color from success to transparent when updated
                            transition: theme.transitions.create('background-color', {
                                duration: theme.transitions.duration.short,
                                easing: theme.transitions.easing.easeOut,
                            }),
                            backgroundColor: isAnimating ? alpha(theme.palette.success.main, 0.5) : 'transparent',
                        }}
                    >
                        Last updated: {moment(lastUpdatedDate).format('L LT')}
                    </span>
                </Typography>
            )}
        </Box>
    );
};

export default MessageUsersAutoMassDmQueueList;
