import { Chip, Grid, Skeleton, Tooltip, Typography } from '@mui/material';

const ValueAndTrend = (props: {
    loading?: boolean;
    value: number | undefined;
    previousValue?: number;
    type: 'number' | 'money';
    fractionDigits: number;
    logic?: 'greater' | 'less';
    size?: 'small' | 'medium';
    separateLines?: boolean;
    textAlign?: 'left' | 'center' | 'right';
}) => {
    const { loading, value, previousValue, type, logic, size, separateLines, textAlign, fractionDigits } = props;

    const diff = value !== undefined && previousValue !== undefined ? value - previousValue : 0;

    const calculatedColor = () => {
        if (!previousValue || !value) {
            return 'warning';
        }

        if (logic === 'greater') {
            return value > previousValue ? 'success' : 'error';
        } else if (logic === 'less') {
            return value < previousValue ? 'success' : 'error';
        } else {
            return value === previousValue ? 'warning' : value > previousValue ? 'success' : 'error';
        }
    };

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent={'center'}>
            <Grid item xs={separateLines ? 12 : undefined}>
                <Typography
                    variant={size && size === 'small' ? 'body1' : 'h6'}
                    fontWeight={500}
                    textAlign={textAlign || 'right'}
                    color={'primary.main'}
                >
                    {loading ? (
                        <Skeleton />
                    ) : (
                        <>
                            {type === 'money' ? '$' : ''}
                            {value
                                ? value.toLocaleString(undefined, {
                                      minimumFractionDigits: fractionDigits,
                                      maximumFractionDigits: fractionDigits,
                                  })
                                : '-'}
                        </>
                    )}
                </Typography>
            </Grid>
            {previousValue !== undefined && (
                <Grid item xs={separateLines ? 12 : undefined} textAlign={textAlign || 'right'}>
                    {loading ? (
                        <Skeleton />
                    ) : (
                        <>
                            <Tooltip title="Change from yesterday" placement="top" arrow>
                                <Chip
                                    variant="filled"
                                    color={calculatedColor()}
                                    label={
                                        value
                                            ? diff === 0
                                                ? '0'
                                                : `${diff > 0 ? '+' : ''}${type === 'money' ? '$' : ''}${diff.toLocaleString(undefined, {
                                                      minimumFractionDigits: fractionDigits,
                                                      maximumFractionDigits: fractionDigits,
                                                  })}`
                                            : 'n/a'
                                    }
                                />
                            </Tooltip>
                        </>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default ValueAndTrend;
