import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, Theme, useTheme } from '@mui/material';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import {
    emptyOnlyFansMassDMMessageRequest,
    OnlyFansMassDMMessageSchedule,
    OnlyFansMassDMMessageScheduleDocumentWithColor,
} from '../../../../hooks/useMessageUsersMassDmList';
import { OnlyFansSubscriber } from '../../../../hooks/useSubscriber';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';
import MessageUsersMassDmAutoMassDmMessageForm from './MessageUsersAutoMassDmMessageForm';

const momentPeriods: { [key: string]: string } = {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
};

type Props = {
    subscriber: OnlyFansSubscriber;
    scheduledAutoMassDmMessage: OnlyFansMassDMMessageScheduleDocumentWithColor;
    refetchMassDms: () => void;
    onClose: () => void;
    open: boolean;
};

const MessageUsersAutoMassDmEditMessageDialog = (props: Props) => {
    const { subscriber, scheduledAutoMassDmMessage, refetchMassDms, onClose, open } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const params = useParams();
    const axios = useAxios();
    const { enqueueSnackbar } = useSnackbar();
    const dialog = useDialog();
    const queryClient = useQueryClient();

    const [formData, setFormData] = useState<OnlyFansMassDMMessageSchedule>(scheduledAutoMassDmMessage);
    const [isFormInvalid, setIsFormInvalid] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    const massDmScheduleUpdate = async (newMassDm: OnlyFansMassDMMessageSchedule) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsSubmitting(true);

        const data = await axios
            .put(`${settingsContext.routes.massDm.base}${params.userId}/schedule/${scheduledAutoMassDmMessage._id}`, newMassDm)
            .then(async response => {
                setIsSubmitting(false);
                return response.data as OnlyFansMassDMMessageSchedule;
            })
            .catch(error => {
                setIsSubmitting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleSubmit = () => {
        massDmScheduleUpdate({
            ...formData,
            active: true,
        }).then(data => {
            if (!data) {
                return;
            }

            enqueueSnackbar('Mass DM updated successfully', { variant: 'success' });

            if (formData.scheduleDate) {
                // Invalidate the scheduled messages query
                queryClient.invalidateQueries(['subscriberMassDmsSchedule']);
            } else {
                refetchMassDms();
            }

            // Reset the form
            setFormData(emptyOnlyFansMassDMMessageRequest);

            onClose();
        });
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
                <StyledCardTitleBar title="Change Scheduled Message Settings" theme={theme} />
                <DialogContent>
                    <MessageUsersMassDmAutoMassDmMessageForm
                        subscriber={subscriber}
                        formData={formData}
                        setFormData={setFormData}
                        setIsFormInvalid={setIsFormInvalid}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <Button
                        color="error"
                        size="medium"
                        disabled={isSubmitting}
                        onClick={() => {
                            handleClose({}, 'escapeKeyDown');
                        }}
                    >
                        Close
                    </Button>
                    <LoadingButton
                        color="secondary"
                        variant="contained"
                        size="medium"
                        loading={isSubmitting}
                        disabled={isFormInvalid}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        {formData.scheduleDate ? `${formData.active ? 'Save' : 'Restart'} Scheduled Mass DM` : 'Send Mass DM'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MessageUsersAutoMassDmEditMessageDialog;
