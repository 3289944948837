import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import HelpIcon from '@mui/icons-material/Help';
import { CardContent, Grid, IconButton, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { OnlyFansSubscriber } from '../../../../hooks/useSubscriber';
import { loadDraftsJSEditorStateFromHtml } from '../../../../utils/common';
import OnlyFansMessageEditorWithPreview from '../../../common/OnlyFansMessageEditorWithPreview';
import './CustomEditor.css';
import MessageUsersFormEditorTemplateLoadDialog from './MessageUsersFormEditorTemplateLoadDialog';

type Props = {
    subscriber: OnlyFansSubscriber;
    message: string;
    setMessage: (message: string) => void;
    editorState: EditorState;
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
    messageHasRestrictedWords: boolean;
    messageFoundRestrictedWords: string[];
    checkForRestrictedWords: (text: string) => void;
    usernameReplacement: string;
    setUsernameReplacement: (usernameReplacement: string) => void;
    taggedCreators: { id: number; name: string }[];
    taggedReleaseForms: { id: number; name: string }[];
    lockMessage: boolean;
    lockMessagePrice: number;
    lockedText: boolean;
    saveTemplate: boolean;
    setSaveTemplate: (saveTemplate: boolean) => void;
    saveTemplateName: string;
    setSaveTemplateName: (templateName: string) => void;
    session?: any;
    borderless: boolean;
    theme: Theme;
    disabled?: boolean;
};

const MessageUsersFormEditor = (props: Props) => {
    const {
        subscriber,
        message,
        setMessage,
        editorState,
        setEditorState,
        session,
        messageHasRestrictedWords,
        messageFoundRestrictedWords,
        checkForRestrictedWords,
        usernameReplacement,
        setUsernameReplacement,
        taggedCreators,
        taggedReleaseForms,
        lockMessage,
        lockMessagePrice,
        lockedText,
        saveTemplate,
        setSaveTemplate,
        saveTemplateName,
        setSaveTemplateName,
        borderless,
        theme,
        disabled,
    } = props;
    const [loadTemplateDialogOpen, setLoadTemplateDialogOpen] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (session && session.payload && session.payload.message) {
            setEditorState(loadDraftsJSEditorStateFromHtml(session.payload.message));
            setMessage(session.payload.message);
        }

        if (session && session.payload && session.payload.replaceUsername) {
            setUsernameReplacement(session.payload.replaceUsername);
        }
    }, [session, setEditorState, setMessage, setUsernameReplacement]);

    const handleLoadTemplate = (template: any) => {
        setEditorState(loadDraftsJSEditorStateFromHtml(template.message));
        setMessage(template.message);

        if (template.usernameReplacement) {
            setUsernameReplacement(template.usernameReplacement);
        }
    };

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <Paper variant={borderless ? 'elevation' : 'outlined'} elevation={0}>
                        <CardContent
                            sx={{
                                ...(borderless && {
                                    padding: 0,
                                }),
                            }}
                        >
                            <Grid container spacing={1} alignItems="center" sx={{ marginBottom: 2 }}>
                                <Grid item xs>
                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                        <Typography variant="h6">Message</Typography>
                                        <Tooltip title="Show more" placement="top">
                                            <span>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setIsExpanded(!isExpanded)}
                                                    sx={{ color: isExpanded ? theme.palette.text.secondary : theme.palette.primary.main }}
                                                >
                                                    <HelpIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Stack>
                                </Grid>
                                <Grid item xs="auto">
                                    <Tooltip title="Load from template">
                                        <span>
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    setLoadTemplateDialogOpen(true);
                                                }}
                                                disabled={disabled}
                                            >
                                                <FolderOpenIcon fontSize="large" />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom={isExpanded}>
                                        Enter the message to send below. Use the toolbar icons for styling the text in bold, italic, change
                                        its color and font size.
                                        {!isExpanded && (
                                            <Tooltip title="Show more" placement="top">
                                                <span>
                                                    <IconButton size="small" color="primary" onClick={() => setIsExpanded(!isExpanded)}>
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </Typography>
                                    {isExpanded && (
                                        <>
                                            <Typography gutterBottom>
                                                Click on "ADD %USERNAME%" to add the special %USERNAME% placeholder which will get replaced
                                                with the user's actual username when the message is sent (their orignal username, and not
                                                the thing you renamed them to to avoid accidents).
                                            </Typography>
                                            <Typography>
                                                If the user's name is a default uXXXXXXXX, the system can automatically replace their name
                                                with the word or phrase below, or leave it blank so that their name is not used at all
                                                automatically, in which case when composing your message, make sure to word it in such a way
                                                that it would still make sense if the user's name was not there.
                                            </Typography>
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Replacement for %USERNAME% (i.e. baby, bby, daddy, etc...)"
                                        fullWidth
                                        value={usernameReplacement}
                                        onChange={e => {
                                            setUsernameReplacement(e.target.value);
                                            // debouncedSetMessage(message, e.target.value);
                                        }}
                                        disabled={disabled}
                                    />
                                </Grid>
                            </Grid>

                            <OnlyFansMessageEditorWithPreview
                                subscriber={subscriber}
                                message={message}
                                setMessage={setMessage}
                                editorState={editorState}
                                setEditorState={setEditorState}
                                messageHasRestrictedWords={messageHasRestrictedWords}
                                messageFoundRestrictedWords={messageFoundRestrictedWords}
                                checkForRestrictedWords={checkForRestrictedWords}
                                taggedCreators={taggedCreators}
                                taggedReleaseForms={taggedReleaseForms}
                                lockMessage={lockMessage}
                                lockMessagePrice={lockMessagePrice}
                                lockedText={lockedText}
                                saveTemplate={saveTemplate}
                                setSaveTemplate={setSaveTemplate}
                                saveTemplateName={saveTemplateName}
                                setSaveTemplateName={setSaveTemplateName}
                                showUsernamePlaceholder={true}
                                disabled={disabled}
                            />
                        </CardContent>
                    </Paper>
                </Grid>
            </Grid>
            <MessageUsersFormEditorTemplateLoadDialog
                subscriber={subscriber}
                handleLoadTemplate={handleLoadTemplate}
                loadTemplateDialogOpen={loadTemplateDialogOpen}
                setLoadTemplateDialogOpen={setLoadTemplateDialogOpen}
            />
        </>
    );
};

export default MessageUsersFormEditor;
