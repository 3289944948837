import { Box, useTheme } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';

interface DataPoint {
    time: Date;
    count: number;
}

interface D3EarningsChartProps {
    data: DataPoint[];
    type: 'currency' | 'number';
    color?: string;
    height?: number;
}

const StatsGraphD3: React.FC<D3EarningsChartProps> = ({
    data,
    type,
    color = '#607d8b', // Default color matching the image
    height = 100,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const theme: Theme = useTheme();

    // Handle container width updates
    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.getBoundingClientRect().width);
            }
        };

        updateWidth();
        const resizeObserver = new ResizeObserver(updateWidth);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => resizeObserver.disconnect();
    }, []);

    // Update chart when data or dimensions change
    useEffect(() => {
        if (!data.length || !svgRef.current || width === 0) return;

        // Clear previous content
        d3.select(svgRef.current).selectAll('*').remove();

        // Setup margins
        const margin = { top: 5, right: 0, bottom: 28, left: 0 };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // Create SVG
        const svg = d3.select(svgRef.current).attr('width', width).attr('height', height);

        // Add group for margins
        const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

        // Calculate domain with safe type checking
        const minCount = d3.min(data, d => d.count);
        const maxCount = d3.max(data, d => d.count);

        // Set up scales
        const x = d3
            .scaleTime()
            .domain(d3.extent(data, d => d.time) as [Date, Date])
            .range([0, innerWidth]);

        const y = d3
            .scaleLinear()
            .domain([
                minCount !== undefined ? minCount * 0.95 : 0, // Reduce padding to match image
                maxCount !== undefined ? maxCount * 1.05 : 100,
            ])
            .range([innerHeight, 0]);

        // Create line generator
        const line = d3
            .line<DataPoint>()
            .x(d => x(d.time))
            .y(d => y(d.count))
            .curve(d3.curveMonotoneX);

        // Add the line
        g.append('path').datum(data).attr('fill', 'none').attr('stroke', color).attr('stroke-width', 2).attr('d', line);

        // Create area generator
        const area = d3
            .area<DataPoint>()
            .x(d => x(d.time))
            .y0(innerHeight)
            .y1(d => y(d.count))
            .curve(d3.curveMonotoneX);

        // Add the area
        g.append('path').datum(data).attr('fill', alpha(color, 0.5)).attr('d', area);

        // Add dots for each data point
        g.selectAll('circle')
            .data(data)
            .enter()
            .append('circle')
            .attr('cx', d => x(d.time))
            .attr('cy', d => y(d.count))
            .attr('r', 3)
            .attr('fill', color)
            .attr('class', 'data-point')
            .on('mouseover', (event, d) => {
                if (tooltipRef.current) {
                    const tooltip = d3.select(tooltipRef.current);
                    const tooltipWidth = 120; // Approximate width of tooltip
                    const tooltipHeight = 50; // Approximate height of tooltip

                    // Get mouse position relative to container
                    const containerRect = containerRef.current?.getBoundingClientRect();
                    const mouseX = event.clientX - (containerRect?.left || 0);
                    const mouseY = event.clientY - (containerRect?.top || 0);

                    // Calculate tooltip position
                    let tooltipX = mouseX + 10;
                    let tooltipY = mouseY - 10;

                    // Adjust if tooltip would overflow right edge
                    if (tooltipX + tooltipWidth > width) {
                        tooltipX = mouseX - tooltipWidth - 10;
                    }

                    // Adjust if tooltip would overflow top or bottom
                    if (tooltipY + tooltipHeight > height) {
                        tooltipY = height - tooltipHeight - 5;
                    }
                    if (tooltipY < 0) {
                        tooltipY = 5;
                    }

                    tooltip
                        .style('opacity', 1)
                        .html(
                            `
              <div style="font-weight: ${theme.typography.fontWeightBold}">
                ${d3.timeFormat('%b %d')(d.time)}
              </div>
              <div style="color: ${theme.palette.primary.main}; text-align: right">
                ${
                    type === 'currency'
                        ? d.count.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                          })
                        : d.count.toLocaleString()
                }
              </div>
            `,
                        )
                        .style('left', `${tooltipX}px`)
                        .style('top', `${tooltipY}px`);
                }
            })
            .on('mouseout', () => {
                if (tooltipRef.current) {
                    d3.select(tooltipRef.current).style('opacity', 0);
                }
            });

        // Add date labels at start and end
        const dateExtent = d3.extent(data, d => d.time) as [Date, Date];

        // Add start date label (left-aligned)
        g.append('text')
            .attr('x', 4)
            .attr('y', innerHeight + 12)
            .style('font-size', '10px')
            .style('font-family', 'system-ui')
            .style('text-anchor', 'start')
            .text(d3.timeFormat('%b %d')(dateExtent[0]));

        // Add end date label (right-aligned)
        g.append('text')
            .attr('x', innerWidth - 4)
            .attr('y', innerHeight + 12)
            .style('font-size', '10px')
            .style('font-family', 'system-ui')
            .style('text-anchor', 'end')
            .text(d3.timeFormat('%b %d')(dateExtent[1]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, width, height, color, theme]);

    return (
        <Box ref={containerRef} sx={{ width: '100%', position: 'relative' }}>
            <svg ref={svgRef} />
            <Box
                ref={tooltipRef}
                style={{
                    position: 'absolute',
                    opacity: 0,
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: theme.shape.borderRadius,
                    paddingTop: theme.spacing(0.5),
                    paddingBottom: theme.spacing(0.5),
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                    pointerEvents: 'none',
                    boxShadow: theme.shadows[2],
                    zIndex: 1000,
                    transition: 'opacity 0.2s',
                }}
            />
            <Box
                component="style"
                dangerouslySetInnerHTML={{
                    __html: `
            .data-point {
              cursor: pointer;
            }
            .data-point:hover {
              r: 3;
            }
          `,
                }}
            />
        </Box>
    );
};

export default StatsGraphD3;
