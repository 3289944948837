import { useContext } from 'react';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';
import { OnlyFansSubscriber } from './useSubscriber';
import { OnlyFansVaultMedias } from './useSubscriberVaultAlbums';

const useSubscriberMedia = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    // const dialog = useDialog();
    // const params = useParams();
    const axios = useAxios();

    const getThumbnail = (subscriber: OnlyFansSubscriber, thumbId: number, thumbUrl: string) => {
        if (subscriber && userContext.jwtToken && settingsContext.apiKey) {
            const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultMediaThumb?${new URLSearchParams({
                thumbUrl,
            })}`;

            return axios
                .get(query, {
                    responseType: 'blob',
                })
                .then(response => response.data as Blob)
                .catch(error => {
                    console.error(error);

                    return null;
                });
        }

        return null;
    };

    const getMediaCahcedUrl = async (subscriber: OnlyFansSubscriber, mediaId: number): Promise<{ mediaId: number; url: string } | null> => {
        if (subscriber && userContext.jwtToken && settingsContext.apiKey) {
            const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultMediaThumb/${mediaId}`;

            return axios
                .get(query)
                .then(response => response.data as { mediaId: number; url: string })
                .catch(error => {
                    console.error(error);

                    return null;
                });
        }

        return null;
    };

    const getMediaCahcedReconstructedItem = async (
        subscriber: OnlyFansSubscriber,
        mediaId: number,
    ): Promise<OnlyFansVaultMedias.RestructuredResponseItem | null> => {
        if (subscriber && userContext.jwtToken && settingsContext.apiKey) {
            const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultMediaCached/${mediaId}`;

            return axios
                .get(query)
                .then(response => response.data)
                .catch(error => {
                    console.error(error);

                    return null;
                });
        }

        return null;
    };

    const getAudio = async (subscriber: OnlyFansSubscriber, url: string): Promise<Blob | null> => {
        if (subscriber && userContext.jwtToken && settingsContext.apiKey) {
            const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultMediaThumb?${new URLSearchParams({
                thumbUrl: url,
            })}`;

            return axios
                .get(query, {
                    responseType: 'blob',
                })
                .then(response => response.data as Blob)
                .catch(error => {
                    console.error(error);
                    return null;
                });
        }

        return null;
    };

    return {
        getThumbnail,
        getMediaCahcedReconstructedItem,
        getAudio,
        getMediaCahcedUrl,
    };
};

export default useSubscriberMedia;
