import { Alert, Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import dinero from 'dinero.js';
import moment from 'moment';
import { OnlyFansPosts } from '../../../../hooks/useSubscriberPosts';

const SextforceAutoCampaignTipReplyPostPreview = ({ post }: { post: OnlyFansPosts.List }) => {
    // console.log(post.fundRaising?.presets.map(preset => parseFloat(preset)));
    // console.log(
    //     post.fundRaising?.presets.map(preset => dinero({ amount: Math.trunc(parseFloat(preset) * 100), currency: 'USD' }).toFormat()),
    // );

    return (
        <Alert icon={false} severity="success">
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Post Preview</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">{post.rawText}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" spacing={0}>
                        <div>Posted At: {moment(post.postedAt).format('LLL')}</div>
                        {post.expiredAt && <div>Expired At: {moment(post.expiredAt).format('LLL')}</div>}
                    </Stack>
                </Grid>
                {post.fundRaising && post.fundRaising.presets.length > 0 && (
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="body2">Tip Options:</Typography>
                            {post.fundRaising?.presets.map(preset => (
                                <Chip
                                    color="success"
                                    size="small"
                                    label={`$${parseFloat(preset).toLocaleString(undefined, {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`}
                                    key={preset}
                                />
                            ))}
                        </Stack>
                    </Grid>
                )}
                {post.fundRaising && post.fundRaising.target && (
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="body2">Progress/Target:</Typography>
                            <Chip
                                color={post.fundRaising.targetProgress >= post.fundRaising.target ? 'success' : 'warning'}
                                size="small"
                                label={`${dinero({
                                    amount: Math.trunc(post.fundRaising.targetProgress * 100),
                                    currency: 'USD',
                                }).toFormat()} / ${dinero({
                                    amount: Math.trunc(post.fundRaising.target * 100),
                                    currency: 'USD',
                                }).toFormat()}`}
                            />
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Alert>
    );
};

export default SextforceAutoCampaignTipReplyPostPreview;
