import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { Card, CardContent, Checkbox, Chip, FormControlLabel, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import { OnlyFansLists } from '../../../../types/onlyFansTypes';
import TextInputDialog from '../../../common/TextInputDialog';
import MessageUsersFormExcludeList from './MessageUsersFormExcludeList';
import MessageUsersFormExcludePeriod from './MessageUsersFormExcludePeriod';
import MessageUsersFormExcludeSpent from './MessageUsersFormExcludeSpent';
import MessageUsersFormExcludeLimit from './MessageUsersFormExclueLimit';

type Props = {
    theme: Theme;
    lists: OnlyFansLists.List[] | void | undefined;
    listsLoading: boolean;
    refetchLists: () => void;
    targetUsers: string;

    excludeCreators: boolean;
    setExcludeCreators: (excludeCreators: boolean) => void;

    excludeUnreadChats: boolean;
    setExcludeUnreadChats: (excludeUnreadChats: boolean) => void;

    excludeLimitMessage: boolean;
    setExcludeLimitMessage: (excludeLimitMessage: boolean) => void;
    excludeLimitMessageCount: number;
    setExcludeLimitMessageCount: (count: number) => void;

    excludeUsersList: boolean;
    setExcludeUsersList: (excludeUsersList: boolean) => void;
    excludedLists: any[];
    setExcludedLists: (excludedLists: any[]) => void;

    excludeUsersSpent: boolean;
    setExcludeUsersSpent: (excludeUsersSpent: boolean) => void;
    excludeSpentCondition: string;
    setExcludeSpentCondition: (condition: string) => void;
    excludeSpentAmount: number;
    setExcludeSpentAmount: (amount: number) => void;

    excludeUsersSpentPeriod: boolean;
    setExcludeUsersSpentPeriod: (excludeUsersSpentPeriod: boolean) => void;
    excludeSepntPeriod: string;
    setExcludeSpentPeriod: (period: string) => void;
    excludeSpentPeriodAmount: number;
    setExcludeSpentPeriodAmount: (amount: number) => void;

    excludeUsersMessagedIn: boolean;
    setExcludeUsersMessagedIn: (excludeUsersMessagedIn: boolean) => void;
    excludeUsersMessagedInPeriod: string;
    setExcludeUsersMessagedInPeriod: (period: string) => void;
    excludeUsersMessagedInPeriodAmount: number;
    setExcludeUsersMessagedInPeriodAmount: (amount: number) => void;

    excludeFans: boolean;
    setExcludeFans: (excludeFans: boolean) => void;

    session?: any;
};

const MessageUsersFormExclude = (props: Props) => {
    const {
        lists,
        listsLoading,
        refetchLists,
        targetUsers,
        excludeCreators,
        setExcludeCreators,
        excludeUnreadChats,
        setExcludeUnreadChats,
        excludeLimitMessage,
        setExcludeLimitMessage,
        excludeLimitMessageCount,
        setExcludeLimitMessageCount,
        excludeUsersList,
        setExcludeUsersList,
        excludedLists,
        setExcludedLists,
        excludeUsersSpent,
        setExcludeUsersSpent,
        excludeSpentCondition,
        setExcludeSpentCondition,
        excludeSpentAmount,
        setExcludeSpentAmount,
        excludeUsersSpentPeriod,
        setExcludeUsersSpentPeriod,
        excludeSepntPeriod,
        setExcludeSpentPeriod,
        excludeSpentPeriodAmount,
        setExcludeSpentPeriodAmount,
        excludeUsersMessagedIn,
        setExcludeUsersMessagedIn,
        excludeUsersMessagedInPeriod,
        setExcludeUsersMessagedInPeriod,
        excludeUsersMessagedInPeriodAmount,
        setExcludeUsersMessagedInPeriodAmount,
        excludeFans,
        setExcludeFans,
        session,
        theme,
    } = props;

    const [sortLists, setSortLists] = useState<boolean>(true);
    const [sortedLists, setSortedLists] = useState<OnlyFansLists.List[] | void | undefined>(lists);

    const [searchText, setSearchText] = useState<string>('');
    const [searchTextDialogOpen, setSearchTextDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!lists) {
            return;
        }

        const filteredLists = searchText ? lists.filter(list => list.name.toLowerCase().includes(searchText.toLowerCase())) : lists;

        if (!sortLists) {
            setSortedLists(filteredLists);
            return;
        }

        const sortedData = filteredLists.slice().sort((a: any, b: any) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();

            if (x < y) {
                return -1;
            }

            if (x > y) {
                return 1;
            }

            return 0;
        });

        setSortedLists(sortedData);
    }, [lists, searchText, sortLists]);

    useEffect(() => {
        if (!excludeUsersList) {
            setExcludedLists([]);
        }
    }, [excludeUsersList, setExcludedLists]);

    useEffect(() => {
        if (session && session.payload && session.payload.exclude) {
            const { exclude } = session.payload;

            if (typeof exclude.excludeCreators === 'boolean') {
                setExcludeCreators(exclude.excludeCreators);
            }

            if (typeof exclude.excludeUnreadChats === 'boolean') {
                setExcludeUnreadChats(exclude.excludeUnreadChats);
            }

            if (exclude.excludeLimitMessageCount) {
                setExcludeLimitMessage(true);
                setExcludeLimitMessageCount(exclude.excludeLimitMessageCount);
            }

            if (exclude.excludeLists) {
                setExcludeUsersList(true);
                setExcludedLists(exclude.excludeLists);
            }

            if (exclude.excludeSpentCondition && exclude.excludeSpentAmount) {
                setExcludeUsersSpent(true);
                setExcludeSpentCondition(exclude.excludeSpentCondition);
                setExcludeSpentAmount(exclude.excludeSpentAmount);
            }

            if (exclude.excludeSpentPeriod && exclude.excludeSpentPeriodAmount) {
                setExcludeUsersSpentPeriod(true);
                setExcludeSpentPeriod(exclude.excludeSpentPeriod);
                setExcludeSpentPeriodAmount(exclude.excludeSpentPeriodAmount);
            }

            if (exclude.excludeFans) {
                setExcludeFans(exclude.excludeFans);
            }

            if (exclude.excludeMessagedInPeriod && exclude.excludeMessagedInPeriodAmount) {
                setExcludeUsersMessagedIn(true);
                setExcludeUsersMessagedInPeriod(exclude.excludeMessagedInPeriod);
                setExcludeUsersMessagedInPeriodAmount(exclude.excludeMessagedInPeriodAmount);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
            <Grid item xs={12}>
                <Card variant="elevation">
                    <CardContent>
                        <Grid
                            container
                            spacing={1}
                            flexGrow={0}
                            alignItems="center"
                            sx={{
                                marginBottom: 1,
                            }}
                        >
                            <Grid item xs="auto">
                                <DoNotDisturbOnIcon color="error" />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                    Whom to Exclude?
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={excludeCreators}
                                            onChange={(e, checked) => {
                                                setExcludeCreators(checked);
                                            }}
                                        />
                                    }
                                    label="Skip other creators"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={excludeUnreadChats}
                                            onChange={(e, checked) => {
                                                setExcludeUnreadChats(checked);
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant="inherit">
                                            Skip users with unread messages <span style={{ fontWeight: 'bold' }}>(SLOWER SENDING!)</span>
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={excludeLimitMessage}
                                            onChange={(e, checked) => {
                                                setExcludeLimitMessage(checked);
                                            }}
                                        />
                                    }
                                    label="Limit number of messages to..."
                                />
                            </Grid>
                            {excludeLimitMessage && (
                                <Grid item xs={12} sx={{ marginLeft: 4 }}>
                                    <MessageUsersFormExcludeLimit
                                        count={excludeLimitMessageCount}
                                        setCount={setExcludeLimitMessageCount}
                                        theme={theme}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={excludeUsersList}
                                            onChange={(e, checked) => {
                                                setExcludeUsersList(checked);
                                            }}
                                        />
                                    }
                                    label="Fans in a list..."
                                />
                            </Grid>
                            {excludeUsersList && (
                                <Grid container spacing={1} flexGrow={0} alignItems="center" sx={{ marginLeft: 4 }}>
                                    <Grid item xs>
                                        <Chip
                                            color={!excludedLists || excludedLists.length === 0 ? 'error' : 'primary'}
                                            icon={<CheckCircleIcon />}
                                            label={`${excludedLists ? excludedLists.length : 0} selected`}
                                        />
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Tooltip title="Search">
                                            <IconButton
                                                onClick={() => {
                                                    setSearchTextDialogOpen(true);
                                                }}
                                            >
                                                <SearchIcon fontSize="large" color={searchText ? 'primary' : 'disabled'} />
                                            </IconButton>
                                        </Tooltip>
                                        {searchTextDialogOpen && (
                                            <TextInputDialog
                                                open={searchTextDialogOpen}
                                                onClose={() => setSearchTextDialogOpen(false)}
                                                onSubmit={value => {
                                                    setSearchText(value.trim());
                                                    setSearchTextDialogOpen(false);
                                                }}
                                                title="Search"
                                                contentText="Search for lists by name"
                                                label="Search"
                                                placeholder="Search"
                                                initialValue={searchText}
                                                submitButtonText="Search"
                                                clearButton={true}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Tooltip title="Clear Selection">
                                            <IconButton
                                                color="error"
                                                onClick={() => {
                                                    setExcludedLists([]);
                                                }}
                                            >
                                                <ClearIcon fontSize="large" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Tooltip title="Sort lists alphabetically or custom like on OnlyFans">
                                            <IconButton
                                                onClick={() => {
                                                    setSortLists(!sortLists);
                                                }}
                                            >
                                                <SortByAlphaIcon fontSize="large" color={sortLists ? 'primary' : 'secondary'} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Tooltip title="Reload Lists from OnlyFans">
                                            <IconButton
                                                color="secondary"
                                                onClick={() => {
                                                    refetchLists();
                                                }}
                                            >
                                                <RefreshIcon fontSize="large" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MessageUsersFormExcludeList
                                            lists={sortedLists}
                                            listsLoading={listsLoading}
                                            excludedLists={excludedLists}
                                            setExcludedLists={setExcludedLists}
                                            theme={theme}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={excludeUsersSpent}
                                            onChange={(e, checked) => {
                                                setExcludeUsersSpent(checked);
                                            }}
                                        />
                                    }
                                    label="Users who spent..."
                                />
                            </Grid>
                            {excludeUsersSpent && (
                                <Grid item xs={12} sx={{ marginLeft: 4 }}>
                                    <MessageUsersFormExcludeSpent
                                        condition={excludeSpentCondition}
                                        setCondition={setExcludeSpentCondition}
                                        amount={excludeSpentAmount}
                                        setAmount={setExcludeSpentAmount}
                                        theme={theme}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={excludeUsersSpentPeriod}
                                            disabled={!excludeUsersSpent}
                                            onChange={(e, checked) => {
                                                setExcludeUsersSpentPeriod(checked);
                                            }}
                                        />
                                    }
                                    label="In..."
                                />
                            </Grid>
                            {excludeUsersSpentPeriod && excludeUsersSpent && (
                                <Grid item xs={12} sx={{ marginLeft: 4 }}>
                                    <MessageUsersFormExcludePeriod
                                        period={excludeSepntPeriod}
                                        setPeriod={setExcludeSpentPeriod}
                                        periodAmount={excludeSpentPeriodAmount}
                                        setPeriodAmount={setExcludeSpentPeriodAmount}
                                        suffixMessage={'since following you'}
                                        theme={theme}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={targetUsers === 'onlineFollowing' ? excludeFans : false}
                                            disabled={targetUsers !== 'onlineFollowing'}
                                            onChange={(e, checked) => {
                                                setExcludeFans(checked);
                                            }}
                                        />
                                    }
                                    label="Exclude current fans (only available when sending to users you follow)"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={excludeUsersMessagedIn}
                                            onChange={(e, checked) => {
                                                setExcludeUsersMessagedIn(checked);
                                            }}
                                        />
                                    }
                                    label={
                                        <span>
                                            Users <strong>this service</strong> messaged in the last...
                                        </span>
                                    }
                                />
                            </Grid>
                            {excludeUsersMessagedIn && (
                                <Grid item xs={12} sx={{ marginLeft: 4 }}>
                                    <MessageUsersFormExcludePeriod
                                        period={excludeUsersMessagedInPeriod}
                                        setPeriod={setExcludeUsersMessagedInPeriod}
                                        periodAmount={excludeUsersMessagedInPeriodAmount}
                                        setPeriodAmount={setExcludeUsersMessagedInPeriodAmount}
                                        theme={theme}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageUsersFormExclude;
