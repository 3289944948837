import moment from 'moment';
import { OnlyFansMassDMMessageScheduleDocumentWithColor } from '../hooks/useMessageUsersMassDmList';

interface MassDmOccurrence {
    repost: OnlyFansMassDMMessageScheduleDocumentWithColor;
    scheduledTime: moment.Moment;
}

export function calculateUpcomingMassDmsSchedule(
    massDmsSchedule: OnlyFansMassDMMessageScheduleDocumentWithColor[],
    fromTime: moment.Moment = moment(),
): MassDmOccurrence[] {
    const upcomingReposts: MassDmOccurrence[] = [];
    const endTime = moment(fromTime).add(24, 'hours');

    massDmsSchedule.forEach(massDmSchedule => {
        if (!massDmSchedule.active) return;

        // Start with the initial scheduled time
        let nextRepostTime = moment(massDmSchedule.scheduleDate);

        // If the first occurrence is in the future but beyond our 24h window, skip this message
        if (nextRepostTime.isAfter(endTime)) return;

        // If the first occurrence is in the past, find the next occurrence that's after fromTime
        if (nextRepostTime.isBefore(fromTime) && massDmSchedule.repeatEveryAmount && massDmSchedule.repeatEveryUnit) {
            // Calculate how many repetitions have occurred since the initial schedule date
            const timeDiff = moment.duration(fromTime.diff(nextRepostTime));
            const unitDiff = timeDiff.as(massDmSchedule.repeatEveryUnit as moment.unitOfTime.Base);
            const repetitions = Math.floor(unitDiff / massDmSchedule.repeatEveryAmount);

            // Advance to the next occurrence after fromTime
            nextRepostTime = moment(massDmSchedule.scheduleDate).add(
                (repetitions + 1) * massDmSchedule.repeatEveryAmount,
                massDmSchedule.repeatEveryUnit,
            );

            // If even the next occurrence is beyond our window, skip this message
            if (nextRepostTime.isAfter(endTime)) return;
        }

        // Add the first occurrence that falls within our window
        upcomingReposts.push({
            repost: massDmSchedule,
            scheduledTime: moment(nextRepostTime),
        });

        // If there's no repeat configuration, we're done with this message
        if (!massDmSchedule.repeatEveryAmount || !massDmSchedule.repeatEveryUnit) {
            return;
        }

        // Add subsequent occurrences
        let currentRepostTime = moment(nextRepostTime);

        while (true) {
            // Calculate the next occurrence time
            currentRepostTime = moment(currentRepostTime).add(massDmSchedule.repeatEveryAmount, massDmSchedule.repeatEveryUnit);

            // If we've passed the 24-hour window, we're done with this message
            if (currentRepostTime.isAfter(endTime)) break;

            // If there's a repeatEndDate and we've passed it, we're done with this message
            if (massDmSchedule.repeatEndDate && currentRepostTime.isAfter(moment(massDmSchedule.repeatEndDate))) break;

            // Add this occurrence
            upcomingReposts.push({
                repost: massDmSchedule,
                scheduledTime: moment(currentRepostTime),
            });
        }
    });

    // Sort by scheduled time
    return upcomingReposts.sort((a, b) => a.scheduledTime.valueOf() - b.scheduledTime.valueOf());
}

// Helper function to get just the count if that's all you need
export function getUpcomingMassDmsScheduleCount(
    reposts: OnlyFansMassDMMessageScheduleDocumentWithColor[],
    fromTime: moment.Moment = moment(),
): number {
    return calculateUpcomingMassDmsSchedule(reposts, fromTime).length;
}
