import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Stack, Theme, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import StyledCardTitleBar from './StyledCardTitleBar';

/**
 * TextInputDialog - A reusable Material UI dialog component for text input
 *
 * @param {Object} props
 * @param {boolean} props.open - Controls whether the dialog is open
 * @param {function} props.onClose - Callback when dialog is closed without submission
 * @param {function} props.onSubmit - Callback when form is submitted with text value
 * @param {string} props.title - Dialog title text
 * @param {string} props.contentText - Prompt or description text
 * @param {string} props.label - Input field label
 * @param {string} props.placeholder - Input field placeholder
 * @param {string} props.initialValue - Initial value for the text field
 * @param {string} props.cancelButtonText - Text for cancel button
 * @param {string} props.submitButtonText - Text for submit button
 */
const TextInputDialog = ({
    open,
    onClose,
    onSubmit,
    title = 'Enter Information',
    contentText = 'Please enter the requested information below.',
    label = 'Input',
    placeholder = '',
    initialValue = '',
    clearButton = false,
    cancelButtonText = 'Cancel',
    submitButtonText = 'Submit',
    clearButtonText = 'Clear',
}: {
    open: boolean;
    onClose: () => void;
    onSubmit: (value: string) => void;
    title?: string;
    contentText?: string;
    label?: string;
    placeholder?: string;
    initialValue?: string;
    clearButton?: boolean;
    cancelButtonText?: string;
    submitButtonText?: string;
    clearButtonText?: string;
}) => {
    const theme: Theme = useTheme();
    const [value, setValue] = useState(initialValue);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(value);
        setValue(initialValue); // Reset the field
    };

    const handleClose = () => {
        setValue(initialValue); // Reset the field
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <StyledCardTitleBar title={title} theme={theme} />
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <DialogContentText gutterBottom>{contentText}</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="input-text"
                        label={label}
                        placeholder={placeholder}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        InputProps={{
                            endAdornment: clearButton && value && (
                                <IconButton
                                    color="error"
                                    onClick={() => {
                                        setValue('');
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        backgroundColor: theme.palette.grey[100],
                        borderTop: `1px solid ${theme.palette.divider}`,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        {clearButton && value && (
                            <Button
                                color="error"
                                onClick={() => {
                                    onSubmit('');
                                    setValue('');
                                }}
                            >
                                {clearButtonText}
                            </Button>
                        )}
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                        <Button color="secondary" onClick={handleClose}>
                            {cancelButtonText}
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            {submitButtonText}
                        </Button>
                    </Stack>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default TextInputDialog;
