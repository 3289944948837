import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import ContactsIcon from '@mui/icons-material/Contacts';
import ImageIcon from '@mui/icons-material/Image';
import LabelIcon from '@mui/icons-material/Label';
import RepeatIcon from '@mui/icons-material/Repeat';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import { Box, Stack, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { ScheduledTask } from '../../../../hooks/useMessageUsersScheduledSessions';
import { targetUsersName } from '../../../../utils/common';
import Bullet from '../../../common/Bullet';
import MessageUsersFormEditiorPreview from '../sendMessage/MessageUsersFormEditiorPreview';

export const MessageUsersPriorityMessageNameAndBadges = (props: {
    scheduledMessage: ScheduledTask;
    isFutureScheduledMessage: boolean;
    timezone: string;
}) => {
    const { scheduledMessage, isFutureScheduledMessage, timezone } = props;
    const theme: Theme = useTheme();

    return (
        <Stack direction="column" spacing={0.5}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Tooltip title="Send To">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ContactsIcon color="primary" sx={{ fontSize: '12pt' }} />
                    </div>
                </Tooltip>
                <Typography variant="inherit" color="textPrimary" noWrap>
                    {targetUsersName(scheduledMessage.payload.usersType, {})}
                </Typography>
            </Stack>

            <Stack direction="row" spacing={0.5} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Category Color">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LabelIcon htmlColor={scheduledMessage.color?.color || theme.palette.text.disabled} sx={{ fontSize: '12pt' }} />
                        </div>
                    </Tooltip>
                    <Typography variant="inherit" color={scheduledMessage.color ? 'textPrimary' : 'textSecondary'} noWrap>
                        {scheduledMessage.color?.name || 'No Color'}
                    </Typography>
                </Stack>

                <Bullet />

                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title={scheduledMessage.repeatEveryAmount && scheduledMessage.repeatEveryUnit ? 'Repeat' : `Don't Repeat`}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <RepeatIcon
                                color={scheduledMessage.repeatEveryAmount && scheduledMessage.repeatEveryUnit ? 'success' : 'disabled'}
                                sx={{ fontSize: '12pt' }}
                            />
                        </div>
                    </Tooltip>
                    <Typography
                        variant="inherit"
                        color={scheduledMessage.repeatEveryAmount && scheduledMessage.repeatEveryUnit ? 'textPrimary' : 'textSecondary'}
                        noWrap
                    >
                        {scheduledMessage.repeatEveryAmount && scheduledMessage.repeatEveryUnit
                            ? `${scheduledMessage.repeatEveryAmount} ${scheduledMessage.repeatEveryUnit}`
                            : 'Does not repeat'}
                    </Typography>
                </Stack>

                <Bullet />

                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Auto Unsend After">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CancelScheduleSendIcon
                                color={
                                    scheduledMessage.payload.autoUnsendTimerPeriod && scheduledMessage.payload.autoUnsendTimerPeriodAmount
                                        ? 'info'
                                        : 'disabled'
                                }
                                sx={{
                                    fontSize: '12pt',
                                }}
                            />
                        </div>
                    </Tooltip>
                    <Typography
                        variant="inherit"
                        color={
                            scheduledMessage.payload.autoUnsendTimerPeriod && scheduledMessage.payload.autoUnsendTimerPeriodAmount
                                ? 'textPrimary'
                                : 'textSecondary'
                        }
                        noWrap
                    >
                        {scheduledMessage.payload.autoUnsendTimerPeriod && scheduledMessage.payload.autoUnsendTimerPeriodAmount
                            ? `${scheduledMessage.payload.autoUnsendTimerPeriodAmount} ${scheduledMessage.payload.autoUnsendTimerPeriod}`
                            : 'Not Set'}
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction="row" spacing={0.5} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Repeat Until">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SportsScoreIcon color={scheduledMessage.endDate ? 'warning' : 'disabled'} sx={{ fontSize: '12pt' }} />
                        </div>
                    </Tooltip>

                    <Typography variant="inherit" color={scheduledMessage.endDate ? 'textPrimary' : 'textSecondary'} noWrap>
                        {scheduledMessage.endDate ? moment(scheduledMessage.endDate).tz(timezone).format('L') : 'Indefinitely'}
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction="row" spacing={0.5} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Repeat Until">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ImageIcon
                                color={
                                    scheduledMessage.payload &&
                                    scheduledMessage.payload.mediaFiles &&
                                    scheduledMessage.payload.mediaFiles.length > 0
                                        ? 'primary'
                                        : 'disabled'
                                }
                                sx={{ fontSize: '12pt' }}
                            />
                        </div>
                    </Tooltip>

                    <Typography
                        variant="inherit"
                        color={
                            scheduledMessage.payload &&
                            scheduledMessage.payload.mediaFiles &&
                            scheduledMessage.payload.mediaFiles.length > 0
                                ? 'textPrimary'
                                : 'textSecondary'
                        }
                        noWrap
                    >
                        {scheduledMessage.payload && scheduledMessage.payload.mediaFiles && scheduledMessage.payload.mediaFiles.length > 0
                            ? `${scheduledMessage.payload.mediaFiles.length.toLocaleString()} ${
                                  scheduledMessage.payload.mediaFiles.length > 1 ? 'attachment' : 'attachments'
                              }`
                            : 'No Attachments'}
                    </Typography>
                </Stack>
            </Stack>

            {scheduledMessage.payload.message && scheduledMessage.payload.message.length > 0 ? (
                <Box
                    sx={{
                        // smaller font for all children
                        '& *': {
                            fontSize: '0.9rem',
                        },
                        // animate opacity on leave
                        transition: 'opacity 0.15s',
                        opacity: 0.7,
                        ...(!isFutureScheduledMessage && {
                            '&:hover': {
                                // animate opacity on hover
                                transition: 'opacity 0.3s',
                                opacity: 1,
                            },
                        }),
                    }}
                >
                    <MessageUsersFormEditiorPreview
                        // editorState={loadDraftsJSEditorStateFromHtml(scheduledMessage.payload.message)}
                        message={scheduledMessage.payload.message}
                        lockMessage={!scheduledMessage.payload.price || scheduledMessage.payload.price === 0}
                        lockMessagePrice={scheduledMessage.payload.price || 0}
                        lockedText={scheduledMessage.payload.lockedText || false}
                        taggedCreators={
                            scheduledMessage.payload.releaseForms?.map((f: { id: number; name: string }) => {
                                return { id: f.id, name: f.name };
                            }) || []
                        }
                        taggedReleaseForms={[]}
                        theme={theme}
                        fullWidth
                    />
                </Box>
            ) : (
                <Typography variant="inherit" color="textSecondary">
                    No Message
                </Typography>
            )}
        </Stack>
    );
};

export default MessageUsersPriorityMessageNameAndBadges;
