import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, Skeleton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Stack, Theme } from '@mui/system';
import dinero from 'dinero.js';
import { useContext, useState } from 'react';
import { SettingsContext } from '../../../../store/SettingsContext';
import { d2f, titleCase, trendColor, trendIcon } from '../../../../utils/common';

const ProductPriceCard = (
    title: string,
    mainData: any,
    compareData: any,
    compare: boolean,
    isFetching: boolean,
    amountType: 'gross' | 'net',
    theme: Theme,
) => {
    const amountTypeMultiplier = amountType === 'gross' ? 1 : 0.8;

    const zeroDinero = dinero({ amount: 0, currency: 'USD' });
    const mainTotal: dinero.Dinero = mainData
        ? dinero({ amount: Math.trunc(d2f(mainData.total) * amountTypeMultiplier * 100), currency: 'USD' })
        : zeroDinero;
    const mainCount: number = mainData ? mainData.count : 0;
    const compareTotal: dinero.Dinero = compareData
        ? dinero({ amount: Math.trunc(d2f(compareData.total) * amountTypeMultiplier * 100), currency: 'USD' })
        : zeroDinero;
    const compareCount: number = compareData ? compareData.count : 0;
    const color: string = trendColor(mainTotal, compareTotal, compare, theme);
    let icon: IconDefinition = trendIcon(mainTotal, compareTotal, compare);

    return (
        <Card variant="elevation">
            <CardContent>
                <Grid container flexGrow={1} alignItems="center" spacing={2}>
                    {compare && compareData && (
                        <Grid item xs={1}>
                            <FontAwesomeIcon icon={icon} color={color} />
                        </Grid>
                    )}
                    <Grid item xs>
                        <Typography variant="subtitle1" align="right">
                            {title}
                            <small
                                style={{
                                    fontSize: '0.8rem',
                                    color: theme.palette.grey[600],
                                    // Position below the bottom left corner of the main text
                                    // position: 'relative',
                                    // left: -79,
                                    // top: 14,
                                }}
                            >
                                {' '}
                                {amountType}
                            </small>
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="h6" align="right" fontFamily={'monospace'} sx={{ ...(compare && { color }) }}>
                    {isFetching ? (
                        <Skeleton width="100%" />
                    ) : (
                        <>
                            <Typography variant="body1" display="inline" fontFamily={'monospace'} fontSize={'0.7em'}>
                                {mainCount.toLocaleString()}{' '}
                            </Typography>
                            {mainTotal.toFormat()}
                        </>
                    )}
                </Typography>
                {compare && (
                    <Typography variant="h6" align="right" fontFamily={'monospace'}>
                        {isFetching ? (
                            <Skeleton width="100%" />
                        ) : (
                            <>
                                <Typography variant="body1" display="inline" fontFamily={'monospace'} fontSize={'0.7em'}>
                                    {compareCount.toLocaleString()}{' '}
                                </Typography>
                                {compareTotal.toFormat()}
                            </>
                        )}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

type Props = {
    mainData: any[];
    compareData: any[];
    compare: boolean;
    isFetching: boolean;
    theme: Theme;
};

const ReportAccountTotalsByProduct = (props: Props) => {
    const { mainData, compareData, compare, isFetching, theme } = props;
    const settingsContext = useContext(SettingsContext);

    const [amountType, setAmountType] = useState<'gross' | 'net'>(
        localStorage.getItem('showEarningsAsGross') === 'false' ? 'net' : 'gross',
    );

    return (
        <>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginBottom: 2 }}>
                <Typography variant="h6">Total by Product</Typography>
                <ToggleButtonGroup
                    value={amountType}
                    exclusive
                    color="secondary"
                    size={'small'}
                    onChange={(_event, newValue) => {
                        if (!newValue) {
                            return;
                        }

                        setAmountType(newValue);
                        localStorage.setItem('showEarningsAsGross', newValue === 'gross' ? 'true' : 'false');
                    }}
                    sx={{ marginTop: '4px', height: '39px' }}
                >
                    <ToggleButton value="gross" fullWidth sx={{ minWidth: '80px' }}>
                        GROSS
                    </ToggleButton>
                    <ToggleButton value="net" fullWidth sx={{ minWidth: '80px' }}>
                        NET
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>

            <Grid container flexGrow={1} justifyContent="center" spacing={1} sx={{ paddingBottom: 4 }}>
                {settingsContext &&
                    settingsContext.services.sextforce.productTypes &&
                    settingsContext.services.sextforce.productTypes.map((productType: string) => (
                        <Grid item xs key={productType}>
                            {ProductPriceCard(
                                titleCase(productType),
                                mainData.find(item => item._id === productType),
                                compareData.find(item => item._id === productType),
                                compare,
                                isFetching,
                                amountType,
                                theme,
                            )}
                        </Grid>
                    ))}
            </Grid>
        </>
    );
};

export default ReportAccountTotalsByProduct;
