import { Typography } from '@mui/material';

const MessageUsersAutoMassDmDescription = () => {
    return (
        <>
            <Typography variant="body1" gutterBottom>
                Break free from the limitations of OnlyFans’ built-in Mass DM system with Auto Mass DM. Schedule unlimited Mass DMs, set up
                repeats and unsends, and automatically add purchasers to lists—all with ease!
            </Typography>
            <Typography variant="body1" gutterBottom>
                While the 50 Mass DMs per 24-hour limit still applies, Auto Mass DM lets you plan ahead, eliminating the hassle of manually
                scheduling the same message over and over again.
            </Typography>
        </>
    );
};

export default MessageUsersAutoMassDmDescription;
