import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import PageContainer from '../../../components/common/PageContainter';
import StyledCard from '../../../components/common/StyledCard';
import StyledCardTitleBar from '../../../components/common/StyledCardTitleBar';
import AutoSendWelcomeMessageDetailsForm from '../../../components/services/messageUsers/autoSendWelcomeMessage/AutoSendWelcomeMessageDetailsForm';
import useSubscriber from '../../../hooks/useSubscriber';
import useSubscriberSettingsMessageUsersAutoWelcomeMessage from '../../../hooks/useSubscriberSettingsMessageUsersAutoWelcomeMessage';
import { SettingsContext } from '../../../store/SettingsContext';
import { OnlyFansSubscriberMessageUsersAutoMessageDetails } from '../../../types/messageUsersAutoSendWelcomeMessage';

const MessageUsersAutoSendWelcomeMessageRebillSubscriber = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const dialog = useDialog();

    const { data: subscriber, isLoading: subscriberLoading } = useSubscriber();
    const {
        data: serviceSettings,
        isLoading: serviceSettingsLoading,
        setServiceSettingsMessageUsersAutoWelcomeMessageRebillSubscriber,
        setServiceSettingsMessageUsersAutoWelcomeMessageLoading,
    } = useSubscriberSettingsMessageUsersAutoWelcomeMessage();

    // New Subscriber data
    const [autoMessageRebillSubscriber, setAutoMessageRebillSubscriber] = useState<OnlyFansSubscriberMessageUsersAutoMessageDetails | null>(
        null,
    );
    const [autoMessageRebillSubscriberInitialValues, setAutoMessageRebillSubscriberInitialValues] =
        useState<OnlyFansSubscriberMessageUsersAutoMessageDetails | null>(null);
    const [vaultAlbumNameRebillSubscriber, setVaultAlbumNameRebillSubscriber] = useState<string | undefined>();
    const [saveTemplateRebillSubscriber, setSaveTemplateRebillSubscriber] = useState<boolean>(false);
    const [saveTemplateNameRebillSubscriber, setSaveTemplateNameRebillSubscriber] = useState<string>('');
    const [autoMessageRebillSubscriberIsFormValid, setAutoMessageRebillSubscriberIsFormValid] = useState<boolean>(false);

    useEffect(() => {
        if (serviceSettings) {
            if (!autoMessageRebillSubscriber) {
                setAutoMessageRebillSubscriber(serviceSettings.autoMessageRebillSubscriber);
            }

            if (!autoMessageRebillSubscriberInitialValues) {
                setAutoMessageRebillSubscriberInitialValues(serviceSettings.autoMessageRebillSubscriber || null);
            }
        } else {
            setAutoMessageRebillSubscriber(null);
            setAutoMessageRebillSubscriberInitialValues(null);
        }
    }, [autoMessageRebillSubscriber, autoMessageRebillSubscriberInitialValues, serviceSettings]);

    const handleSaveSettings = () => {
        const doSave = async () => {
            await setServiceSettingsMessageUsersAutoWelcomeMessageRebillSubscriber(
                autoMessageRebillSubscriber,
                saveTemplateRebillSubscriber,
                saveTemplateNameRebillSubscriber,
            )
                .then(newData => {
                    enqueueSnackbar('Settings saved successfully!', { variant: 'success' });

                    setAutoMessageRebillSubscriber(newData.autoMessageRebillSubscriber);
                    setAutoMessageRebillSubscriberInitialValues(newData.autoMessageRebillSubscriber);

                    // Scroll to top
                    window.scrollTo(0, 0);
                })
                .catch(() => {
                    enqueueSnackbar('Failed to save settings!', { variant: 'error' });

                    setAutoMessageRebillSubscriberInitialValues(null);
                });
        };

        doSave();
    };

    const resetForm = () => {
        setAutoMessageRebillSubscriber(null);
    };

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}`} />{' '}
                        Auto Welcome Message for {subscriber.username}
                    </Typography>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Auto Message To Subscriber Rebill" theme={theme} />
                    </StyledCard>
                    <StyledCard>
                        <Typography gutterBottom>
                            Use the forms below to setup a message that will be sent automatically to a subscribing fan who rebills his
                            existing subscription. The fan will receive a regular message from you which will appear in their Priority
                            inbox. Because of the way OnlyFans works, this message is not sent in real-time like the other welcome messages
                            but rather send at slightly random times after the rebill. The busier your page is, the faster the system will
                            send the message.
                        </Typography>
                        <Typography gutterBottom>
                            Use this service to send a thank you message to your loyal subscribers. Take the opportunity to upsell some
                            exclusive content or send a gift to the fan, and start a conversation to sell some more. It is good to end the
                            message with a question to encourage a reply.
                        </Typography>
                    </StyledCard>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Message Settings" theme={theme} />
                    </StyledCard>

                    {/* {serviceSettingsLoading ? <Skeleton height={400} sx={{ mb: 1 }} /> : null} */}
                    {autoMessageRebillSubscriber && (
                        <AutoSendWelcomeMessageDetailsForm
                            title="Auto Message Subscriber Rebill"
                            description="This message will be sent to fan shortly after they rebill their ongoing subscription. You can use this message to thank them for their loyalty, and to send them some content for free or a PPV to unlock."
                            subscriber={subscriber}
                            initialValues={autoMessageRebillSubscriberInitialValues}
                            messageDetails={autoMessageRebillSubscriber}
                            setMessageDetails={setAutoMessageRebillSubscriber}
                            vaultAlbumName={vaultAlbumNameRebillSubscriber}
                            setVaultAlbumName={setVaultAlbumNameRebillSubscriber}
                            saveTemplate={saveTemplateRebillSubscriber}
                            setSaveTemplate={setSaveTemplateRebillSubscriber}
                            saveTemplateName={saveTemplateNameRebillSubscriber}
                            setSaveTemplateName={setSaveTemplateNameRebillSubscriber}
                            setIsFormValid={setAutoMessageRebillSubscriberIsFormValid}
                            showAddToList={true}
                        />
                    )}

                    <StyledCard noCard>
                        <Grid
                            container
                            spacing={1}
                            flexGrow={0}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ marginBottom: theme.spacing(2) }}
                        >
                            <Grid item xs={8}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    loading={
                                        setServiceSettingsMessageUsersAutoWelcomeMessageLoading ||
                                        subscriberLoading ||
                                        serviceSettingsLoading
                                    }
                                    fullWidth
                                    disabled={
                                        setServiceSettingsMessageUsersAutoWelcomeMessageLoading ||
                                        subscriberLoading ||
                                        serviceSettingsLoading ||
                                        !autoMessageRebillSubscriberIsFormValid
                                    }
                                    onClick={() => {
                                        dialog
                                            .confirm({
                                                message: `Are you sure you want to save these settings?`,
                                                title: 'Confirm',
                                                ok: { text: 'Save', variant: 'contained' },
                                                cancel: { text: 'Cancel' },
                                            })
                                            .then(async () => {
                                                handleSaveSettings();
                                            })
                                            .catch(() => {});
                                    }}
                                >
                                    Save Settings
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                    disabled={
                                        setServiceSettingsMessageUsersAutoWelcomeMessageLoading ||
                                        subscriberLoading ||
                                        serviceSettingsLoading
                                    }
                                    onClick={() => {
                                        dialog
                                            .confirm({
                                                message: `Are you sure you want to reset the whole form? Everything you've entered will be cleared.`,
                                                title: 'Confirm',
                                                ok: {
                                                    text: 'Reset',
                                                    variant: 'contained',
                                                    color: 'secondary',
                                                },
                                                cancel: { text: 'Cancel' },
                                            })
                                            .then(() => {
                                                resetForm();
                                            })
                                            .catch(() => {});
                                    }}
                                >
                                    Clear
                                </Button>
                            </Grid>
                        </Grid>
                    </StyledCard>
                </>
            )}
        </PageContainer>
    );
};

export default MessageUsersAutoSendWelcomeMessageRebillSubscriber;
