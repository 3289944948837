'muibox';
import { useDialog } from 'muibox';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { OnlyFansLists } from '../types/onlyFansTypes';
import { handleHttpError } from '../utils/common';
import { subscriberListsService } from './subscriberListsService';
import useAxios from './useAxios';
import useSubscriber from './useSubscriber';

const useSubscriberLists = (enabled: boolean) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const queryClient = useQueryClient();
    const dialog = useDialog();
    const axios = useAxios();

    // Use refs to track initialization state
    const initializedRef = useRef(false);
    const fetchStartedRef = useRef(false);

    // State for tracking loaded items
    const [lists, setLists] = useState<OnlyFansLists.List[]>([]);
    const [loadedListsCount, setLoadedListsCount] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    // Use a ref to compare previous lists count to avoid unnecessary re-renders
    const prevListsCountRef = useRef<number>(0);

    // Track already fetched pages to prevent duplicate updates
    const fetchedPagesRef = useRef<Set<number>>(new Set());

    // Create a List
    const [isCreatingList, setIsCreatingList] = useState<boolean>(false);

    // Create a List
    const createList = async (name: string): Promise<any> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber?._id}/lists`;

        setIsCreatingList(true);

        return axios
            .post(query, { name })
            .then(response => {
                // Add the new list to the cache
                queryClient.setQueryData(['subscriberLists', subscriber?._id], (oldData: any) => {
                    return [...oldData, response.data];
                });

                setIsCreatingList(false);

                return response.data;
            })
            .catch(error => {
                console.error(error);
                setIsCreatingList(false);
                handleHttpError(error, dialog);
            });
    };

    // The main function to fetch data from the service and update state
    const fetchAndUpdateState = useCallback(async () => {
        if (!subscriber?._id) {
            return null;
        }

        try {
            // Get current data from service
            const currentLists = subscriberListsService.getLists(subscriber._id);
            const currentCount = currentLists.length;
            const currentHasMore = subscriberListsService.hasMorePages(subscriber._id);

            // Only update state if the lists count has changed
            if (currentCount !== prevListsCountRef.current) {
                // console.log(`[Hook] State update for ${subscriber._id}: ${currentCount} lists, hasMore: ${currentHasMore}`);

                // Update local state with new array
                setLists(currentLists);
                setLoadedListsCount(currentCount);
                setHasMore(currentHasMore);

                // Update the ref to the new count
                prevListsCountRef.current = currentCount;
            } else {
                // console.log(`[Hook] No state update for ${subscriber._id}: ${currentCount} lists, hasMore: ${currentHasMore}`);

                setHasMore(currentHasMore);
            }

            return { lists: currentLists, count: currentCount, hasMore: currentHasMore };
        } catch (error) {
            console.error(`[Hook] Error updating state:`, error);
            return null;
        }
    }, [subscriber]);

    const query = useQuery(
        ['subscriberLists', subscriber?._id],
        async () => {
            // console.log(`[Hook] Initial query execution for ${subscriber._id}`);

            try {
                // Fetch the first page
                const firstPage = await subscriberListsService.fetchPage(axios, settingsContext.routes.onlyFans.base, subscriber._id);

                // Mark as initialized
                initializedRef.current = true;

                // Update state with first page results
                await fetchAndUpdateState();

                return firstPage;
            } catch (error) {
                console.error(`[Hook] Error in initial query:`, error);
                initializedRef.current = true;
                throw error;
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            cacheTime: Infinity,
            enabled: userContext.jwtToken && settingsContext.apiKey && subscriber && subscriber._id && enabled ? true : false,
        },
    );

    // Effect for fetching all pages
    useEffect(() => {
        // Only run this effect if we should fetch all pages,
        // we're enabled, and we haven't started fetching yet
        if (!enabled || fetchStartedRef.current || !subscriber?._id) {
            return;
        }

        // console.log(`[Hook] Starting fetchAllPages effect for ${subscriber._id}`);
        fetchStartedRef.current = true;

        // Function to fetch all pages
        const fetchAll = async () => {
            try {
                await subscriberListsService.fetchAllPages(axios, settingsContext.routes.onlyFans.base, subscriber._id);

                // Final state update after all pages are fetched
                await fetchAndUpdateState();
            } catch (error) {
                console.error(`[Hook] Error in fetchAll:`, error);
            }
        };

        // Start fetching all pages
        fetchAll();

        // No cleanup needed here as the service manages its own state
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriber?._id, enabled, fetchAndUpdateState]);

    // Effect to periodically check for updates, but using a ref to track last known state
    // to prevent unnecessary re-renders
    useEffect(() => {
        if (!enabled || !subscriber?._id) {
            return;
        }

        // console.log(`[Hook] Setting up polling for ${subscriber._id}`);

        // Update immediately once
        fetchAndUpdateState();

        // Then set up interval for updates - only update when needed
        const intervalId = setInterval(() => {
            const currentLists = subscriberListsService.getLists(subscriber._id);
            const currentCount = currentLists.length;

            // Only update state if the count has changed
            if (currentCount !== prevListsCountRef.current) {
                fetchAndUpdateState();
            }
        }, 1000); // Reduced frequency to prevent too many checks

        return () => {
            clearInterval(intervalId);
        };
    }, [subscriber?._id, enabled, fetchAndUpdateState]);

    // Effect to handle subscriber ID changes
    useEffect(() => {
        // Reset state when subscriber ID changes
        // console.log(`[Hook] Subscriber ID changed to ${subscriber?._id}`);

        // Reset our internal tracking
        prevListsCountRef.current = 0;
        fetchStartedRef.current = false;
        initializedRef.current = false;
        fetchedPagesRef.current = new Set();

        // Reset state
        setLists([]);
        setLoadedListsCount(0);
        setHasMore(true);

        // Force the query to refetch with the new ID
        if (enabled && subscriber?._id) {
            // Run the initial query
            query.refetch().then(() => {
                // Then start fetching all pages if needed
                subscriberListsService.fetchAllPages(axios, settingsContext.routes.onlyFans.base, subscriber._id).then(() => {
                    fetchAndUpdateState();
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriber?._id, enabled, fetchAndUpdateState, query.refetch]);

    // Only log once per actual data change
    // useEffect(() => {
    //     console.log(`[Hook] Data changed: ${lists.length} lists for ${subscriber._id}`);
    // }, [lists.length, subscriber._id]);

    return {
        ...query,
        lists,
        loadedListsCount,
        hasMore,
        // Provide access to the service methods
        isLoading: query.isLoading,
        isError: query.isError,
        error: query.error,
        refetch: async () => {
            // console.log(`[Hook] Refetching lists for ${subscriber._id}`);

            // Reset our internal state
            setLists([]);
            setLoadedListsCount(0);
            setHasMore(true);
            prevListsCountRef.current = 0;
            fetchStartedRef.current = false;
            initializedRef.current = false;
            fetchedPagesRef.current = new Set();

            // Clear service data for this subscriber
            subscriberListsService.clearSubscriberData(subscriber._id);

            // Re-run the query
            const result = await query.refetch();

            // Kick off the fetch all process again if needed
            if (enabled) {
                subscriberListsService.fetchAllPages(axios, settingsContext.routes.onlyFans.base, subscriber._id).then(() => {
                    fetchAndUpdateState();
                });
            }

            return result;
        },
        createList,
        isCreatingList,
    };
};

export default useSubscriberLists;
