import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import ContactsIcon from '@mui/icons-material/Contacts';
import ImageIcon from '@mui/icons-material/Image';
import LabelIcon from '@mui/icons-material/Label';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import RepeatIcon from '@mui/icons-material/Repeat';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import { Box, Skeleton, Stack, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { OnlyFansMassDMMessageScheduleDocumentWithColor } from '../../../../hooks/useMessageUsersMassDmList';
import useSubscriberLists from '../../../../hooks/useSubscriberLists';
import { parseNumberOrString } from '../../../../utils/common';
import Bullet from '../../../common/Bullet';
import NA from '../../../common/NA';
import MessageUsersFormEditiorPreview from '../sendMessage/MessageUsersFormEditiorPreview';

export const MassageUsersAutoMassDmCalendarItemNameAndBadges = (props: {
    scheduledAutoMassDmMessage: OnlyFansMassDMMessageScheduleDocumentWithColor;
    isFutureScheduledMessage: boolean;
    timezone: string;
}) => {
    const { scheduledAutoMassDmMessage, isFutureScheduledMessage, timezone } = props;
    const theme: Theme = useTheme();

    const {
        lists,
        // data: listsPages,
        isLoading: isLoadingLists,
        // hasNextPage,
        // isFetchingNextPage,
        // fetchNextPage,
    } = useSubscriberLists(
        scheduledAutoMassDmMessage && scheduledAutoMassDmMessage.userLists && scheduledAutoMassDmMessage.userLists.length > 0
            ? true
            : false,
    );

    // Fetch next page of lists when needed
    // useEffect(() => {
    //     if (hasNextPage && !isFetchingNextPage) {
    //         fetchNextPage();
    //     }
    // }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    // const lists = useMemo(() => {
    //     if (!listsPages?.pages) return [];

    //     const allLists = listsPages.pages.flatMap(page => page.list);

    //     return allLists;
    // }, [listsPages]);

    return (
        <Stack direction="column" spacing={0.5}>
            <Typography variant="inherit" color="textPrimary" component={'div'}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography variant="inherit" noWrap>
                        {scheduledAutoMassDmMessage.name || 'Untitled'}
                    </Typography>
                </Stack>
            </Typography>
            <Typography variant="inherit" color="textSecondary" component={'div'}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Tooltip title="Send To">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ContactsIcon color="primary" sx={{ fontSize: '12pt' }} />
                        </div>
                    </Tooltip>
                    {isLoadingLists && <Skeleton width={100} variant="text" />}
                    <Stack direction="row" spacing={0.5} alignItems={'center'}>
                        {lists && !isLoadingLists && scheduledAutoMassDmMessage && scheduledAutoMassDmMessage.userLists ? (
                            scheduledAutoMassDmMessage.userLists.map((u, index) => (
                                <React.Fragment key={index}>
                                    <Typography variant="inherit">
                                        {lists.find(l => parseNumberOrString(u) === l.id)?.name || 'Unknown List'}
                                    </Typography>
                                    {index < scheduledAutoMassDmMessage.userLists.length - 1 && <Bullet />}
                                </React.Fragment>
                            ))
                        ) : (
                            <NA />
                        )}
                    </Stack>
                </Stack>
            </Typography>
            <Typography variant="inherit" color="textSecondary" component={'div'}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Tooltip title="Exclude">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <NoAccountsIcon color="error" sx={{ fontSize: '12pt' }} />
                        </div>
                    </Tooltip>
                    {isLoadingLists && <Skeleton width={100} variant="text" />}
                    <Stack direction="row" spacing={0.5} alignItems={'center'}>
                        {lists && !isLoadingLists && scheduledAutoMassDmMessage && scheduledAutoMassDmMessage.excludedLists ? (
                            scheduledAutoMassDmMessage.excludedLists.map((u, index) => (
                                <React.Fragment key={index}>
                                    <Typography variant="inherit">
                                        {lists.find(l => parseNumberOrString(u) === l.id)?.name || 'Unknown List'}
                                    </Typography>
                                    {index < scheduledAutoMassDmMessage.userLists.length - 1 && <Bullet />}
                                </React.Fragment>
                            ))
                        ) : (
                            <NA />
                        )}
                    </Stack>
                </Stack>
            </Typography>

            <Stack direction="row" spacing={0.5} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Category Color">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LabelIcon
                                htmlColor={scheduledAutoMassDmMessage.color?.color || theme.palette.text.disabled}
                                sx={{ fontSize: '12pt' }}
                            />
                        </div>
                    </Tooltip>
                    <Typography variant="inherit" color={scheduledAutoMassDmMessage.color ? 'textPrimary' : 'textSecondary'} noWrap>
                        {scheduledAutoMassDmMessage.color?.name || 'No Color'}
                    </Typography>
                </Stack>

                <Bullet />

                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="PPV Price">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AttachMoneyIcon
                                htmlColor={scheduledAutoMassDmMessage.price ? theme.palette.success.main : theme.palette.text.disabled}
                                sx={{ fontSize: '12pt' }}
                            />
                        </div>
                    </Tooltip>
                    <Typography variant="inherit" color={scheduledAutoMassDmMessage.price ? 'textPrimary' : 'textSecondary'} noWrap>
                        {scheduledAutoMassDmMessage.price
                            ? `$${scheduledAutoMassDmMessage.price.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                              })}`
                            : 'FREE'}
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction="row" spacing={0.5} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip
                        title={
                            scheduledAutoMassDmMessage.repeatEveryAmount && scheduledAutoMassDmMessage.repeatEveryUnit
                                ? 'Repeat'
                                : `Don't Repeat`
                        }
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <RepeatIcon
                                color={
                                    scheduledAutoMassDmMessage.repeatEveryAmount && scheduledAutoMassDmMessage.repeatEveryUnit
                                        ? 'success'
                                        : 'disabled'
                                }
                                sx={{ fontSize: '12pt' }}
                            />
                        </div>
                    </Tooltip>
                    <Typography
                        component={'div'}
                        variant="inherit"
                        color={
                            scheduledAutoMassDmMessage.repeatEveryAmount && scheduledAutoMassDmMessage.repeatEveryUnit
                                ? 'textPrimary'
                                : 'textSecondary'
                        }
                        noWrap
                    >
                        {scheduledAutoMassDmMessage.repeatEveryAmount && scheduledAutoMassDmMessage.repeatEveryUnit
                            ? `${scheduledAutoMassDmMessage.repeatEveryAmount} ${scheduledAutoMassDmMessage.repeatEveryUnit}`
                            : 'Does not repeat'}
                    </Typography>
                </Stack>

                <Bullet />

                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Repeat Until">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SportsScoreIcon
                                color={scheduledAutoMassDmMessage.repeatEndDate ? 'warning' : 'disabled'}
                                sx={{ fontSize: '12pt' }}
                            />
                        </div>
                    </Tooltip>

                    <Typography variant="inherit" color={scheduledAutoMassDmMessage.repeatEndDate ? 'textPrimary' : 'textSecondary'} noWrap>
                        {scheduledAutoMassDmMessage.repeatEveryAmount && scheduledAutoMassDmMessage.repeatEveryUnit
                            ? `${
                                  scheduledAutoMassDmMessage.repeatEndDate
                                      ? moment(scheduledAutoMassDmMessage.repeatEndDate).tz(timezone).format('L')
                                      : 'Indefinitely'
                              }`
                            : 'No Repeat'}
                    </Typography>
                </Stack>

                <Bullet />

                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Auto Unsend After">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CancelScheduleSendIcon
                                color={
                                    (scheduledAutoMassDmMessage.autoUnsendTimerPeriod &&
                                        scheduledAutoMassDmMessage.autoUnsendTimerPeriodAmount) ||
                                    scheduledAutoMassDmMessage.autoUnsendViews ||
                                    scheduledAutoMassDmMessage.autoUnsendBeforeRepeat
                                        ? 'info'
                                        : 'disabled'
                                }
                                sx={{
                                    fontSize: '12pt',
                                }}
                            />
                        </div>
                    </Tooltip>
                    <Typography
                        component={'div'}
                        variant="inherit"
                        color={
                            scheduledAutoMassDmMessage.autoUnsendTimerPeriod && scheduledAutoMassDmMessage.autoUnsendTimerPeriodAmount
                                ? 'textPrimary'
                                : 'textSecondary'
                        }
                        noWrap
                    >
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            {scheduledAutoMassDmMessage.autoUnsendTimerPeriod && scheduledAutoMassDmMessage.autoUnsendTimerPeriodAmount && (
                                <div>
                                    {scheduledAutoMassDmMessage.autoUnsendTimerPeriodAmount} $
                                    {scheduledAutoMassDmMessage.autoUnsendTimerPeriod}
                                </div>
                            )}
                            {scheduledAutoMassDmMessage.autoUnsendViews && (
                                <div>After ${scheduledAutoMassDmMessage.autoUnsendViews} views</div>
                            )}
                            {scheduledAutoMassDmMessage.autoUnsendBeforeRepeat && <div>Before repeat</div>}
                        </Stack>
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction="row" spacing={0.5} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Repeat Until">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ImageIcon
                                color={
                                    scheduledAutoMassDmMessage.medias && scheduledAutoMassDmMessage.medias.length > 0
                                        ? 'primary'
                                        : 'disabled'
                                }
                                sx={{ fontSize: '12pt' }}
                            />
                        </div>
                    </Tooltip>

                    <Typography
                        variant="inherit"
                        color={
                            scheduledAutoMassDmMessage.medias && scheduledAutoMassDmMessage.medias.length > 0
                                ? 'textPrimary'
                                : 'textSecondary'
                        }
                        noWrap
                    >
                        {scheduledAutoMassDmMessage.medias && scheduledAutoMassDmMessage.medias.length > 0
                            ? `${scheduledAutoMassDmMessage.medias.length.toLocaleString()} ${
                                  scheduledAutoMassDmMessage.medias.length > 1 ? 'attachment' : 'attachments'
                              }`
                            : 'No Attachments'}
                    </Typography>
                </Stack>
            </Stack>

            {scheduledAutoMassDmMessage.text && scheduledAutoMassDmMessage.text.length > 0 ? (
                <Box
                    sx={{
                        // smaller font for all children
                        '& *': {
                            fontSize: '0.9rem',
                        },
                        // animate opacity on leave
                        transition: 'opacity 0.15s',
                        opacity: 0.7,
                        ...(!isFutureScheduledMessage && {
                            '&:hover': {
                                // animate opacity on hover
                                transition: 'opacity 0.3s',
                                opacity: 1,
                            },
                        }),
                    }}
                >
                    <MessageUsersFormEditiorPreview
                        // editorState={loadDraftsJSEditorStateFromHtml(scheduledAutoMassDmMessage.text)}
                        message={scheduledAutoMassDmMessage.text}
                        lockMessage={!scheduledAutoMassDmMessage.price || scheduledAutoMassDmMessage.price === 0}
                        lockMessagePrice={scheduledAutoMassDmMessage.price || 0}
                        lockedText={scheduledAutoMassDmMessage.lockedText || false}
                        taggedCreators={
                            scheduledAutoMassDmMessage.taggedReleaseForms?.map((f: { id: number; name: string }) => {
                                return { id: f.id, name: f.name };
                            }) || []
                        }
                        taggedReleaseForms={[]}
                        theme={theme}
                        fullWidth
                    />
                </Box>
            ) : (
                <Typography variant="inherit" color="textSecondary">
                    No Message
                </Typography>
            )}
        </Stack>
    );
};

export default MassageUsersAutoMassDmCalendarItemNameAndBadges;
