import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';
import useSubscriber from './useSubscriber';

export interface MessageUsersTemplate {
    _id?: string;
    subscriberId: string;
    name: string;
    message: string;
    usernameReplacement?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
}

const useMessageUsersTextTemplates = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const axios = useAxios();

    // Fetch Subscriber's Lists
    const fetchTemplates = async (): Promise<MessageUsersTemplate[]> => {
        const query: string = `${settingsContext.routes.messageUsers.base}${subscriber._id}/templates`;

        return axios
            .get(query)
            .then(response => response.data)
            .catch(() => {
                return [];
            });
    };

    const subscriberTemplates = useQuery(['messageUsersTemplates', subscriber?._id], () => fetchTemplates(), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: userContext.jwtToken && settingsContext.apiKey && subscriber ? true : false,
    });

    return { ...subscriberTemplates };
};

export default useMessageUsersTextTemplates;
