import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MouseIcon from '@mui/icons-material/Mouse';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { Avatar, Box, Divider, Grid, Stack, Theme, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import dinero from 'dinero.js';
import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import { useParams } from 'react-router-dom';
// import WarningIcon from '@mui/icons-material/Warning';
import OverviewCard from '../../../components/common/OverviewCard';
import PriceCard from '../../../components/common/PriceCard';
import { metricTypeName } from '../../../utils/common';
// import SextforceMetricsSuspiciousUsersList from '../../../components/services/sextforce/metrics/SextforceMetricsSuspiciousUsersList';
import SextforceMetricsCampaignCountersGraph from '../../../components/services/sextforce/metrics/campaigns/details/SextforceMetricsCampaignCountersGraph';
import SextforceMetricsCampaignSubscribedAtGraph from '../../../components/services/sextforce/metrics/campaigns/details/SextforceMetricsCampaignSubscribedAtGraph';
import SextforceMetricsTrialCountersGraph from '../../../components/services/sextforce/metrics/trials/details/SextforceMetricsTrialCountersGraph';
import SextforceMetricsTrialLink from '../../../components/services/sextforce/metrics/trials/details/SextforceMetricsTrialLink';
import SextforceMetricsTrialSubscribedAtGraph from '../../../components/services/sextforce/metrics/trials/details/SextforceMetricsTrialSubscribedAtGraph';
import useSextforcePulicMetric, { OnlyFansMetricsPublicData } from '../../../hooks/useSextforcePublicMetric';

const PublicMetricData = ({ metric, timezone }: { metric: OnlyFansMetricsPublicData; timezone: string }) => {
    const theme: Theme = useTheme();

    return (
        <>
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <Avatar src={metric.avatar} sx={{ width: '48px', height: '48px', mr: 1 }}>
                    {metric.username.charAt(0).toUpperCase()}
                </Avatar>
                <Stack direction="column" spacing={0} flexGrow={1}>
                    <Typography variant="h5">
                        {metricTypeName(metric.type)} Metrics for {metric.username}
                    </Typography>
                    {metric.name && <Typography variant="h6">{metric.name}</Typography>}
                </Stack>
            </Stack>

            {metric.createdAt && (
                <Typography variant="subtitle1">{`Created at: ${moment(metric.createdAt).tz(timezone).format('LL')}`}</Typography>
            )}

            {metric && metric.updatedAt && (
                <Typography
                    variant="subtitle1"
                    sx={{
                        color: moment(metric.updatedAt).diff(moment(), 'days') < 0 ? theme.palette.error.dark : theme.palette.success.dark,
                        marginBottom: 2,
                    }}
                >
                    Updated at: {moment(metric.updatedAt).format('LL hh:mm a')}
                </Typography>
            )}

            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: 2 }}>
                {metric.counters && 'countSubscribers' in metric.counters && typeof metric.counters.countSubscribers === 'number' && (
                    <Grid item xs={12} md={6} lg={3}>
                        <OverviewCard
                            title="Total Subscribed"
                            value={metric.counters.countSubscribers}
                            loading={false}
                            icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    </Grid>
                )}
                {metric.counters && 'countTransitions' in metric.counters && typeof metric.counters.countTransitions === 'number' && (
                    <Grid item xs={12} md={6} lg={3}>
                        <OverviewCard
                            title="Total Clicked"
                            value={metric.counters.countTransitions}
                            loading={false}
                            icon={<MouseIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    </Grid>
                )}

                {metric.counters && 'claimsCount' in metric.counters && typeof metric.counters.claimsCount === 'number' && (
                    <Grid item xs={12} md={6} lg={3}>
                        <OverviewCard
                            title="Total Claimed"
                            value={metric.counters.claimsCount}
                            loading={false}
                            icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    </Grid>
                )}
                {metric.counters && 'subscribeCounts' in metric.counters && typeof metric.counters.subscribeCounts === 'number' && (
                    <Grid item xs={12} md={6} lg={3}>
                        <OverviewCard
                            title="Total Subscribed"
                            value={metric.counters.subscribeCounts}
                            loading={false}
                            icon={<MouseIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    </Grid>
                )}

                {/* {metric.suspiciousUsers && (
                            <Grid item xs={12} md={6} lg={3}>
                                <OverviewCard
                                    title="Suspicious Users"
                                    value={metric.suspiciousUsers.length}
                                    loading={false}
                                    icon={<WarningIcon fontSize="large" htmlColor={theme.palette.warning.dark} />}
                                    theme={theme}
                                />
                            </Grid>
                        )} */}
                {'totalEarnings' in metric && typeof metric.totalEarnings === 'number' && (
                    <>
                        <Grid item xs={12} md={6} lg={3}>
                            <PriceCard
                                title="Total Earned (gross)"
                                value={dinero({ amount: Math.trunc(metric.totalEarnings * 100), currency: 'USD' })}
                                loading={false}
                                icon={<AttachMoneyIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                theme={theme}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <PriceCard
                                title="Total Earned (net)"
                                value={dinero({ amount: Math.trunc(metric.totalEarnings * 100 * 0.8), currency: 'USD' })}
                                loading={false}
                                icon={<AttachMoneyIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                theme={theme}
                            />
                        </Grid>
                    </>
                )}
            </Grid>

            {metric.type === 'trialLinkTrial' && metric.trialUrl && <SextforceMetricsTrialLink trialUrl={metric.trialUrl} theme={theme} />}

            {metric.type === 'promoCampaign' && metric.campaignCode && (
                <SextforceMetricsTrialLink trialUrl={`https://onlyfans.com/${metric.username}/c${metric.campaignCode}`} theme={theme} />
            )}

            {metric.type === 'promoCampaign' && metric.counters.metricsDatas && (
                <SextforceMetricsCampaignCountersGraph
                    promoCampaignCounters={metric.counters}
                    promoCampaignCountersLoading={false}
                    timezone={timezone}
                    theme={theme}
                />
            )}

            {(metric.type === 'trialLinkTrial' || metric.type === 'trialLinkPromo') && metric.counters.metricsDatas && (
                <SextforceMetricsTrialCountersGraph
                    trialCounters={metric.counters}
                    trialCountersLoading={false}
                    timezone={timezone}
                    theme={theme}
                />
            )}

            {metric.subscribersGraph && metric.type === 'promoCampaign' && (
                <SextforceMetricsCampaignSubscribedAtGraph
                    metricId={''}
                    promoCampaignSubscribedAtVsSales={metric.subscribersGraph}
                    promoCampaignSubscribedAtVsSalesLoading={false}
                    showEarnings={metric.totalEarnings ? true : false}
                    showEarningsAsGross={true}
                    showEarningsWithSubscriptions={true}
                    timezone={timezone}
                    theme={theme}
                />
            )}

            {metric.subscribersGraph && (metric.type === 'trialLinkTrial' || metric.type === 'trialLinkPromo') && (
                <SextforceMetricsTrialSubscribedAtGraph
                    metricId={''}
                    trialSubscribedAtVsSales={metric.subscribersGraph}
                    trialSubscribedAtVsSalesLoading={false}
                    showEarnings={metric.totalEarnings ? true : false}
                    showEarningsAsGross={true}
                    showEarningsWithSubscriptions={true}
                    timezone={timezone}
                    theme={theme}
                />
            )}

            {/* {metric.suspiciousUsers && (
                        <SextforceMetricsSuspiciousUsersList
                            metricId={''}
                            suspiciousUsers={metric.suspiciousUsers}
                            suspiciousUsersLoading={false}
                            theme={theme}
                            timezone={timezone}
                        />
                    )} */}
        </>
    );
};

const CardTitle = ({ title, subtitle }: { title: string; subtitle?: string }) => {
    return (
        <Stack direction="column" spacing={0}>
            <Typography variant="subtitle1" align="right">
                {title}
            </Typography>
            {subtitle && (
                <Typography variant="subtitle2" align="right" color="text.secondary">
                    {subtitle}
                </Typography>
            )}
        </Stack>
    );
};

const SextforceMetricsPublic = () => {
    const params = useParams();
    const theme: Theme = useTheme();

    const timezone: string = moment.tz.guess();

    const { data: publicMetric, isLoading: metricLoading } = useSextforcePulicMetric(timezone, params.metricShareLinkId);

    const totalSubscribers = () => {
        if (!publicMetric) {
            return 0;
        }

        if (!Array.isArray(publicMetric.results)) {
            return publicMetric.results.counters.countSubscribers || 0;
        }

        let total = 0;

        publicMetric.results.forEach(m => {
            if (m.counters && 'countSubscribers' in m.counters && typeof m.counters.countSubscribers === 'number') {
                total += m.counters.countSubscribers;
            }
        });

        return total;
    };

    const totalClicks = () => {
        if (!publicMetric) {
            return 0;
        }

        if (!Array.isArray(publicMetric.results)) {
            return publicMetric.results.counters.countTransitions || 0;
        }

        let total = 0;

        publicMetric.results.forEach(m => {
            if (m.counters && 'countTransitions' in m.counters && typeof m.counters.countTransitions === 'number') {
                total += m.counters.countTransitions;
            }
        });

        return total;
    };

    const totalClaims = () => {
        if (!publicMetric) {
            return 0;
        }

        if (!Array.isArray(publicMetric.results)) {
            return publicMetric.results.counters.claimsCount || 0;
        }

        let total = 0;

        publicMetric.results.forEach(m => {
            if (m.counters && 'claimsCount' in m.counters && typeof m.counters.claimsCount === 'number') {
                total += m.counters.claimsCount;
            }
        });

        return total;
    };

    const totalSubscribe = () => {
        if (!publicMetric) {
            return 0;
        }

        if (!Array.isArray(publicMetric.results)) {
            return publicMetric.results.counters.subscribeCounts || 0;
        }

        let total = 0;

        publicMetric.results.forEach(m => {
            if (m.counters && 'subscribeCounts' in m.counters && typeof m.counters.subscribeCounts === 'number') {
                total += m.counters.subscribeCounts;
            }
        });

        return total;
    };

    // Set global MomentJS locale
    moment.locale('en-gb');

    return (
        <Box
            sx={{
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 2,
                paddingRight: 2,
            }}
        >
            {metricLoading && (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h5">Loading...</Typography>
                </Box>
            )}
            {!metricLoading && publicMetric && publicMetric.type === 'metric' && !Array.isArray(publicMetric.results) && (
                <PublicMetricData metric={publicMetric.results} timezone={timezone} />
            )}
            {!metricLoading && publicMetric && publicMetric.type === 'metricsGroup' && Array.isArray(publicMetric.results) && (
                <>
                    <Typography variant="h5">Metrics Group ({publicMetric.results.length.toLocaleString()})</Typography>
                    {publicMetric.name && <Typography variant="h6">{publicMetric.name}</Typography>}
                    {publicMetric.createdAt && (
                        <Typography variant="subtitle1">{`Created at: ${moment(publicMetric.createdAt)
                            .tz(timezone)
                            .format('LL')}`}</Typography>
                    )}

                    <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                        Summary
                    </Typography>

                    <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                        <Grid item xs={12} md={6} lg={3}>
                            <OverviewCard
                                title={<CardTitle title="Total Subscribed" subtitle="Campaigns" />}
                                value={totalSubscribers()}
                                loading={false}
                                icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                theme={theme}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <OverviewCard
                                title={<CardTitle title="Total Clicked" subtitle="Campaigns" />}
                                value={totalClicks()}
                                loading={false}
                                icon={<MouseIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                theme={theme}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <OverviewCard
                                title={<CardTitle title="Total Claimed" subtitle="Trials" />}
                                value={totalClaims()}
                                loading={false}
                                icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                theme={theme}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <OverviewCard
                                title={<CardTitle title="Total Subscribed" subtitle="Trials" />}
                                value={totalSubscribe()}
                                loading={false}
                                icon={<MouseIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                theme={theme}
                            />
                        </Grid>
                    </Grid>

                    <Divider sx={{ mb: 2 }} />

                    {publicMetric.results.map((metric, index) => (
                        <>
                            <PublicMetricData key={index} metric={metric} timezone={timezone} />

                            {Array.isArray(publicMetric.results) && index < publicMetric.results.length - 1 && <Divider sx={{ mb: 2 }} />}
                        </>
                    ))}
                </>
            )}
        </Box>
    );
};

export default SextforceMetricsPublic;
