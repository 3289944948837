import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { alpha, Theme } from '@mui/system';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

interface Props {
    sortedTaggedFriends: any[];
    taggedCreators: { id: number; name: string }[];
    handleListItemClicked: (id: number) => void;
    theme: Theme;
    disabled?: boolean;
}

const MessageUsersTagCreatorsList = (props: Props) => {
    const { sortedTaggedFriends, taggedCreators, handleListItemClicked, theme, disabled } = props;

    const Row = ({ index, style }: ListChildComponentProps) => {
        const item = sortedTaggedFriends[index];

        return (
            <ListItem
                style={style}
                disablePadding
                key={item.id}
                sx={{
                    backgroundColor:
                        taggedCreators && taggedCreators.find(taggedCreator => taggedCreator.id === item.id)
                            ? alpha(theme.palette.info.main, 0.2)
                            : '',
                }}
                disabled={disabled}
            >
                <ListItemButton
                    role={undefined}
                    dense
                    disableRipple={disabled}
                    onClick={() => {
                        if (!disabled) {
                            handleListItemClicked(item.id);
                        }
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            tabIndex={-1}
                            disableRipple
                            checked={taggedCreators && taggedCreators.find(taggedCreator => taggedCreator.id === item.id) ? true : false}
                            disabled={disabled}
                        />
                    </ListItemIcon>
                    <ListItemText primary={item.name} secondary={`@${item.user.username}`} />
                </ListItemButton>
            </ListItem>
        );
    };

    return (
        <Paper variant="outlined" sx={{ height: 200, overflow: 'auto', width: '100%', backgroundColor: 'transparent' }}>
            <FixedSizeList height={200} width="100%" itemSize={57} itemCount={sortedTaggedFriends.length} itemData={sortedTaggedFriends}>
                {Row}
            </FixedSizeList>
        </Paper>
    );
};

export default MessageUsersTagCreatorsList;
