import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Checkbox, FormControlLabel, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { MessageUsersTemplate } from '../../../../hooks/useMessageUsersTextTemplates';
import { OnlyFansSubscriber } from '../../../../hooks/useSubscriber';
import MessageUsersFormEditorTemplateLoadDialog from './MessageUsersFormEditorTemplateLoadDialog';

type Props = {
    subscriber: OnlyFansSubscriber;
    saveTemplate: boolean;
    setSaveTemplate: (saveTemplate: boolean) => void;
    saveTemplateName: string;
    setSaveTemplateName: (templateName: string) => void;
    handleLoadTemplate: (template: MessageUsersTemplate) => void;
    disabled?: boolean;
};

const MessageUsersFormEditorTemplateSave = (props: Props) => {
    const {
        subscriber,
        saveTemplate,
        setSaveTemplate,
        saveTemplateName: templateName,
        setSaveTemplateName: setTemplateName,
        handleLoadTemplate,
        disabled,
    } = props;

    const [loadTemplateDialogOpen, setLoadTemplateDialogOpen] = useState<boolean>(false);

    return (
        <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs="auto">
                <FormControlLabel
                    control={<Checkbox size="medium" checked={saveTemplate} onChange={() => setSaveTemplate(!saveTemplate)} />}
                    label="Save as Template"
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs>
                <TextField
                    label="Template Name"
                    value={templateName}
                    onChange={e => setTemplateName(e.currentTarget.value)}
                    disabled={!saveTemplate || disabled}
                    fullWidth
                    error={saveTemplate && templateName.trim().length === 0}
                />
            </Grid>
            <Grid item xs="auto">
                <Tooltip title="Load from template">
                    <span>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setLoadTemplateDialogOpen(true);
                            }}
                            disabled={disabled}
                        >
                            <FolderOpenIcon fontSize="large" />
                        </IconButton>
                    </span>
                </Tooltip>
                <MessageUsersFormEditorTemplateLoadDialog
                    subscriber={subscriber}
                    handleLoadTemplate={handleLoadTemplate}
                    loadTemplateDialogOpen={loadTemplateDialogOpen}
                    setLoadTemplateDialogOpen={setLoadTemplateDialogOpen}
                />
            </Grid>
        </Grid>
    );
};

export default MessageUsersFormEditorTemplateSave;
