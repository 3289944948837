import { Alert, Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { MessageUsersTemplate } from '../../hooks/useMessageUsersTextTemplates';
import { OnlyFansSubscriber } from '../../hooks/useSubscriber';
import { isSextforceActive, loadDraftsJSEditorStateFromHtml } from '../../utils/common';
import MessageUsersFormEditiorPreview from '../services/messageUsers/sendMessage/MessageUsersFormEditiorPreview';
import MessageUsersFormEditorTemplateSave from '../services/messageUsers/sendMessage/MessageUsersFormEditorTemplateSave';

const UsernameButton = (props: any) => {
    const { editorState, onChange } = props;

    const addStar = (): void => {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            '%USERNAME%',
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };

    return (
        <Button variant="outlined" color="primary" onClick={addStar} sx={{ backgroundColor: 'white', ml: '3px' }}>
            Add %USERNAME%
        </Button>
    );
};

const toolbar: EditorProps['toolbar'] = {
    options: ['inline', 'fontSize', 'colorPicker'],
    inline: {
        options: ['bold', 'italic'],
        bold: { className: 'toolbar-button' },
        italic: { className: 'toolbar-button' },
    },
    fontSize: {
        options: ['12', '14', '16', '20', '24'],
        className: 'toolbar-button',
    },
    colorPicker: {
        // icon: 'T',
        className: 'toolbar-button',
        // component: undefined,
        colors: ['rgb(0,0,0)', '#00aff0', '#1b98e0', '#8a96a3'],
    },
};

type Props = {
    subscriber: OnlyFansSubscriber;
    message: string;
    setMessage: (message: string) => void;
    editorState: EditorState;
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
    messageHasRestrictedWords: boolean;
    messageFoundRestrictedWords: string[];
    checkForRestrictedWords: (text: string) => void;
    taggedCreators: { id: number; name: string }[];
    taggedReleaseForms: { id: number; name: string }[];
    lockMessage: boolean;
    lockMessagePrice: number;
    lockedText: boolean;
    saveTemplate: boolean;
    setSaveTemplate: (saveTemplate: boolean) => void;
    saveTemplateName: string;
    setSaveTemplateName: (templateName: string) => void;
    showUsernamePlaceholder: boolean;
    disabled?: boolean;
};

const OnlyFansMessageEditorWithPreview = (props: Props) => {
    const {
        subscriber,
        message,
        setMessage,
        editorState,
        setEditorState,
        messageHasRestrictedWords,
        messageFoundRestrictedWords,
        checkForRestrictedWords,
        taggedCreators,
        taggedReleaseForms,
        lockMessage,
        lockMessagePrice,
        lockedText,
        saveTemplate,
        setSaveTemplate,
        saveTemplateName,
        setSaveTemplateName,
        showUsernamePlaceholder,
        disabled,
    } = props;
    const theme: Theme = useTheme();

    // const [lastRawTextChecked, setLastRawTextChecked] = useState<string>(message);

    const debouncedSetMessage = useCallback(
        debounce((newState: EditorState) => {
            setMessage(customConvertToHTML(newState.getCurrentContent()));

            const rawText = newState.getCurrentContent().getPlainText();

            checkForRestrictedWords(rawText);
            // setLastRawTextChecked(rawText);
        }, 300), // 300ms debounce delay
        [setMessage, checkForRestrictedWords],
    );

    const customConvertToHTML = (contentState: ContentState) => {
        const rawContentState = convertToRaw(contentState);
        let html = draftToHtml(rawContentState, undefined, undefined, customEntityTransform);

        // Replace color spans
        html = html.replace(/<span style="color: #00aff0;"/g, '<span class="m-editor-fc__blue-1"');
        html = html.replace(/<span style="color: #1b98e0;"/g, '<span class="m-editor-fc__blue-2"');
        html = html.replace(/<span style="color: #8a96a3;"/g, '<span class="m-editor-fc__gray"');
        html = html.replace(/<span style="color: rgb\(0,0,0\);"/g, '<span class="m-editor-fc__default"');

        // Replace font size spans
        html = html.replace(/<span style="font-size: 24px;"/g, '<span class="m-editor-fc__default m-editor-fs__lg"');
        html = html.replace(/<span style="font-size: 20px;"/g, '<span class="m-editor-fc__default m-editor-fs__l"');
        html = html.replace(/<span style="font-size: 16px;"/g, '<span class="m-editor-fc__default m-editor-fs__r"');
        html = html.replace(/<span style="font-size: 14px;"/g, '<span class="m-editor-fc__default m-editor-fs__s"');
        html = html.replace(/<span style="font-size: 12px;"/g, '<span class="m-editor-fc__default m-editor-fs__sm"');

        return html;
    };

    const customEntityTransform = (entity: any, text: string) => {
        if (entity.type === 'LINK') {
            return `<a href="${entity.data.url}">${text}</a>`;
        }
        return text;
    };

    const handleLoadTemplate = (template: MessageUsersTemplate) => {
        const newState = loadDraftsJSEditorStateFromHtml(template.message);

        setEditorState(newState);
        debouncedSetMessage(newState);
    };

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
            <Grid item xs={12}>
                <Editor
                    editorState={editorState}
                    toolbarStyle={{
                        borderRadius: '4px',
                        borderColor: '#0000003b',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderBottom: 'none',
                        padding: '4px 8px',
                        marginBottom: 0,
                        backgroundColor: theme.palette.grey[100],
                    }}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={toolbar}
                    toolbarCustomButtons={showUsernamePlaceholder ? [<UsernameButton />] : undefined}
                    editorStyle={{
                        borderRadius: '4px',
                        borderColor: '#0000003b',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                        // paddingTop: theme.spacing(1),
                        minHeight: 200,
                    }}
                    stripPastedStyles={true}
                    handlePastedText={() => false}
                    onEditorStateChange={(state: EditorState) => {
                        setEditorState(state);

                        // State to raw text
                        // const rawText = state.getCurrentContent().getPlainText();

                        // log out the html
                        // const htmlContent = customConvertToHTML(state.getCurrentContent());
                        // console.log(htmlContent);

                        // debouncedSetMessage(rawText);
                        debouncedSetMessage(state);
                    }}
                    readOnly={disabled}
                />
            </Grid>
            {messageHasRestrictedWords && (
                <Grid item xs={12}>
                    <Alert
                        variant="filled"
                        severity={isSextforceActive(subscriber) && subscriber.sextforce?.bigBrother?.active ? 'error' : 'warning'}
                    >
                        Message contains words that are restricted by OnlyFans!
                        {messageFoundRestrictedWords.length > 0 && (
                            <>
                                <br />
                                {messageFoundRestrictedWords.join(', ')}
                            </>
                        )}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <MessageUsersFormEditorTemplateSave
                    subscriber={subscriber}
                    saveTemplate={saveTemplate}
                    setSaveTemplate={setSaveTemplate}
                    saveTemplateName={saveTemplateName}
                    setSaveTemplateName={setSaveTemplateName}
                    handleLoadTemplate={handleLoadTemplate}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="inherit" sx={{ marginTop: 1 }}>
                    Preview
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <MessageUsersFormEditiorPreview
                    message={message}
                    taggedCreators={taggedCreators}
                    taggedReleaseForms={taggedReleaseForms}
                    lockMessage={lockMessage}
                    lockMessagePrice={lockMessagePrice}
                    lockedText={lockedText}
                    theme={theme}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default OnlyFansMessageEditorWithPreview;
