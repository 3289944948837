import ContactsIcon from '@mui/icons-material/Contacts';
import DeleteIcon from '@mui/icons-material/Delete';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { Box, IconButton, Skeleton, Stack, TableCell, TableRow, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { ContentState, EditorState } from 'draft-js';
import moment from 'moment';
import { useDialog } from 'muibox';
import { enqueueSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { OnlyFansMessaMessagesQueueDetails, SextforceAutoMassDmMessage } from '../../../../hooks/useMessageUsersMassDmList';
import useSubscriberLists from '../../../../hooks/useSubscriberLists';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import {
    handleHttpError,
    loadDraftsJSEditorStateFromHtml,
    parseNumberOrString,
    stripHtmlAndConvertParagraphs,
} from '../../../../utils/common';
import Bullet from '../../../common/Bullet';
import NA from '../../../common/NA';
import MessageUsersFormEditiorPreview from '../sendMessage/MessageUsersFormEditiorPreview';

type Props = {
    subscriber: any;
    massDm: SextforceAutoMassDmMessage;
    refetchMassDms: () => void;
};

const MessageUsersAutoMassDmListTableRow = (props: Props) => {
    const { subscriber, massDm, refetchMassDms } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();
    const [isDeleting, setIsDeleting] = useState(false);

    const messageDetails: OnlyFansMessaMessagesQueueDetails.Response | undefined | null = massDm.messageDetails;

    const {
        lists,
        // data: listsPages,
        isLoading: isLoadingLists,
        // hasNextPage,
        // isFetchingNextPage,
        // fetchNextPage,
    } = useSubscriberLists(messageDetails && messageDetails.userLists && messageDetails.userLists.length > 0 ? true : false);

    // Fetch next page of lists when needed
    // useEffect(() => {
    //     if (hasNextPage && !isFetchingNextPage) {
    //         fetchNextPage();
    //     }
    // }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    // const lists = useMemo(() => {
    //     if (!listsPages?.pages) return [];

    //     const allLists = listsPages.pages.flatMap(page => page.list);

    //     return allLists;
    // }, [listsPages]);

    const editorState: EditorState =
        messageDetails && messageDetails.text && messageDetails.text.length > 0
            ? loadDraftsJSEditorStateFromHtml(messageDetails.text)
            : EditorState.createWithContent(ContentState.createFromText(''));

    const unsendMassDm = async (massDmId: number) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsDeleting(true);

        const data = await axios
            .delete(`${settingsContext.routes.massDm.base}${params.userId}/list/${massDmId}`)
            .then(async response => {
                return response.data as { success: boolean };
            })
            .catch(error => {
                setIsDeleting(false);
                handleHttpError(error, dialog);

                return { success: false };
            });

        return data;
    };

    const handleUnsendMassDm = (massDmId: number) => {
        dialog
            .confirm({ message: 'Are you sure you want to unsend this Auto Mass DM?', title: 'Unsend Auto Mass DM' })
            .then(() => {
                unsendMassDm(massDmId).then(data => {
                    setIsDeleting(false);

                    if (data && data.success) {
                        enqueueSnackbar('Auto Mass DM unsent successfully', { variant: 'success' });

                        refetchMassDms();

                        return;
                    }

                    enqueueSnackbar('Failed to unsend Auto Mass DM', { variant: 'error' });
                });
            })
            .catch(() => {});
    };

    return (
        <>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    // cursor: 'pointer',
                    '& td': {
                        borderBottom: 'unset',
                    },
                }}
            >
                <TableCell sx={{ textWrap: 'nowrap' }}>{moment(massDm.date).format('L LT')}</TableCell>
                <TableCell
                    sx={{
                        // no wrap text
                        textWrap: 'nowrap',
                    }}
                >
                    {massDm.schedule?.name || massDm.name}
                </TableCell>
                <TableCell align="center" sx={{ textWrap: 'nowrap' }}>
                    {massDm.sentCount?.toLocaleString() || <NA />}
                </TableCell>
                <TableCell align="center" sx={{ textWrap: 'nowrap' }}>
                    {typeof massDm.viewedCount === 'number' ? (
                        <Stack direction="column" alignContent={'center'}>
                            <Typography variant="inherit">{massDm.viewedCount.toLocaleString()}</Typography>
                            <Typography variant="inherit" color="text.secondary">
                                {massDm.viewedCount > 0 ? `${((massDm.viewedCount / massDm.sentCount) * 100).toFixed(2)}%` : '0%'}
                            </Typography>
                        </Stack>
                    ) : (
                        <NA />
                    )}
                </TableCell>
                <TableCell align="center" sx={{ textWrap: 'nowrap' }}>
                    {massDm.purchasedCount && !massDm.isFree ? (
                        <Stack direction="column" alignContent={'center'}>
                            <Typography variant="inherit">{massDm.purchasedCount.toLocaleString()}</Typography>
                            <Typography variant="inherit" color="text.secondary">
                                $
                                {((messageDetails?.price || 0) * massDm.purchasedCount).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </Typography>
                        </Stack>
                    ) : massDm.isFree ? (
                        'FREE'
                    ) : (
                        <NA />
                    )}
                </TableCell>
                <TableCell align="center">
                    <Stack direction="row" spacing={0.5} justifyContent={'center'} alignItems={'center'}>
                        <IconButton color="error" onClick={() => handleUnsendMassDm(massDm.massDmId!)} disabled={isDeleting}>
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 4,
                        paddingTop: 4,
                        // ...(!open && { borderBottom: 'none' }),
                    }}
                    colSpan={7}
                >
                    <Stack direction="column" spacing={1}>
                        <Typography variant="inherit" color="textSecondary" component={'div'}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Tooltip title="Send To">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ContactsIcon color="primary" sx={{ fontSize: '12pt' }} />
                                    </div>
                                </Tooltip>
                                {isLoadingLists && <Skeleton width={100} variant="text" />}
                                <Stack direction="row" spacing={0.5} alignItems={'center'}>
                                    {lists && !isLoadingLists && messageDetails && messageDetails.userLists ? (
                                        messageDetails.userLists.map((u, index) => (
                                            <React.Fragment key={index}>
                                                <Typography variant="inherit">
                                                    {stripHtmlAndConvertParagraphs(
                                                        lists.find(l => parseNumberOrString(u) === l.id)?.name || 'Unknown List',
                                                    )}
                                                </Typography>
                                                {index < messageDetails.userLists.length - 1 && <Bullet />}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <NA />
                                    )}
                                </Stack>
                            </Stack>
                        </Typography>
                        <Typography variant="inherit" color="textSecondary" component={'div'}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Tooltip title="Exclude">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <NoAccountsIcon color="error" sx={{ fontSize: '12pt' }} />
                                    </div>
                                </Tooltip>
                                {isLoadingLists && <Skeleton width={100} variant="text" />}
                                <Stack direction="row" spacing={0.5} alignItems={'center'}>
                                    {lists && !isLoadingLists && messageDetails && messageDetails.excludedLists ? (
                                        messageDetails.excludedLists.map((u, index) => (
                                            <React.Fragment key={index}>
                                                <Typography variant="inherit">
                                                    {lists.find(l => parseNumberOrString(u) === l.id)?.name || 'Unknown List'}
                                                </Typography>
                                                {index < messageDetails.userLists.length - 1 && <Bullet />}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <NA />
                                    )}
                                </Stack>
                            </Stack>
                        </Typography>
                        {messageDetails && messageDetails.text && messageDetails.text.length > 0 && (
                            <Box
                                sx={{
                                    '&:hover': {
                                        // animate opacity on hover
                                        transition: 'opacity 0.3s',
                                        opacity: 1,
                                    },
                                    // animate opacity on leave
                                    transition: 'opacity 0.15s',
                                    opacity: 0.7,
                                    // smaller font for all children
                                    '& *': {
                                        fontSize: '0.9rem',
                                    },
                                }}
                            >
                                <MessageUsersFormEditiorPreview
                                    // editorState={editorState}
                                    message={messageDetails.text}
                                    lockMessage={!massDm.isFree}
                                    lockMessagePrice={messageDetails?.price || 0}
                                    lockedText={messageDetails?.lockedText || false}
                                    taggedCreators={
                                        messageDetails?.releaseForms?.map(f => {
                                            return { id: f.id, name: f.name };
                                        }) || []
                                    }
                                    taggedReleaseForms={[]}
                                    theme={theme}
                                    fullWidth
                                />
                            </Box>
                        )}
                    </Stack>
                </TableCell>
            </TableRow>
        </>
    );
};

export default MessageUsersAutoMassDmListTableRow;
