import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { GridColDef, GridColumnGroupingModel, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';

// Local storage key for column settings
const STORAGE_KEY = 'sextforce-metrics-column-visibility';

interface DataGridColumnVisibilityDialogProps {
    columns: GridColDef[];
    columnGroupingModel?: GridColumnGroupingModel;
    onVisibilityModelChange: (model: GridColumnVisibilityModel) => void;
    visibilityModel?: GridColumnVisibilityModel;
    componentId?: string; // Used to create unique localStorage keys
}

/**
 * Dialog component for managing column visibility in a DataGrid
 *
 * IMPORTANT: In this implementation:
 * - false = column is hidden
 * - not in model = column is visible (default)
 */
const DataGridColumnVisibilityDialog: React.FC<DataGridColumnVisibilityDialogProps> = ({
    columns,
    columnGroupingModel,
    onVisibilityModelChange,
    visibilityModel = {},
    componentId = 'campaigns-overview', // Used to create unique localStorage keys
}) => {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);
    const [localVisibilityModel, setLocalVisibilityModel] = useState<GridColumnVisibilityModel>(visibilityModel);
    const [resetDialogOpen, setResetDialogOpen] = useState<boolean>(false);

    // Get storage key specific to this component
    const getStorageKey = (): string => `${STORAGE_KEY}-${componentId}`;

    // Load saved settings on component mount
    useEffect(() => {
        const savedSettings = localStorage.getItem(getStorageKey());
        if (savedSettings) {
            try {
                const parsedSettings = JSON.parse(savedSettings);
                setLocalVisibilityModel(parsedSettings);
                // Don't call onVisibilityModelChange here to avoid infinite loop
            } catch (e) {
                console.error('Failed to parse saved column settings:', e);
            }
        }
    }, [componentId]);

    // Separate effect to notify parent component when component mounts
    useEffect(() => {
        const savedSettings = localStorage.getItem(getStorageKey());
        if (savedSettings) {
            try {
                const parsedSettings = JSON.parse(savedSettings);
                // Only call this once at component initialization
                onVisibilityModelChange(parsedSettings);
            } catch (e) {
                // Error already logged in the other effect
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Define an interface for grouped columns
    interface GroupedColumns {
        [key: string]: {
            groupId: string;
            columns: GridColDef[];
        };
    }

    // Group columns by their group IDs
    const getColumnsByGroup = (): GroupedColumns => {
        const groupedColumns: GroupedColumns = {};

        // Initialize groups
        if (columnGroupingModel) {
            columnGroupingModel.forEach(group => {
                groupedColumns[group.groupId] = {
                    groupId: group.groupId,
                    columns: [],
                };
            });
        }

        // Add "Ungrouped" for any columns without a group
        groupedColumns['Ungrouped'] = {
            groupId: 'Ungrouped',
            columns: [],
        };

        // Add columns to their respective groups
        columns.forEach(column => {
            let groupId = 'Ungrouped';

            // Find which group this column belongs to
            if (columnGroupingModel) {
                for (const group of columnGroupingModel) {
                    if (
                        group.children &&
                        group.children.some(child => {
                            // Type-safe way to check if the child has a field property matching column.field
                            return 'field' in child && child.field === column.field;
                        })
                    ) {
                        groupId = group.groupId;
                        break;
                    }
                }
            }

            // Skip action columns (edit, share)
            if (column.field === 'edit' || column.field === 'share') {
                return;
            }

            groupedColumns[groupId].columns.push(column);
        });

        return groupedColumns;
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleSave = (): void => {
        // Save to localStorage
        localStorage.setItem(getStorageKey(), JSON.stringify(localVisibilityModel));

        // Update parent component
        onVisibilityModelChange(localVisibilityModel);
        handleClose();
    };

    // In YOUR implementation:
    // - false = hidden
    // - not in model = visible (default)
    const handleToggleColumn = (field: string): void => {
        setLocalVisibilityModel(prev => {
            const newModel = { ...prev };

            // If checkbox is unchecked, we want to hide the column (set to false)
            // If checkbox is checked, we want to show the column (remove from model)
            if (newModel[field] === false) {
                // If column is currently hidden (false), make it visible by removing it
                delete newModel[field];
            } else {
                // If column is currently visible (not in model), hide it (false)
                newModel[field] = false;
            }

            return newModel;
        });
    };

    const handleToggleGroup = (groupId: string, visible: boolean): void => {
        const groupedColumns = getColumnsByGroup();
        const group = groupedColumns[groupId];

        if (!group) return;

        const newModel = { ...localVisibilityModel };

        group.columns.forEach(column => {
            if (visible) {
                // Show columns by removing them from the model
                delete newModel[column.field];
            } else {
                // Hide columns by setting to false
                newModel[column.field] = false;
            }
        });

        setLocalVisibilityModel(newModel);
    };

    const handleResetSettings = (): void => {
        setResetDialogOpen(true);
    };

    const confirmReset = (): void => {
        // Clear localStorage
        localStorage.removeItem(getStorageKey());

        // Reset to empty model - all columns will be visible by default
        const defaultModel: GridColumnVisibilityModel = {};

        setLocalVisibilityModel(defaultModel);
        onVisibilityModelChange(defaultModel);

        setResetDialogOpen(false);
    };

    const isGroupVisible = (groupId: string): boolean => {
        const groupedColumns = getColumnsByGroup();
        const group = groupedColumns[groupId];

        if (!group || !group.columns.length) return false;

        // A group is considered visible if any of its columns are visible (not false in model)
        return group.columns.some(column => localVisibilityModel[column.field] !== false);
    };

    const groupedColumns = getColumnsByGroup();

    return (
        <>
            <Tooltip title="Manage Columns">
                <IconButton onClick={handleOpen} sx={{ color: theme.palette.primary.main }}>
                    <SettingsIcon />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        boxShadow: 24,
                    },
                }}
            >
                <StyledCardTitleBar title="Manage Columns" theme={theme} />

                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        Select which columns you want to display in the table. Your settings will be saved automatically.
                    </DialogContentText>

                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        startIcon={<RestartAltIcon />}
                        onClick={handleResetSettings}
                        size="small"
                        sx={{ p: 1, mb: 2 }}
                    >
                        Reset to Default
                    </Button>

                    <Grid container spacing={2}>
                        {Object.keys(groupedColumns).map(groupId => {
                            const group = groupedColumns[groupId];

                            // Skip empty groups
                            if (!group.columns.length) return null;

                            const isVisible = isGroupVisible(groupId);

                            return (
                                <Grid item xs={12} key={groupId}>
                                    <Paper
                                        variant="outlined"
                                        sx={{
                                            p: 1,
                                            height: '100%',
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Stack spacing={1}>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                justifyContent="space-between"
                                                alignItems="center"
                                                sx={{
                                                    p: 1,
                                                    borderRadius: 1,
                                                }}
                                            >
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    {groupId}
                                                </Typography>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    startIcon={isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    onClick={() => handleToggleGroup(groupId, !isVisible)}
                                                >
                                                    {isVisible ? 'Hide All' : 'Show All'}
                                                </Button>
                                            </Stack>

                                            <Divider />

                                            <List dense disablePadding>
                                                {group.columns.map(column => (
                                                    <ListItem
                                                        key={column.field}
                                                        disablePadding
                                                        sx={{
                                                            py: 0.5,
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.action.hover,
                                                                borderRadius: 1,
                                                            },
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    // For YOUR implementation:
                                                                    // - false in model = hidden column
                                                                    // - true in model = visible column
                                                                    checked={localVisibilityModel[column.field] !== false}
                                                                    onChange={() => handleToggleColumn(column.field)}
                                                                    size="small"
                                                                />
                                                            }
                                                            label={
                                                                <ListItemText
                                                                    primary={column.headerName || column.field}
                                                                    primaryTypographyProps={{
                                                                        variant: 'body2',
                                                                    }}
                                                                />
                                                            }
                                                            sx={{ ml: 0, width: '100%' }}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Stack>
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        px: 3,
                        py: 2,
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                >
                    <Button onClick={handleClose} color="secondary" variant="text">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="primary" startIcon={<VisibilityIcon />}>
                        Apply Changes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog for Reset */}
            <Dialog open={resetDialogOpen} onClose={() => setResetDialogOpen(false)} maxWidth="xs" fullWidth>
                <DialogTitle>Reset Column Settings?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will reset all column visibility settings to their default values. This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setResetDialogOpen(false)} color="inherit">
                        Cancel
                    </Button>
                    <Button onClick={confirmReset} color="error" variant="contained">
                        Reset
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DataGridColumnVisibilityDialog;
