import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import {
    Alert,
    Checkbox,
    Chip,
    Grid,
    IconButton,
    LinearProgress,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    SxProps,
    Theme,
    Tooltip,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import useSubscriberLists from '../../hooks/useSubscriberLists';
import { OnlyFansLists } from '../../types/onlyFansTypes';
import { stripHtmlAndConvertParagraphs } from '../../utils/common';
import TextInputDialog from './TextInputDialog';

type Props = {
    targetLists: { id: number | OnlyFansLists.Type; name: string }[];
    setTargetLists: (targetLists: { id: number | OnlyFansLists.Type; name: string }[]) => void;
    disabled?: boolean;
    listItemBackgroundColor?: string;
    sx?: SxProps<Theme>;
};

const OnlyFansListsMutiSelector = (props: Props) => {
    const { targetLists, setTargetLists, disabled, listItemBackgroundColor, sx } = props;

    const {
        // data: listsPages,
        lists,
        hasMore,
        loadedListsCount,
        isLoading: listsLoading,
        isFetching: listsFetching,
        refetch: refetchLists,
    } = useSubscriberLists(true);

    const [sortLists, setSortLists] = useState<boolean>(true);
    const [sortedLists, setSortedLists] = useState<OnlyFansLists.List[] | void | undefined>([]);

    const [searchText, setSearchText] = useState<string>('');
    const [searchTextDialogOpen, setSearchTextDialogOpen] = useState<boolean>(false);

    // Memoize the sorting function
    const sortListsData = useCallback(
        (data: OnlyFansLists.List[]) => {
            if (!sortLists) return data;

            return [...data].sort((a, b) => {
                const x = a.name.toLowerCase();
                const y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
        },
        [sortLists],
    );

    // Handle list item selection
    const handleListItemClicked = useCallback(
        (id: number | OnlyFansLists.Type) => {
            const alreadyChecked = targetLists.find(item => item.id === id);

            if (alreadyChecked) {
                setTargetLists(targetLists.filter(item => item.id !== id));
            } else if (lists) {
                const listName = lists.find(item => item.id === id)?.name;
                if (listName) {
                    setTargetLists([...targetLists, { id, name: listName }]);
                }
            }
        },
        [lists, targetLists, setTargetLists],
    );

    // Update sorted lists whenever lists or sort preference changes
    useEffect(() => {
        if (!lists) {
            setSortedLists([]);
            return;
        }

        const filteredLists = searchText ? lists.filter(list => list.name.toLowerCase().includes(searchText.toLowerCase())) : lists;

        const newSortedLists = sortListsData(filteredLists);
        setSortedLists(newSortedLists);
    }, [lists, searchText, sortListsData]);

    const isLoading = listsLoading || listsFetching;

    const Row = ({ index, style }: ListChildComponentProps) => {
        const list = sortedLists ? sortedLists[index] : null;
        if (!list) return null;

        return (
            <ListItem
                disablePadding
                key={list.id}
                sx={{
                    backgroundColor:
                        targetLists && targetLists.find(item => item.id === list.id)
                            ? listItemBackgroundColor || 'text.gray.200'
                            : 'transparent',
                    ...style,
                }}
            >
                <ListItemButton
                    disabled={disabled}
                    role={undefined}
                    dense
                    onClick={() => {
                        handleListItemClicked(list.id);
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            id={`checkbox-${list.id}`}
                            name={`checkbox-${list.id}`}
                            tabIndex={-1}
                            disableRipple
                            checked={targetLists && targetLists.find(item => item.id === list.id) ? true : false}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={stripHtmlAndConvertParagraphs(list.name)}
                        secondary={`Users: ${(list.usersCount as number).toLocaleString()}`}
                    />
                </ListItemButton>
            </ListItem>
        );
    };

    return (
        <Grid container spacing={0} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
            <Grid item xs>
                <Chip
                    disabled={disabled}
                    color={!targetLists || targetLists.length === 0 ? 'error' : 'primary'}
                    icon={<CheckCircleIcon />}
                    label={`${targetLists ? targetLists.length : 0} selected`}
                />
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Search">
                    <span>
                        <IconButton
                            disabled={disabled}
                            onClick={() => {
                                setSearchTextDialogOpen(true);
                            }}
                        >
                            <SearchIcon fontSize="large" color={searchText ? 'primary' : 'disabled'} />
                        </IconButton>
                    </span>
                </Tooltip>
                {searchTextDialogOpen && (
                    <TextInputDialog
                        open={searchTextDialogOpen}
                        onClose={() => setSearchTextDialogOpen(false)}
                        onSubmit={value => {
                            setSearchText(value.trim());
                            setSearchTextDialogOpen(false);
                        }}
                        title="Search"
                        contentText="Search for lists by name"
                        label="Search"
                        placeholder="Search"
                        initialValue={searchText}
                        submitButtonText="Search"
                        clearButton={true}
                    />
                )}
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Clear Selection">
                    <span>
                        <IconButton
                            disabled={disabled}
                            onClick={() => {
                                setTargetLists([]);
                            }}
                        >
                            <ClearIcon fontSize="large" color={targetLists && targetLists.length > 0 ? 'error' : 'disabled'} />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Sort lists alphabetically or custom like on OnlyFans">
                    <span>
                        <IconButton
                            disabled={disabled}
                            color={sortLists ? 'primary' : 'secondary'}
                            onClick={() => {
                                setSortLists(!sortLists);
                            }}
                        >
                            <SortByAlphaIcon fontSize="large" />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={'auto'}>
                <Tooltip title="Reload Lists from OnlyFans">
                    <span>
                        <IconButton
                            disabled={disabled}
                            color="secondary"
                            onClick={() => {
                                refetchLists();
                            }}
                        >
                            <RefreshIcon fontSize="large" />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                {sortedLists && typeof sortedLists !== 'undefined' ? (
                    <Paper variant="outlined" sx={{ height: 200, overflow: 'auto', ...sx }}>
                        <Stack direction="column">
                            {(isLoading || hasMore) && <LinearProgress />}
                            <FixedSizeList
                                height={isLoading || hasMore ? 196 : 200}
                                width="100%"
                                itemSize={57}
                                itemCount={sortedLists.length}
                                itemData={sortedLists}
                            >
                                {Row}
                            </FixedSizeList>
                        </Stack>
                    </Paper>
                ) : (
                    <Alert severity="error">Could not load lists!</Alert>
                )}
            </Grid>
        </Grid>
    );
};

export default OnlyFansListsMutiSelector;
