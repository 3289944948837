// subscriberListsService.ts

import { AxiosInstance } from 'axios';
import { OnlyFansLists } from '../types/onlyFansTypes';

// A singleton service that handles all subscriber list fetching
class SubscriberListsService {
    private activeRequests: Map<string, Promise<OnlyFansLists.Response>> = new Map();
    private fetchedPages: Set<string> = new Set();
    private allResults: Map<string, OnlyFansLists.Response[]> = new Map();
    private memoizedResults: Map<string, any> = new Map();
    private isLoading: boolean = false;
    private isPaused: boolean = false;

    constructor() {
        // Initialize the singleton
    }

    // Pause all fetching (useful for when app is in background)
    public pause(): void {
        this.isPaused = true;
    }

    // Resume fetching
    public resume(): void {
        this.isPaused = false;
    }

    // Get a single page of results
    public async fetchPage(
        axios: AxiosInstance,
        baseUrl: string,
        subscriberId: string,
        offset: number = 0,
        limit: number = 10,
    ): Promise<OnlyFansLists.Response> {
        const requestKey = `${subscriberId}:${offset}`;

        // If we've already fetched this page, return the cached result
        if (this.fetchedPages.has(requestKey)) {
            const subscriberResults = this.allResults.get(subscriberId) || [];
            const pageIndex = offset / limit;
            if (subscriberResults[pageIndex]) {
                return subscriberResults[pageIndex];
            }
        }

        // If this request is already in progress, return the existing promise
        if (this.activeRequests.has(requestKey)) {
            return this.activeRequests.get(requestKey)!;
        }

        // Wait if there's already an active request for this subscriber
        while (this.isLoading) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        // Mark that we're loading
        this.isLoading = true;

        // Create the request
        const requestPromise = this.makeRequest(axios, baseUrl, subscriberId, offset, limit);

        // Store the request
        this.activeRequests.set(requestKey, requestPromise);

        try {
            // Wait for the request to complete
            const response = await requestPromise;

            // Store the result
            if (!this.allResults.has(subscriberId)) {
                this.allResults.set(subscriberId, []);
            }

            // Get the results array
            const results = this.allResults.get(subscriberId)!;

            // Calculate page index
            const pageIndex = offset / limit;

            // Store the response at the correct index
            results[pageIndex] = response;

            // console.log(`[Service] fetchPage: Stored page at index ${pageIndex} for ${subscriberId}`);
            // console.log(`[Service] fetchPage: Results array now has ${results.length} pages`);

            // Mark this page as fetched
            this.fetchedPages.add(requestKey);

            // Debug the current state
            // console.log(`[Service] fetchPage: Current lists count: ${this.getLists(subscriberId).length}`);

            return response;
        } finally {
            // Clean up
            this.activeRequests.delete(requestKey);
            this.isLoading = false;
        }
    }

    // Check if we have all pages for a specific subscriber
    public hasAllPages(subscriberId: string): boolean {
        const results = this.allResults.get(subscriberId);
        if (!results || results.length === 0) {
            return false;
        }

        // The last page's hasMore property tells us if there are more pages
        const lastPage = results[results.length - 1];
        return !lastPage.hasMore;
    }

    // Get all fetched lists for a subscriber
    public getLists(subscriberId: string): OnlyFansLists.List[] {
        const results = this.allResults.get(subscriberId);
        if (!results || results.length === 0) {
            return [];
        }

        // If we haven't calculated this list before, generate it and cache it
        const cachedListsKey = `${subscriberId}:lists`;
        const cachedLists = this.memoizedResults.get(cachedListsKey);

        // Only regenerate the list if we don't have a cached version
        // or if the number of pages has changed
        if (!cachedLists || cachedLists.length !== this.getListsCount(subscriberId)) {
            // Carefully extract lists from each page
            const lists: OnlyFansLists.List[] = [];

            results.forEach((page, index) => {
                if (page && Array.isArray(page.list)) {
                    lists.push(...page.list);
                }
            });

            // Store the result in the cache
            this.memoizedResults.set(cachedListsKey, lists);

            // console.log(`[Service] getLists: Generated ${lists.length} lists for ${subscriberId}`);
            return lists;
        }

        // Return the cached lists
        return cachedLists;
    }

    // Get the count of loaded lists (now uses cached count)
    public getLoadedListsCount(subscriberId: string): number {
        return this.getListsCount(subscriberId);
    }

    // Get the count of all lists across all pages
    public getListsCount(subscriberId: string): number {
        const results = this.allResults.get(subscriberId);
        if (!results || results.length === 0) {
            return 0;
        }

        // Calculate the total number of lists across all pages
        return results.reduce((count, page) => {
            if (page && Array.isArray(page.list)) {
                return count + page.list.length;
            }
            return count;
        }, 0);
    }

    // Clear all data for a specific subscriber
    public clearSubscriberData(subscriberId: string): void {
        console.log(`[Service] Clearing data for ${subscriberId}`);

        // Remove all cached pages for this subscriber
        this.fetchedPages.forEach(key => {
            if (key.startsWith(`${subscriberId}:`)) {
                this.fetchedPages.delete(key);
            }
        });

        // Remove all memoized results
        this.memoizedResults.delete(`${subscriberId}:lists`);

        // Remove all stored results
        this.allResults.delete(subscriberId);

        // Cancel any active requests for this subscriber
        this.activeRequests.forEach((promise, key) => {
            if (key.startsWith(`${subscriberId}:`)) {
                this.activeRequests.delete(key);
            }
        });

        // console.log(`[Service] Data cleared for ${subscriberId}`);
    }

    // Check if there are more pages to load
    public hasMorePages(subscriberId: string): boolean {
        const results = this.allResults.get(subscriberId);

        if (!results || results.length === 0) {
            return true; // No pages loaded yet, so there are more
        }

        const lastPage = results[results.length - 1];

        return lastPage.hasMore;
    }

    // Fetch all pages for a subscriber (recursive)
    public async fetchAllPages(
        axios: AxiosInstance,
        baseUrl: string,
        subscriberId: string,
        limit: number = 10,
    ): Promise<OnlyFansLists.List[]> {
        // console.log(`[Service] fetchAllPages called for ${subscriberId}`);

        // Implement an iterative approach instead of recursion
        let hasMorePages = true;

        while (hasMorePages && !this.isPaused) {
            // Get the next offset to fetch
            const nextOffset = this.getNextOffset(subscriberId, limit);
            // console.log(`[Service] Fetching next page at offset ${nextOffset}`);

            // Fetch the next page
            try {
                const response = await this.fetchPage(axios, baseUrl, subscriberId, nextOffset, limit);

                // Check if we have more pages
                hasMorePages = response.hasMore;

                if (hasMorePages) {
                    // Wait a small amount of time to prevent overwhelming the server
                    await new Promise(resolve => setTimeout(resolve, 300));
                } else {
                    // console.log(`[Service] No more pages for ${subscriberId}`);
                }
            } catch (error) {
                console.error(`[Service] Error fetching page:`, error);
                // Break the loop on error
                hasMorePages = false;
            }
        }

        // Return all the lists
        const allLists = this.getLists(subscriberId);
        // console.log(`[Service] fetchAllPages returning ${allLists.length} lists for ${subscriberId}`);
        return allLists;
    }

    // Get the offset for the next page to fetch
    private getNextOffset(subscriberId: string, limit: number): number {
        const results = this.allResults.get(subscriberId);
        if (!results) {
            return 0; // Start at the beginning
        }

        return results.length * limit;
    }

    // Make the actual network request
    private async makeRequest(
        axios: AxiosInstance,
        baseUrl: string,
        subscriberId: string,
        offset: number,
        limit: number,
    ): Promise<OnlyFansLists.Response> {
        const response = await axios.get(`${baseUrl}${subscriberId}/listsPaginated?offset=${offset}&limit=${limit}`);

        if (!response.status || response.status !== 200) {
            throw new Error(`Error fetching subscriber lists: ${response.status}`);
        }

        // Validate the response structure
        if (!response.data || !Array.isArray(response.data.list)) {
            console.error(`[Service] Invalid response format:`, response.data);
            // Return a safe default if the API returns unexpected format
            return {
                hasMore: false,
                list: [],
            };
        }

        // console.log(`[Service] Received ${response.data.list.length} lists for ${subscriberId} at offset ${offset}`);

        return response.data as OnlyFansLists.Response;
    }
}

// Export a singleton instance
export const subscriberListsService = new SubscriberListsService();
